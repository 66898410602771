.product.detailed {

    .data.item {

        &.title {
            border: 0;
            color: @black;
            margin-right: 1rem;
        }
        
        &.content {
            margin-top: 0;
            padding: 3rem 2rem;
            border: 2px solid darken(@light-grey, 2%);
            // background: @light-grey;
            
            @media (min-width: 768px) {
                margin-top: 44px;
            }

            .description {

                p > strong {
                    margin-top: 1rem;
                    display: inline-block;
                }

                strong, 
                strong span {
                    color: @light-green !important;
                }

                a {
                    color: @dark-green !important;
                    text-decoration: underline !important;

                    &:hover {
                        color: @black !important;
                    }
                }

                span[style="color: #29b471;"] {
                    font-weight: bold;
                    color: @dark-green !important;
                }
            }
        }

        &.active {

            .switch {
                color: @white;
                background: @light-green;

                &:hover {
                    color: @white;
                    background: darken(@light-green, 2%);
                }
            }
        }

        > .switch {
            border: 0;
            padding: 5px 15px 5px 15px;
            border-radius: 5px 5px 0 0;
            background: darken(@light-grey, 2%);

            &:hover {
                color: @black;
                background: darken(@light-grey, 10%);
            }

            .counter {
                display: none;
            }
        }
    }
}

.block.review-list {
    .block-title {
        width: 100%;
        padding-bottom: 1rem;
        border-bottom: 2px solid @light-grey;
        
        strong {
            font-size: 2rem;
            font-weight: bold;
            color: @dark-green;
        }
    }

    .review-item {
        .review-title {
            font-size: 1.6rem;
            font-weight: bold;
            color: @dark-green;
            margin-bottom: 1rem;
        }
    }
}