.nav-sections {
    margin-bottom: 0;
    background-color: @dark-green;
}
.nav-sections nav.navigation {
    background: transparent;
}
.nav-sections nav.navigation > ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent !important;
}
.nav-sections nav.navigation ul > li.level0 {
    flex-grow: 1;
    text-align: center;
    transition: all .3s ease-in-out;
}
.nav-sections nav.navigation ul > li.level0 > a {
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
    padding: 8px 20px 8px 20px;
    transition: all .3s ease-in-out;
}
.nav-sections nav.navigation ul > li.level0:last-child > a {
    background-color: @light-red;
}

.level0.has-active > a {
    background: @light-green;
} 

.nav-sections nav.navigation ul > li.level0:hover,
.nav-sections nav.navigation ul > li.level0:hover a,
.nav-sections nav.navigation ul > li.level0.active > .level-top {
    background: @light-green !important;
}
.nav-sections nav.navigation ul > li.level0 > a > span.ui-menu-icon {
    display: none !important;
}
