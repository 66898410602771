

.product-image-photo {
    display: block;
    max-width: 225px;
    max-height: 225px;
    position: relative;
    margin: 0 auto 10px auto;

    @media (max-width: 970px) {
        max-width: 215px;
        max-height: 215px;
    }
    @media (max-width: 500px) {
        max-width: 205px;
        max-height: 205px;
    }
}

#shopping-cart-table .product-image-photo {
    max-width: 100%;
    max-height: 100%;

    @media (min-width: 767px) {
        max-width: 100px;
        max-height: 100px;
    }
}