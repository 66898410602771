
.sidebar {
    
    .block {
        padding: 0;
        background: @light-grey;

        .block-title {
            line-height: 1;
            padding: 1rem 1.5rem;
            background: @light-green;
            
            strong {
                color: @white;
                font-size: 1.6rem;
            }

            .counter {
                color: @white;
            }
        }
        
        .block-content,
        > .empty {
            padding: 0 1.5rem 1.5rem;
    
            .block-subtitle {
                display: none;
            }
            
            .filter-options {
        
                .filter-options-title {
                    padding: .6rem 0;
                    color: @dark-green;
                    text-transform: none;
                    border-top: 1px solid @mid-grey;
                    border-bottom: 1px solid @mid-grey;
                }

                li.item {
                    margin-bottom: 8px;

                    &:hover {
                        a {
                            margin-left: 8px;
                        }
                    }
                    
                    a {
                        color: @black;
                        position: relative;
                        padding: 0 0 0 14px;
                        transition: all .25s ease-in-out;
    
                        &:hover {
                            color: @light-green;
                        }
    
                        &:before {
                            top: 50%;
                            left: 0px;
                            width: 0px;
                            height: 0px;
                            content: '';
                            display: block;
                            position: absolute;
                            border-right: none;
                            transform: translateY(-50%);
                            border-left: 4px solid @mid-grey;
                            border-top: 4px solid rgba(0, 0, 0, 0);
                            border-bottom: 4px solid rgba(0, 0, 0, 0);
                        }
    
                        .count {
                            display: none;
                        }
                    }
                }

            }
        }
    }
}