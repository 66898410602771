html body {

    // Page Body Class
    &.checkout-cart-index {

        .page-wrapper {
            min-height: 70vh;
        }

        main {
            padding: 2rem 0 2rem 0;

            a {
                color: @dark-green;

                &:hover {
                    color: @black;
                }
            }

            .page-title-wrapper {
                padding: 1rem;
                margin-bottom: 1rem;
                background-color: @light-green;

                .page-title {
                    width: 100%;
                    color: @white;
                    font-size: 2rem;
                    margin-bottom: 0;
                    text-align: center;
                }
            }

            .messages {
                &:before,
                &:after {
                    content: '';
                    display: table;
                }

                &:after {
                    clear: both;
                }
            }

            .columns {

                .main {
                    padding: 2rem;
                    min-height: auto;
                    background-color: @light-grey;

                    .rewards-notification-block {
                        margin: 0;
                    }

                    .cart-empty {
                        padding: 1rem;
                        font-size: 1.4rem;
                        text-align: center;

                        p {
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }

            .action.action-delete {
                padding: 0 !important;
                border: none !important;
                color: @light-red !important;
                font-weight: normal !important;
            }
        }

        .cart-summary {
            padding: 0;
            background: @light-grey;
        }

        #block-shipping {
            .title {
                padding-top: 0.8rem;

                > strong {
                    font-size: 1.5rem;
                    font-weight: bold;
                    color: @dark-green;
                }

                &:after {
                    top: 0px;
                }
            }
        }

        #block-discount {
            margin-top: 1rem;
            .title {
                padding-top: 1.5rem;

                > strong {
                    font-size: 1.5rem;
                    font-weight: bold;
                    color: @dark-green;
                }

                &:after {
                    display: none;
                }
            }

            .content {
                display: block !important;

                input {
                    border-radius: 5px;
                }
            }
        }

        #block-rewards-form {
            margin-top: 1rem;

            .discount-form {
                padding: 5px;
            }

            .title {
                padding-top: 0.7rem;

                > strong {
                    font-size: 1.5rem;
                    font-weight: bold;
                    color: @dark-green;
                }

                &:after {
                   top: -2px;
                }
            }

            .points_amount {
                margin-bottom: 15px;
            }

            .content {
                // display: block !important;

                input {
                    // margin-top: 1rem;
                    border-radius: 5px;
                }
            }

            .buttons-container {
                button {
                    padding: 0.8rem;
                    background: @dark-green;

                    > span {
                        color: white;
                        font-size: 1.2rem;
                    }
                }
            }
        }

        .checkout {
            button {
                background: @dark-green;
                border-color: @dark-green;

                &:hover {
                    background: @black;
                    border-color: @black;
                }
            }
        }

        #block-shipping {
            display: none;
        }

    }
}