.catalog-product_compare-index {

    main {
        margin-top: 1rem;

        .page-title-wrapper {
            padding: 1rem;
            margin-bottom: 1rem;
            background-color: @light-green;
    
            .page-title {
                width: 100%;
                color: @white;
                font-size: 2rem;
                margin-bottom: 0;
                text-align: center;
            }
        }

        .print {
            float: right;
            line-height: 1;
            font-size: 0.9rem;
        }

        .table-comparison {
            margin-top: 2rem;

            tbody {
                tr {
                    border-bottom: 1px solid @light-grey;

                    td {
                       
                        &.product {
                            .product-item-photo {
                                img {
                                    margin: 0 !important;
                                }
                            }

                            .product-item-name a {
                                line-height: 1;
                                font-size: 1.5rem;
                                color: @dark-green;

                                a {
                                    color: @black;
                                }
                            }

                            .product-reviews-summary {
                                display: flex;
                                margin: 0 auto;
                                align-items: center;
                                flex-direction: row;
                                justify-content: flex-start;

                                @media (min-width: 700px) {
                                    flex-direction: row;
                                }

                                .rating-summary {

                                    .rating-result {

                                        > span {
                                            &:before {
                                                color: #FFCC00;
                                            }
                                        }
                                    }
                                }

                                .reviews-actions {
                                    margin: 0;
                                    padding: 0;
                                    display: inline !important;

                                    a {
                                        color: @black;
                                    }
                                }
                            }
                            
                            .price-box {
                                .price {
                                    font-weight: bold;
                                }
                            }

                            .actions-primary {
                                form {
                                    button.primary {
                                        border: 0;
                                        width: 100%;
                                        color: white;
                                        display: block;
                                        font-size: 1.2rem;
                                        margin-right: 1rem;
                                        padding: 0.8rem 1rem;
                                        text-transform: none;
                                        margin-bottom: 0.5rem;
                                        background-color: @dark-green;
                                        
                                        @media (min-width: @lg-breakpoint) {
                                            width: auto;
                                            margin-bottom: 0;
                                            display: inline-block;
                                        }

                                        &:hover {
                                            background-color: @black;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
 
}