
.box-tocart {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    #product-addtocart-button {
        border: 0;
        border-radius: 0;
        font-size: 1.7rem;
        padding: 1rem 6rem;
        font-weight: normal;
        text-transform: none;
        background: @dark-green;

        &:hover {
            background: @light-green;
        }
    }   
}