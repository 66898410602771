
.product,
.category { 

    &.cta-bar {
        margin-bottom: 1.5rem;

        .product-actions {
            display: flex;
            color: @white;
            padding: 1.4rem;
            align-items: center;
            justify-content: center;

            @media (min-width: @md-breakpoint) {
                padding: 0.8rem;
            }

            .item {

                a,
                div {
                    color: inherit;
                    
                    @media (min-width: @md-breakpoint) {
                        display: flex;
                        align-items: flex-end;
                        justify-content: center;
                    }
                    
                    div.content-main {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    
                    img {
                        display: none;
                        max-width: 42px;
                        max-height: 42px;
                        margin-right: 1rem;

                        @media (min-width: @md-breakpoint) {
                            display: block;
                        }
                    }

                    span.title {
                        margin: 0;
                        line-height: 1;
                        font-size: 2rem;
                        text-align: left;
                        // font-family: "verveine";
                    }
                    
                    span {
                        display: inherit;
                        font-size: 0.8rem;
                        text-align: right;
                        

                        @media (min-width: @md-breakpoint) {
                            text-align: left;
                            margin-left: 1rem;
                            margin-bottom: 5px;
                        }

                        a {
                            color: inherit;
                            margin: 0 3px;
                            text-decoration-style: double;
                            text-decoration-line: underline;

                            &:hover {
                                color: @light-red;
                            }
                        }
                    }
                }
            }
        }
    }
}