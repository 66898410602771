header.page-header {}
header.page-header > .panel.wrapper {
    background: transparent;
}

header {
    background: @white;

    .panel.wrapper {
        display: none;
    }

    .panel.header {
        padding: 0;
    }

    .header.content {
        display: flex;
        padding: 0.7rem 1rem;
        align-items: center;
        // flex-direction: column;
        // justify-content: space-between;

        @media(min-width: @md-breakpoint) {
            padding: 2rem 1.5rem 2rem 1.5rem;
        }
        

        &:before, 
        &:after {
            display: none;
        }

        @media (min-width: 1020px) {
            flex-direction: row;
        }

        .logo {
            margin: 0;

            a.logo {
                margin: 0;
                max-width: none;
                
                @media(min-width: @md-breakpoint) {
                    margin: 0 0 0 1rem;
                }
                
                > img {
                    width: auto;
                    height: auto;
                    max-height: 60px;
                    max-width: 230px;

                    @media(min-width: @md-breakpoint) {
                        max-height: 60px;
                        max-width: 460px;
                    }
                }
            }

        }

        .form.minisearch {
            display: flex;
            justify-content: flex-end;
        }

        @media (max-width: 767px) {
            .header.controls {
                display: none;
            }
        }

        .search {
            width: 100%;

            @media(max-width: @lg-breakpoint) {
                display: block;
            }

            @media(min-width: 800px) {
                max-width: 260px;
            }
            @media(min-width: 1024px) {
                max-width: 340px;
            }
            @media(min-width: 1060px) {
                max-width: none;
            }

            .block-search {
                padding: 0;
                max-width: 100%;
                position: relative;
    
                &:before {
                    top: 50%;
                    left: 10px;
                    opacity: 0.7;
                    z-index: 9000;
                    display: block;
                    color: @dark-grey;
                    content: '\f002';
                    position: absolute;
                    font-family: "Fontawesome";
                    transform: translateY(-50%);
                }
    
                @field-border-radius: 10px;
                form.form.minisearch {
        
                    input {
                        border: 0px;
                        color: @black;
                        padding-left: 40px;
                        padding-right: 80px;
                        background: @light-grey;
                        border-radius: @field-border-radius;
        
                        &:focus,
                        &:active {
                            border-radius: 5px;
                            outline-width: 1px !important;
                            outline-offset: 3px !important;
                            outline-style: dashed !important;
                            outline-color: lighten(black, 50%) !important;
                        }
                    }
        
                    div.actions {
                        display: none !important;

                        @media(min-width: @md-breakpoint) {
                            display: block !important;
                        }
                        
                        button {
                            width: 75px  !important;
                            background: @light-green;
                            border-radius: 0 @field-border-radius @field-border-radius 0;

                            &:hover {
                                background: @dark-green;
                            }
                            
                            &:before {
                                display: none;
                            }
            
                            span {
                                top: 50%;
                                left: 50%;
                                margin: 0;
                                clip: auto;
                                width: auto;
                                height: auto;
                                color: @white;
                                font-weight: bold;
                                overflow: visible;
                                transform: translate(-50%, -50%); 
                            }
                        }
                    }
        
                    .search-autocomplete {
                        padding: 10px;
                        margin-top: 1rem;
                        background: white;
                        border-radius: 10px;
                        box-shadow: 0px 0px 3px 5px rgba(0, 0, 0, 0.1);
        
                        ul {
        
                            li:not(:empty) {
                                border: 0;
                                line-height: 1;
                                padding-top: 8px;
                                margin-bottom: 5px;
                                border-radius: 10px;
                                padding-bottom: 8px;
                                background: #f3f3f3;
        
                                span.amount {
                                    top: 10px;
                                    font-size: 12px;
                                }
        
                                &:hover {
                                    background: darken(#f3f3f3, 5%);
                                }
        
                                &.selected {
                                    background: rgba(44, 170, 102, 0.2)
                                }
                            }
                        }
                    }
                }
            }
        }

        .controls {
            float: right;

            @media(max-width: 1140px) {
                .header.links {
                    &:before {
                        display: none;
                    }
                }
            }
            
            .header.links {
                &:before {
                    display: none;
                }
                > li {
                    display: none;
                }

                a {
                    white-space: nowrap;
                }
            }

            @media(min-width: 800px) {
                .header.links {
                    &:before {
                        display: none;
                    }
                    > li {
                        display: block;
                    }
                }
            }
            @media(min-width: 1125px) {
                .header.links {
                    &:before {
                        display: block;
                    }
                    > li {
                        display: block;
                    }
                }
            }

            @media(min-width: @md-breakpoint) {
                display: block;
            }

            ul.links {
                padding: 0;
                display: flex;
                list-style: none;
                align-items: center;
                margin: 0;
                justify-content: flex-end;

                &:before {
                    content: "\f007";
                    font-family: "Fontawesome";
                    width: 42px;
                    height: 42px;
                    padding: 5px;
                    color: white;
                    font-size: 24px;
                    line-height: 1.8;
                    border-radius: 50%;
                    text-align: center;
                    background: #92bf1f;
                }

                li {
                    margin: 0;
                    position: relative;

                    &:first-child {
                        &:after {
                            top: 50%;
                            right: -3px;
                            content: "/";
                            // height: 15px;
                            color: @black;
                            display: block;
                            font-weight: bold;
                            position: absolute;
                            transform: translateY(-50%);
                        }
                    }

                    a, span {
                        margin: 0 8px;
                        display: block;
                        transition: none;
                        font-weight: bold;
                        color: @dark-grey;
                        font-size: 1.3rem;
                        transition: all 0.3s ease-in-out;
                        
                        &:hover {
                            color: @dark-green;
                        }
                    }
                }
            }
        }

        @media (max-width: 470px) {
            padding: 0;
            flex-direction: column;

            .ammenu-menu-toggle {
                top: 74px;
                left: 11px;
                position: absolute;

                &.-active {
                    top: 10px;
                    left: 11px;
                }

                .ammenu-icon {
                    border-color: @white !important;
                }
            }

            .header.logo {
                height: 66px;
                max-width: none;

                .my-flex {
                    width: 100vw;
                    display: flex;
                    padding: 10px;
                    justify-content: center;
                }
            }

            .header.controls {
                padding-right: 20px;
                .action.showcart {
                    &:before {
                        width: 37px;
                        height: 37px;
                        background: transparent;
                    }
                }
            }

            .header.search-controls {
                width: 100%;
                padding-right: 20px;
                background: @dark-green;

                .label:before {
                    color: @white;
                }
            }
        }

    }

    .header.logo {
        width: auto;
    }

    .search-controls {
        width: auto;
        float: right;
        display: flex;
        margin-left: auto;
        align-items: center;

        .header.search {
            margin-right: 1rem;
            
            @media (min-width: @md-breakpoint) {
                margin-left: 2rem;
                margin-right: 2rem;
            }
            @media (min-width: @lg-breakpoint) {
                margin-right: 5rem;
            }
        }

        .minicart-wrapper {
            margin-right: 0 !important;
        }
    }
}

@item-padding: 1.2rem;
.header-actions {
    width: 100%;
    display: none;

    @media (min-width: 1020px) {
        display: block;
    }
    
    .contain {
        display: flex;
        flex-wrap: wrap;
        margin-left: auto;
        max-width: 1280px;
        margin-right: auto;
        align-items: center;
        justify-content: center;

        @media (min-width: 1020px) {
            flex-wrap: nowrap;
        }
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media (min-width: 1020px) {
            flex: 1;
            width: auto;
        }
        
        a, div {
            width: 100%;
            padding: 1rem @item-padding 0 @item-padding;
        }

        a {
            display: block;
        }

        img {
            display: block;
            max-height: 70px;
            margin: 0 auto 0 auto;
        }

        // div {
        //     display: flex;
        //     justify-content: center;
        // }

        &:first-child {
           a {
               img {
                   margin: 0 auto 0 0;
               }
           }
        }

        &:last-child {
           a {
               img {
                   margin: 0 0 0 auto;
               }
           }
        }
    }
}


.ammenu-menu-toggle {
    padding: 10px;
    z-index: 1000000;
    margin-top: -5px;
    position: relative;

    &.-active {
        .ammenu-icon {
            border-color: @white !important;

            &:nth-of-type(3) {
                transform: translateY(-10px) rotate(-45deg);
            }
        }
    }

    .ammenu-icon {
        border-bottom: 3px solid;
    }
}

.ammenu-nav-sections {

    &.nav-sections {
        max-width: none;
    }

    .ammenu-title {
        color: @white;

        &.active {
            color: @dark-green;
        }
    }

    .ammenu-menu-wrapper.-desktop {
        .ammenu-items {
            justify-content: space-between;

            .ammenu-submenu-container {
                margin-top: 11px !important;
            }
        }

        nav.ammenu-main-container {
            > .ammenu-items {

                > .ammenu-item {

                    > a {
                        font-size: 18px;
                        color: @white !important;
                    }

                    &:hover {
                        > a {
                            color: @dark-green !important;
                        }
                    }

                    &:last-child {
                        background: @light-red !important;
                        
                        &:hover {
                            > a {
                                color: @white !important;
                            }
                        }
                    }

                    &.-main {
                        padding: 11px 15px;
                    }

                }

                .ammenu-link.-level1.current{
                    color: @light-green !important;   
                }
            }
        }
    }

    .ammenu-menu-wrapper.-mobile {
        nav.ammenu-main-container {
            > ul.ammenu-items {
                > li.ammenu-item {
                    > a {
    
                        &:before {
                            display: none;
                        }
                    }

                    &:last-child {
                        > a {
                            color: @light-red !important;
                        }

                        .ammenu-icon {
                            &:before,
                            &:after {
                                background: @light-red !important;
                            }
                        }
                    }

                    &.-drill-opened {
                        &:last-child {
                            .ammenu-icon {
                                background: @light-red !important;
                            }
                        }
                    }
                }
            }
        }
    }

    &.-opened {

        .ammenu-tabs-container {
            .ammenu-title {
                height: 60px;
                z-index: 9000000;
                position: relative;

                > a {
                    font-weight: bold;
                    color: @white !important;
                }  
                
                &.active {

                    > a {
                        color: @dark-green !important;
                    }
                }

                &:first-child {
                    margin-left: 70px;
                }
            }

            > .ammenu-content {
                margin-top: 60px;
                height: calc(100% - 60px);
            }
        }
    }

}

.minicart-wrapper {
    display: flex;
    align-items: center;
    margin: 0 0 0 1rem;
    justify-content: flex-end;

    > a {
        width: 100%;
        display: flex;
        align-items: center;

        &:before {
            width : 42px;
            height: 42px;
            padding: 5px;
            color: white;
            font-size: 24px;
            content: '\f07a';
            border-radius: 50%;
            background-color: transparent;

            @media (min-width: 471px) {
                background: @light-green;
            }
        }

        &:hover {
            .text {
                color: @dark-green;
            }
        }
        
        .text {
            line-height: 1;
            margin-left: 8px;
            font-weight: bold;
            color: @dark-grey;
            font-family: inherit;
            text-transform: none;
            display: inline-block;
            letter-spacing: normal;
            transition: all 0.3s ease-in-out;
        }

        .showcart {
            background: red;
        }

        .counter {
            width: 60px;
            height: 55px;
            z-index: 50000;
            position: absolute;

            .counter-number {
                top: 0;
                right: 0;
                width: 15px;
                height: 14px;
                padding: 3px;
                font-size: 12px;
                line-height: 14px;
                font-weight: bold;
                position: absolute;
                border-radius: 50%;
                color: @dark-green;
                background: @white;
                border: 1px solid @dark-green;
            }
        }

    }
    .stripe-payments-express-minicart {
        display: none;
    }

    .block-minicart {
        border: 0;
        margin-top: 1rem;
        background: white;
        border-radius: 10px;
        padding: 35px 10px 10px 10px;
        box-shadow: 0px 0px 3px 5px rgba(0, 0, 0, 0.1);

        &:after {
            border-color: transparent transparent #ffffff transparent;
        }

        .subtitle {
            &.empty {
                padding: 1rem;
                border-radius: 10px;
                background: lighten(@light-red, 25%);
            }
        }

        .block-content {
            > .action {
                display: none;
            }
        }
    }
}


.ammenu-content {
    .header.links {
        li {
            text-transform: uppercase;
            border-bottom: 1px solid #c4c4c4 !important;

            a {
                font-weight: bold;
            }
        }
    }
}