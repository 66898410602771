

// Product quantity buttons.
.control.qty {
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media (min-width: @md-breakpoint) {
        justify-content: flex-start;
    }
 
    .control.btn {
        cursor: pointer;
        padding: 5px 15px;
        background: @white;
        border: 1px solid @mid-grey;

        span {
            text-align: center;
        }

        &:hover {
            background: darken(@light-grey, 10%);
        }
    }

    input[type="number"] {
        width: 40px;
        height: 34px;
        padding: 5px 10px;
        border: 0 !important;
    }
}

&.catalog-product-view .box-tocart .qty td:nth-child(3) {
    width: 140px;
    text-align: center;
}

&.catalog-product-view .box-tocart #qty {
    width: 40px;
    height: 34px;
    // font-size: 1.5rem;
    padding: 5px 10px;
    // font-weight: bold;
    // color: @dark-green;
    border: 0 !important;
}
&.catalog-product-view .box-tocart .qty-btn {
    cursor: pointer;
    padding: 5px 15px;
    background: @white;
    text-align: center;
    border: 1px solid @mid-grey;

    &:hover {
        background: darken(@light-grey, 10%);
    }

    &.qty-dec {
        float: left;
    }

    &.qty-inc {
        float: right;
    }
}