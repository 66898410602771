

.page-products {

    .toolbar {
        padding: 8px;
        display: flex;
        margin-bottom: 0;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: @light-grey;

        @media (min-width: 450px) {
            display: block;
            padding: 0 1.5rem;
            background: transparent;
        }

        .toolbar-amount {
            padding: 0;
            float: left;
            
            @media (min-width: 450px) {
                margin-right: 2rem;
            }
        }

        .toolbar-sorter {
            padding: 0;
            float: right;
            margin-left: 2rem;
        }
    }

    .products-grid {

        .product-item {
            padding-top: 0;
            padding-left: 0;
            padding-bottom: 1rem;
            margin-bottom: 3rem;
            width: 100% !important;
            margin-left: auto !important;
            transition: all 0.5s ease-in-out linear;

            @media (min-width: 460px) {
                width: 50% !important;
            }
            
            @media (min-width: @md-breakpoint) {
                width: 33.3335% !important;
                
                &:hover {
                    background: lighten(@light-grey, 3%);
                    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
                }
            }

            .product-item-info {
                width: auto;
                margin-left: auto;
                margin-right: auto;

                .product-img-main {
                    height: 235px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .product-item-actions {
                        display: none;
                    }
                }

                .product-item-details {
                    padding: 0 1rem;

                    .product-reviews-summary {
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        justify-content: center;

                        @media (min-width: 700px) {
                            flex-direction: row;
                        }

                        .rating-summary {

                            .rating-result {

                                > span {
                                    &:before {
                                        color: #FFCC00;
                                    }
                                }
                            }
                        }

                        .reviews-actions {
                            margin: 0;
                            padding: 0;
                            line-height: 1;
                            display: inline !important;

                            a {
                                color: @black;
                            }
                        }
                    }

                    .product-item-inner {
                        // display: none;
                        margin-top: 1rem;

                        .product-item-actions {
                            display: block;
                        }
                    }
                }

                .product-item-name {
                    height: 54px;
                    overflow: hidden;
                    font-size: 1.6rem;
                    font-weight: bold;
                    color: @dark-green;
                    text-overflow: ellipsis;
                }
            }

            form:not([data-role="tocart-form"]) {
                overflow: hidden;
                max-height: 218px;

                > div {
                    height: 38px;
                    margin-bottom: 0.5rem;
                }

                .row-1,
                .row-2 {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    input[type="radio"] {
                        cursor: pointer;
                    }
                }
    
                .row-1 {

                    > div:first-child {
                        text-align: left;
                    }
                    > div:last-child {
                        text-align: right;
                    }

                    .product-size {
                        font-weight: bold;
                    }
    
                    .product-price {
                        font-weight: bold;
                        color: @dark-green;
                    }
    
                    .fa-check {
                        color: @light-green;
                    }
    
                    .fa-times {
                        color: @light-red;
                    }
                }
    
                .row-2 {
                    font-size: .9rem;
    
                    p {
                        margin: 0;
                        font-weight: bold;
                        padding-left: 22px;
                        color: lighten(@black, 40%);
                    }
    
                    .product-old-price {
                        color: @light-red;
                        font-weight: bold;
                        padding-right: 22px;
                        text-decoration: line-through;
                    }
                }

                &.single-variation {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .product-prices {
                        margin-bottom: 0;
                    }
                        
                    .row-1 {
                        > div:first-child {
                            display: none;
                        }

                        > div:last-child {
                                width: 100%;

                            > .product-price {
                                font-size: 2rem;
                            }
                        }
                    }
                }

            }

            button.action.primary {
                border: 0;
                width: 100%;
                display: block;
                font-size: 1.2rem;
                margin-right: 1rem;
                padding: 0.8rem 1rem;
                text-transform: none;
                margin-bottom: 0.5rem;
                background-color: @dark-green;
                
                @media (min-width: @lg-breakpoint) {
                    width: auto;
                    margin-bottom: 0;
                    display: inline-block;
                }

                &:hover {
                    background-color: @black;
                }
            }

            a.action.viewproduct {
                border: 0;
                color: @white;
                font-weight: 700;
                font-size: 1.2rem;
                border-radius: 5px;
                padding: 0.8rem 1rem;
                text-transform: none;
                display: block;
                background-color: @light-green;
                
                @media (min-width: @lg-breakpoint) {
                    display: inline-block;
                }

                &:hover {
                    background-color: @black;
                }
            }
        }
    }

    // Not Needed as per Visuals
    .products-list {

    }
}


// Wish List Page
.wishlist-index-index {
    .main {
        .product-item {
            text-align: center;
            width: 50% !important;
            margin-left: auto !important;
            padding-top: 1rem !important;
            padding-left: 1rem !important;
            padding-right: 1rem !important;
            margin-bottom: 3rem !important;
            padding-bottom: 1rem !important;
            transition: all 0.5s ease-in-out linear;
            
            @media (min-width: @md-breakpoint) {
                width: 33.3335% !important;
                
                &:hover {
                    background: lighten(@light-grey, 3%);
                    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
                }
            }

            .product-item-info {
                width: auto;
                margin-left: auto;
                margin-right: auto;

                .product-reviews-summary {
                    display: flex;
                    margin: 0 auto;
                    align-items: center;
                    flex-direction: row;
                    justify-content: center;

                    @media (min-width: 700px) {
                        flex-direction: row;
                    }

                    .rating-summary {

                        .rating-result {

                            > span {
                                &:before {
                                    color: #FFCC00;
                                }
                            }
                        }
                    }

                    .reviews-actions {
                        margin: 0;
                        padding: 0;
                        display: inline !important;

                        a {
                            color: @black;
                        }
                    }
                }

                .product-item-details {
                    padding: 0 1rem;


                    .product-item-inner {
                        // display: none;
                        margin-top: 1rem;

                        .product-item-actions {
                            display: block;
                        }
                    }
                }

                .product-item-name {
                    height: 54px;
                    overflow: hidden;
                    font-size: 1.6rem;
                    font-weight: bold;
                    color: @dark-green;
                    text-overflow: ellipsis;
                }
            }

            form:not([data-role="tocart-form"]) {
                overflow: hidden;

                > div {
                    margin-bottom: 0.5rem;
                }

                .row-1,
                .row-2 {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    input[type="radio"] {
                        cursor: pointer;
                    }
                }

                .row-1 {
                    .product-size {
                        font-weight: bold;
                    }

                    .product-price {
                        font-weight: bold;
                        color: @dark-green;
                    }

                    .fa-check {
                        color: @light-green;
                    }

                    .fa-times {
                        color: @light-red;
                    }
                }

                .row-2 {
                    font-size: .9rem;

                    p {
                        margin: 0;
                        font-weight: bold;
                        padding-left: 22px;
                        color: lighten(@black, 40%);
                    }

                    .product-old-price {
                        color: @light-red;
                        font-weight: bold;
                        padding-right: 22px;
                        text-decoration: line-through;
                    }
                }

            }

            .box-tocart {
                justify-content: center !important;
            }

            button.action.primary {
                border: 0;
                width: 100%;
                display: block;
                font-size: 1.2rem;
                padding: 0.8rem 1rem;
                text-transform: none;
                margin-bottom: 0.5rem;
                background-color: @dark-green;
                
                @media (min-width: @lg-breakpoint) {
                    width: auto;
                    margin-bottom: 0;
                    display: inline-block;
                }

                &:hover {
                    background-color: @black;
                }
            }

            .product-item-actions {
                a {
                    font-weight: bold;

                    &.edit {
                        display: none;
                    }

                    &.btn-remove {
                        color: @light-red;

                        &:hover {
                            opacity: 0.7;
                        }
                    }
                }
            }
        }

        .actions-toolbar {
            button {
                border: 0;
                width: 100%;
                color: white;
                display: block;
                font-size: 1.2rem;
                margin-right: 1rem;
                padding: 0.8rem 1rem;
                text-transform: none;
                margin-bottom: 0.5rem;
                background-color: @dark-green;
                
                @media (min-width: @lg-breakpoint) {
                    width: auto;
                    margin-bottom: 0;
                    display: inline-block;
                }

                &:hover {
                    background-color: @black;
                }
            }
        }
    }

    #confirmBox {
        .amcart-message-box {
            .amcart-product-notice {
                color: @white;
                padding: 0.5rem 2rem;
                background: @light-green;
            }

            .product-item-info {
                margin-top: 2rem;
            }

            .am-photo-container {
                width: 40%;

                a {
                    img {
                        margin: 0;
                    }
                }
            }

            .ampage-top-info,
            .ampage-bottom-info {
                width: 60%;

                .am-title {
                    color: @dark-green;
                }

                .product-reviews-summary {
                    display: flex;
                    margin: 0 auto;
                    align-items: center;
                    flex-direction: row;
                    justify-content: flex-start;

                    @media (min-width: 700px) {
                        flex-direction: row;
                    }

                    .rating-summary {

                        .rating-result {

                            > span {
                                &:before {
                                    color: #FFCC00;
                                }
                            }
                        }
                    }

                    .reviews-actions {
                        margin: 0;
                        padding: 0;
                        display: inline !important;

                        a {
                            color: @black;
                        }
                    }
                }

                .am-price {

                }
            }
        }
    }
}

.owl-buttons {
    .owl-prev,
    .owl-next {
        background: @dark-green !important;

        &:hover {
            background: black !important;
        }
    }
}



// .fotorama__wrap,
// .fotorama__stage,
// .fotorama__wrap--slide .fotorama__stage__frame {
//     max-height: 500px;
// }

.fotorama__stage__frame,
.fotorama__arr--next,
.fotorama__arr--prev,
.fotorama__stage {
    max-height: 500px;
}

.fotorama--fullscreen .fotorama__stage {
    max-width: none !important;
    max-height: none !important;
}

.fotorama--fullscreen {
    z-index: 9999000 !important;
}

.fotorama__nav--thumbs {
    display: flex;
    justify-content: center;
}

.actions-toolbar > .primary .action:last-child {
    padding: 0.8rem;
    background: @dark-green;

    > span {
        color: @white;
        font-size: 1.2rem;
    }
}

.actions-toolbar > .secondary,
.actions-toolbar > .secondary .action:last-child {
    width: 100%;
    display: block;
    text-align: center;

    > span {
        font-size: 1rem;
        text-align: center;
    }
}

aside{
    .block-customer-login {

        #block-customer-login-heading {
            font-weight: bold;
        }

        .block-content {
            .g-recaptcha {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .g-recaptcha .grecaptcha-badge {
                margin-top: 2rem;
                // transform: scale(0.8);
            }
        }
    }
}

.validator-email {
    #customer-email-error {
        margin-top: 29px;
    }
}
.validator-phone {
    width: 100% !important;
}
.feedback-phone {
    z-index: 50;
    right: -1px;
}

.link.rewards{display: none !important;}

@media (min-width: 768px) {

    .modal-custom.authentication-dropdown.custom-slide._show {
        right: 0 !important;
        top: 56px !important;
        width: 600px !important;
    }
}

@media (max-width: 450px) {
    .pages-items {
        .item {
            &:nth-child(4),
            &:nth-child(5) {
                display: none;
            }
        }
    }
}
@media (max-width: 350px) {
    .pages-items {
        .item {
            &:nth-child(3) {
                display: none;
            }
        }
    }
}

.checkout-index-index {
    .payment-option.rewards-block {
        display: none;
    }
}

#block-rewards-form {

    .title {
        &:after {
            display: none;
        }
    }

    #block-rewards-points-form {
        display: block !important;

        .discount-form {
            p {
                margin-bottom: 0px;
            }

            .input-box {
                .input-text {
                    margin-top: 0;
                }

                label {
                    padding: 0 8px;
                }

                #points_amount:valid+label,
                #points_amount:focus+label {
                    display: none;
                }
            }
        }
    }
}


body.checkout-onepage-success  {
    .page-wrapper {
        margin-bottom: 4rem;
    }
}

.block-customer-login {
    div.email,
    div.password {
        margin-bottom: 10px;
    }
    div.note {
        margin-bottom: 16px;
    }
}

body.checkout-index-index .message.notice {
    margin: 0 0 9px 130px !important; 
}