.widget {

    .page-title-main {
        z-index: 1;
        font-size: 2rem;
        margin-bottom: 0;
        color: @mid-green;
        position: relative;
        margin-bottom: 2rem;
        text-transform: none;
        
        strong {
            border: 0;
            z-index: 2;
            margin: 0 auto;
            color: inherit;
            font-size: inherit;
            position: relative;
            padding: 15px 40px;
            display: inline-block;
            text-transform: inherit;
            background-color: @white;
        }

        &:before {
            left: 0;
            top: 50%;
            right: 0;
            height: 1px;
            content: "";
            position: absolute;
            transform: translateY(-50%);
            background-color: @mid-green;
        }
    }

    // Homepage
    &.block-new-products {

        // Image
        .product-img-main {

            .product-item-inner {
                display: none;
            }
        }

        .product-item-details {

            .product-item-name a {
                font-weight: bold;
                color: @dark-green;
            }

            .product-item-name {
                height: 48px;
                display: flex;
                overflow: hidden;
                justify-content: center;
            }

            .product-reviews-summary {
                margin: 0;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;

                @media(min-width: @md-breakpoint) { 
                    flex-direction: row;
                }

                .reviews-actions {
                    margin: 0;
                    line-height: 1;
                    display: block !important;
                }

                .view {
                    color: @black;
                    font-weight: bold;
                }

                .add {
                    display: none;
                }
            }
            
            .product-star-rating {
                // height: 26px;
                overflow: hidden;

                .rating-summary .rating-result > span:before {
                    color: #FFCC00;

                    span {
                        display: inline;
                    }
                }
            }

            .price-box {
                height: 50px;
                display: flex;
                overflow: hidden;
                margin: 10px 0 0 0;
                align-items: center;
                justify-content: center;

                .price-label {
                    display: none;
                }

                .price {
                    font-size: 1.8rem;
                    color: @dark-green;   
                    
                    &:before {
                        color: @black;
                        display: block;
                        font-size: 1rem;
                        font-weight: normal;
                        content: "Prices from:";
                    }
                }
            }

            .product-item-inner {
                .product-item-actions {
                    padding: 0;
                    margin-top: 10px;

                    button {
                        border: 0;
                        border-radius: 8px;
                        text-transform: none;
                        padding: 0.8rem 2rem;
                        background-color: @light-green;

                        &:hover {
                            background-color: @dark-green;
                        }
                    }
                }
            }
        }


        .product-star-rating {
            color: red;
            text-align: center;
        }
    }

    // Temp Stuff
    .testmonial-back {
        .page-title-main {
            strong {
                background-color: #f5f5f5;
            }
        }
    }
}

// .block {

//     &.related {

//         .page-title-main {
//             margin-bottom: 3px;
//             text-align: left;

//             strong {
//                 border: 0;
//                 height: 36px;
//                 color: @white;
//                 margin-bottom: 0;
//                 font-size: 1.3rem;
//                 line-height: 37px;
//                 font-weight: normal;
//                 text-transform: none;
//                 padding: 0 35px 0 35px;
//                 background: @light-green;
//                 border-radius: 5px 5px 0 0;

//                 &:hover {
//                     background: darken(@light-green, 2%);
//                 }
//             }
//         }

//         .content {
//             border-top: 2px solid darken(@light-grey, 2%);
//         }
//     }
// }

.sidebar.sidebar-additional {

    .block-compare {
        display: none;
    }

    .product-item {
        display: flex;
    }

    .product-items {
        margin-bottom: 2rem;
    }

    .actions-toolbar {
        display: flex;
        max-width: none;
        margin: 1rem 0 0 0;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: space-between;

        > div {

            button {
                margin: 0 0 0 2rem;
            }

            a {
                margin: 0;
            }
        }
    }

    button.action.primary {
        border: 0;
        border-radius: 8px;
        text-transform: none;
        padding: 0.8rem 2rem;
        background-color: @dark-green;

        &:hover {
            background-color: @light-green;
        }
    }

    .reorder {
        a {
            color: @black;
        }

        .actions-toolbar {
            button {
                border: 0;
                font-size: 1.2rem;
                border-radius: 8px;
                text-transform: none;
                padding: 0.8rem 1rem;
                background-color: @dark-green;

                &:hover {
                    background-color: @light-green;
                }
            }
        }

        .action.view {
            &:hover {
                color: @dark-green;
            }
        }
    }
}

.suggested {
    margin-bottom: 2rem;
}

.mst-search__highlight {
    background: lighten(@light-green, 40%);
}

.search {

    // @media(max-width: @md-breakpoint) {
    @media(max-width: 767px) {
        // margin-left: 5rem;

        .block-search {
            margin-top: 0;
            position: initial !important;

            .label {
                margin-top: 0;
            }

            &:before {
                display: none !important;
            }

            .block-title {
                display: none;
            }

            .block-content {
                form {
                    .search {
                        label {

                        }
                        .control {
                            left: 0;
                            right: 0;
                            top: 75px;
                            z-index: 3000;
                            padding: 0 4rem;
                            position: absolute;
                            background: @dark-green;

                            @media (max-width: 470px) {
                                top: 120px;
                            }

                            #search {
                                background-color: @white;

                                &:focus,
                                &:active {
                                    outline-offset: 3px !important;
                                    outline-width: 1px !important;
                                    outline-color: white !important;
                                    outline-style: dashed !important;
                                }
                            }
                        }

                        .actions {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .minisearch {

        .control {
        }
    }

    &.results {
        > .block {

            &:before,
            &:after {
                content: "";
                display: table;
            }

            &:after {
                clear: both;
            }

            dd {
                float: left;
                margin-right: 0.5rem;
    
                &:after {
                    content: ",";
                    margin-left: -4px;
                }
    
                &:last-child {
    
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    .products {
        clear: both;
        margin-top: 4rem;
    }
}



.mst-searchautocomplete__autocomplete {
    margin-top: 2rem;
    overflow: hidden;
    border-radius: 0.4rem;

    .mst-searchautocomplete__highlight {
        color: @light-green !important;
    }

    .mst-searchautocomplete__wrapper {
        overflow: hidden;
        border-radius: 0.4rem;
        border: 1px solid @light-grey;
    }

    .mst-searchautocomplete__index-title {
        color: @white;
        background-color: @dark-green;
    }

    .mst-searchautocomplete__spinner {
        margin-top: 4px;
    }

    .mst-searchautocomplete__close {
        color: @white;
        padding: 0.7rem 1rem;
    }

    .mst-searchautocomplete__index {
        border: 0;
    }

    .product-reviews-summary .rating-summary {
        left: 0;

        .rating-result > span:before {
            color: #FFCC00;
        }
    }
}

.page.messages {

    a {
        color: @black;
    }

}

.pages {
    ul {
        li {
            a {
                background: @dark-green;
                border-color: @dark-green;

                &:hover {
                    background: @black;
                    border-color: @black;
                }
            }

            &.current {
                strong {
                    background: @light-green;
                    border-color: @light-green;
                }
            }
        }
    }   
}

.stock.available {
    background: @dark-green;
    border-color: @dark-green;
}

.product-reviews-summary {
    .reviews-actions {
        a.add {
            color: @dark-green;
        }
    }
}

.header.minicart {

    a {
        color: @black;
    }

    .subtotal {
        display: flex;
        justify-content: flex-end;

        .label {
            margin-right: 6px;

            &:after {
                margin-right: 6px;
            }
        }
    }

    #top-cart-btn-checkout {
        background: @dark-green;
        border-color: @dark-green;
        color: @white;

        span {
            display: block;
            margin: 0 auto;
        }

        &:hover {
            background: @black;
            border-color: @black;
        }
    }

    .minicart-items-wrapper {
        margin: 0 0 0 0;
        border-bottom: 0;
        padding: 15px 12px 0;

        &.active {
            margin: 0 -10px 0 1rem;
        }

        .minicart-items {
            li {
                &:after {
                    display: none;
                }

                &:last-child {
                    padding-bottom: 0;
                }

                .product {
                    display: flex;

                    a {
                        line-height: 1;
                        margin: 0 0 8px 0;
                        width: auto !important; 
                        
                        .product-image-container {
                            margin: 0;
                            width: 100px;
                            height: auto;

                            .product-image-wrapper {
                                margin: 0;

                                img {
                                    margin-bottom: 0;
                                    width: auto !important;
                                    height: auto !important;
                                }
                            }
                        }
                    }
                    div.product-item-details {
                        width: 100%;
                        padding: 0 0 0 10px;

                        .product-item-name {
                            margin: 0;
                            line-height: 1;

                            a {
                                &:hover {
                                    color: @dark-green;
                                }
                            }
                        }

                        .product-item-pricing {

                            .label {
                                width: 2.5rem;
                            }

                            .price-container {
                                margin: 0;

                                .price-wrapper {
                                    margin: 0;

                                    .price-excluding-tax {
                                        margin: 0;

                                        .minicart-price {
                                            margin: 0;

                                            .price {
                                                margin: 0;
                                            }
                                        }
                                    }
                                }
                            }

                            .details-qty {
                                margin-top: 4px;

                                input {
                                    border-radius: 5px;
                                }

                                button.update-cart-item {
                                    padding: 6px;
                                    color: @white !important;
                                    background: @light-green;
                                    border-color: @light-green;

                                    &:hover {
                                        background: @dark-green;
                                        border-color: @dark-green;
                                    }

                                    span {
                                        color: @white !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .block-content {
        > .actions {
            
            &:last-child {
                display: none;
            }
        }
    }

    .actions {
        .secondary {
            a {
                width: 100%;
                display: block;
                font-weight: bold;
                text-align: center;
                color: @light-green;
                margin-bottom: 10px;
                text-transform: uppercase;

                &:hover {
                    color: @dark-green;
                }
            }
        }
    }
}

.block-search .control {
    border: none !important;
}

@media(max-width: 980px) {
    .minicart-wrapper {
        margin: 0 8px 0 0;

        .block-minicart {
            max-width: 360px;
            z-index: 30000;
            margin-left: 10px;
            margin-right: 10px;
            width: calc(100vw - 17px);
        }

        .action.showcart {
            .text {
                display: none;
            }
        }
        .counter-number {
            right: 10px !important;
        }
    }
}

@media(max-width: 450px) {
    .minicart-wrapper {

        .items-total,
        .subtotal {
            margin: 0;
            width: 100%;
            line-height: 1;
            text-align: center;
        }

        .subtotal {
            justify-content: center !important;
        }
    }
}