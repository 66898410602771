html body {

    &.cms-no-route {

        .page-wrapper {
            min-height: 70vh;
        }

        main {
            padding: 5rem 1.5rem 2rem 1.5rem;

            .columns {

                &:before,
                &:after {
                    content: '';
                    display: table;
                }

                &:after {
                    clear: both;
                }

                .column {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    h1 {
                        margin-bottom: 2rem;
                    }

                    a {
                        color: @light-green;

                        &:hover {
                            color: @dark-green;
                        }
                    }
    
                    .col {
                        float: left;
                    }
    
                    @media (min-width: @md-breakpoint) {
                        .md-col-4 {
                            padding-right: 3rem;
                        }
                    }

                    .btn {
                        margin-top: 2rem;
                        float: left;
                        
                        a {
                            color: @white;
                            padding: 6px 15px;
                            margin-right: 15px;
                            border-radius: 10px;
                            text-transform: capitalize;
                            background-color: @light-green;

                            &:hover {
                                background-color: @dark-green;
                            }
                        }
                    }
                }
            }
        }
    }
}
