

// Colours
@white: white;
@yellow: yellow;
@light-grey: #EFEFEF;
@mid-grey: #C1C1C1;
@dark-grey: #46484a;
@light-red: #EC615E;
@red: #D6171C;
@light-green: #92bf1f;
@mid-green: #2CAA66;
@dark-green: #067C58;
@black: #000000;

// Breakpoints
@sm-breakpoint: 767px;
@md-breakpoint: 939px;
@lg-breakpoint: 1200px;

// Content Sizes
@standard-content: 1.3rem; 

@heading-style: "verveine";

html {
    font-size: 12px;
    overflow-x: hidden;

    &.ie11 {
        height: auto !important;
        
        body {
            height: auto !important;
        }

        .page-wrapper {
            height: auto !important;
        }
    }
}

html body#body {
    overflow-x: hidden;
    
    // .page-wrapper {
    //     overflow-x: hidden;
    // }

    &.cms-home {
        .page-main {
            .page-main {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

// Header
@import "modules/_header.less"; 
@import 'modules/_search.less';
@import 'modules/_navigation.less';
@import 'modules/_testimonials_slider.less';
@import 'modules/_boxes.less';

// Footer 
@import 'modules/_footer.less';
@import 'modules/_newsletter.less';

// Slider
@import 'modules/_slider.less';

// Breadcrumbs
@import 'modules/_breadcrumbs.less';

// Categories
@import 'modules/_sidebar.less';

// Products
@import 'modules/_attribute.less';
@import 'modules/_cta_banners.less';
@import 'modules/_details_tabs.less';
@import 'modules/_add_to_basket.less';
@import 'modules/_product_details.less';
@import 'modules/_images.less';
@import 'modules/_social_share.less';

// Blocks
@import 'modules/_widgets.less';

}

.message.notice a,
a {
    color: @dark-green;

    &:hover {
        color: @black;
    }
}

@import 'pages/_basket_index.less';
@import 'pages/_customer_login.less';
@import 'pages/_category.less';
@import 'pages/_404.less';
@import 'pages/_customer-account-create.less';
@import 'pages/_myaccount.less';
@import 'pages/_checkout.less';
@import 'pages/_compare.less';
@import 'pages/_product.less';
@import 'pages/_contact.less';
@import 'pages/_faq.less';


// Other
@import 'modules/_misc.less';

// NOTES

/*
NOTE: Basket Icon
    content: "\f291";
    color: #5BD77B;
    font-size: 28px;
    font-family: "Fontawesome";
*/