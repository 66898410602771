footer {
    clear: both;

    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }


    .footer-top {
        background: @light-grey;

        .cta.newsletter {
            padding: 3rem 0;
            background: @dark-green;

            .content {
                width: auto;
                display: flex;
                max-width: 1280px;
                margin-left: auto;
                position: relative;
                margin-right: auto;
                padding-left: 15px;
                align-items: center;
                padding-right: 15px;
                flex-direction: column;
                box-sizing: border-box;

                &:before,
                &:after {
                    content: '';
                    display: table;
                }

                &:after {
                    clear: both;
                }

                .icon {
                    left: 0;
                    display: none;
                    bottom: -3.5rem;
                    position: absolute;

                    @media (min-width: @md-breakpoint) {
                        display: block;
                    }
                }

                .content-wrapper {
                    flex-grow: 1;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    padding: 1rem 0 1rem 1.5rem;

                    .content-text {
                        width: 100%;
                        
                        h5, p {
                            color: @white;
                        }
    
                        h5 {
                            font-size: 2.5rem;
                            text-align: center;
                            margin: 0 0 1rem 0;

                            @media (min-width: @sm-breakpoint) {
                                margin: 0 0 1rem 0;
                            }
                        }
    
                        p {
                            max-width: 460px;
                            text-align: center;
                            margin: 0 0 1.5rem 0;
                            font-size: @standard-content;

                            @media (min-width: @sm-breakpoint) {
                                display: block;
                            }
                        }
                    }

                    .content-button {
                        .button {
                            color: @white;
                            display: block;
                            border-radius: 10px;
                            padding: 0.8rem 1.4rem;
                            background: @light-green;
                            text-transform: uppercase;

                            &:hover {
                                color: @dark-green;
                                background: @white;
                            }
                        }
                    }
                }
            }


            &:before,
            &:after {
                content: '';
                display: table;
            }
        }

        .footer {
            border: 0;
            clear: both;
            display: flex;
            padding: 1rem 0 0 0;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            @media (min-width: @sm-breakpoint) {
                flex-direction: row;
            }

            * {
                box-sizing: border-box;
            }

            .footer-item {
                width: 100%;
                padding: 0 1rem;

                @media (min-width: @sm-breakpoint) {
                    padding: 1rem;
                }

                ul {
                    display: none;
                    padding: 0 1rem;
                }

                h5 {
                    margin: 0;
                    width: 100%;
                    color: @white;  
                    padding: 1rem; 
                    display: block;
                    cursor: pointer;
                    font-size: 1.4rem;
                    position: relative;
                    background: @dark-green;
                    border-bottom: 2px solid @white;

                    &:after {
                        top: 50%;
                        right: 10px;
                        content: "\f107";
                        position: absolute;
                        font-weight: normal;
                        font-family: "Fontawesome";
                        transform: translateY(-50%);
                    }
                }

                &:nth-child(even) {
                    h5 {
                        background-color: @light-green;
                    }
                }

                &[data-expand="true"] {
                    h5 {
                        border-bottom: 0;
                        
                        &:after {
                            top: 50%;
                            right: 10px;
                            content: "\f106";
                            position: absolute;
                            font-weight: normal;
                            font-family: "Fontawesome";
                            transform: translateY(-50%);
                        }
                    }
                    
                    ul {
                        display: block;
                        background: @white;
                        border-left: 2px solid @mid-grey;
                        border-right: 2px solid @mid-grey;
                        border-bottom: 2px solid @mid-grey;

                        li {
                            padding: 0.5rem 0;

                            @media (min-width: @sm-breakpoint) {
                                padding: 0 !important;
                            }

                            a {
                                color: @black;
                            }
                        }
                    }
                }

                @media (min-width: @sm-breakpoint) {
                    width: 100%;
                    padding: 1.5rem 1rem;
                    
                    @media (min-width: @sm-breakpoint) {
                        width: (100%/5);
                    }

                    h5 {
                        padding: 0;
                        cursor: default;
                        margin: 0 0 1rem;
                        font-size: 1.4rem;
                        color: @dark-green;
                        border-bottom: 0;
                        background: transparent !important;

                        &:after {
                            content: "";
                            display: none;
                        }
                    }

                    ul {
                        border: 0 !important;
                        padding: 0 !important;
                        display: block !important;
                        background: transparent !important;

                        li {
                            margin-bottom: 5px;

                            p, a {
                                font-size: 1.1rem;
                                color: @dark-grey;
                            }
                            
                            a {
                                cursor: pointer;

                                &:hover {
                                    color: @dark-green;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .footer-bottom {
        padding: 2rem 0;
        background: @light-grey;

        > .page-main  {
            display: flex;
            flex-direction: column;

            @media (min-width: @sm-breakpoint) {
                flex-direction: row;
            }

            > div {
                width: 100%;
                
                @media (min-width: @sm-breakpoint) {
                    width: (100%/2);
                    
                    &:first-child,
                    &:last-child {
                        width: (100%/4);
                    }
                }

                .google-review {
                    display: none;
                    background: @white;
                    border-radius: 2px;
                    border: 2px solid @mid-grey;

                    @media (min-width: @sm-breakpoint) {
                        display: block;
                    }
                }

                &.footer-copyright-outer {
                    padding: 1rem 2rem; 
                    box-sizing: border-box;

                    .payment-icons {
                        ul {
                            margin: 0;
                            padding: 0;
                            display: flex;
                            flex-wrap: wrap;
                            list-style: none;
                            align-items: center;
                            justify-content: center;
                            
                            li {
                                padding: 10px;
                                margin-bottom: 0;

                                img {
                                    max-width: 40px;
                                }
                            }
                        }
                    }

                    small.copyright {
                        display: block;
                        color: @mid-grey;
                        margin-top: 10px;
                        text-align: center;

                        a {
                            color: inherit;

                            &:hover {
                                color: @dark-green;
                            }
                        }
                    }
                }

                &.footer-badges-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: row-reverse;
                    
                    @media (min-width: @sm-breakpoint) {
                        justify-content: flex-end;
                    }

                    img.fair-to-nature {
                        max-width: 90px;
                        max-height: 70px;
                        margin-left: 1rem;
                    }
                }
            }
        }
    }
}