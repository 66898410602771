.checkout-index-index {

    * {
        box-sizing: border-box;
    }

    a {
        color: @dark-green;
    }

    form {
        .field.required > label,
        .field._required > label {
            position: relative;
            padding-right: 10px;

            &:after {
                top: 0;
                right: 0;
            }
        }
    }

    .checkout-container {
        margin: 3rem 0;

        .authentication-wrapper {
            display: none;
        }

        .opc-progress-bar {

            .opc-progress-bar-item {

                > span {
                    font-size: 1.4rem;
                    font-weight: bold;
                        
                    &:after {
                        line-height: 1.5;
                        font-size: 1.4rem;
                    }
                }

                &._active,
                &._complete {
                    
                    &:before {
                        background: @dark-green;
                    }

                    > span {
                        &:before {
                            background: @dark-green;
                        }

                        &:after {
                            font-size: 2.2rem;
                            line-height: 1.05;
                            color: @dark-green;
                        }
                    }
                }
            }
        }

        .opc-estimated-wrapper {

            .estimated-price {
                font-size: 2rem;
                color: @dark-green;
            }
        }

        #shipping {
            margin-top: 2rem;

            .step-title {
                font-weight: 600;
                font-size: 1.6rem;
                padding: 10px 20px;
                background: @light-grey;
                text-transform: uppercase;

                &:before {
                    content: "1. ";
                }
            }

            #checkout-step-shipping {

                form {
                    padding: 0 20px;
                    max-width: none;
                }

                form.form-login {
                    padding: 20px;
                    background: lighten(@mid-green, 50%);

                    .ml-login-title {
                        display: block;
                        font-weight: bold;
                        font-size: 1.6rem;
                        margin: 0 0 16px 0;
                        color: @dark-green;
                    }

                    .ml-login-content {
                        margin: 0 0 26px 0;
                    }

                    .ml-login-end {
                        display: block;
                        font-size: 1.4rem;
                        color: @dark-green;
                    }

                    #customer-email-fieldset {
                        margin: 0 0 16px;   
                    }

                    input {
                        width: 100%;
                    }

                    .validator-email {

                        // @media (min-width: @md-breakpoint) {
                            padding-right: 4rem;
                        // }
                    }

                    .note {
                        display: none;
                    }

                    .field-tooltip {
                        > span {
                            > span {
                                display: none;
                            }
                        }
                    }

                    .actions-toolbar {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        button.primary {
                            border: 0;
                            color: white;
                            min-width: 160px;
                            font-weight: bold;
                            border-radius: 8px;
                            text-transform: none;
                            padding: 0.8rem 4rem;
                            background: @dark-green;
                            text-transform: uppercase;
                
                            &:hover {
                                background-color: @light-green;
                            }
                        }

                        .secondary {
                            width: auto;
                            margin-left: 2rem;

                            a {
                                margin: 0;

                                &:hover {
                                    color: @black;
                                }
                            }
                        }
                    }
                }
            }
        }

        .checkout-billing-address {

            .billing-address-form {

                #tooltip-label {
                    span {
                        display: none;
                    }
                }

                form {
                    > .fieldset {
                        > .field {
                            width: 100%;

                            @media (min-width: @md-breakpoint) {
                                &:nth-child(1) {
                                    width: 50%;
                                }
                                &:nth-child(2) {
                                    width: 50%;
                                }
                                &:nth-child(4) {
                                    width: 100%;
                                }
                                &:nth-child(5) {
                                    width: 100%;
                                }
                                &:nth-child(6) {
                                    width: 50%;
                                }
                                &:nth-child(7) {
                                    width: 50%;
                                }
                                &:nth-child(8) {
                                    width: 50%;
                                }
                                &:nth-child(9) {
                                    width: 50%;
                                }
                                &:nth-child(10) {
                                    width: 50%;
                                }
                                &:nth-child(11) {
                                    width: 50%;
                                }
                            }
                        }
                    }
                }
            }
            
        }

        #shipping-new-address-form {
            display: flex;
            flex-wrap: wrap;

            .field-tooltip {
                > span {
                    > span {
                        display: none;
                    }
                }
            }

            > .field {
                width: 100%;

                @media (min-width: @md-breakpoint) {
                    &:nth-child(1) {
                        width: 50%;
                    }
                    &:nth-child(2) {
                        width: 50%;
                    }
                    &:nth-child(4) {
                        width: 100%;
                    }
                    &:nth-child(5) {
                        width: 100%;
                    }
                    &:nth-child(6) {
                        width: 50%;
                    }
                    &:nth-child(7) {
                        width: 50%;
                    }
                    &:nth-child(8) {
                        width: 50%;
                    }
                    &:nth-child(9) {
                        width: 50%;
                    }
                    &:nth-child(10) {
                        width: 50%;
                    }
                    &:nth-child(11) {
                        width: 50%;
                    }
                }
            }
        }

        #opc-shipping_method {

            .step-title {
                font-weight: 600;
                font-size: 1.6rem;
                padding: 10px 20px;
                background: @light-grey;
                text-transform: uppercase;

                &:before {
                    content: "2. ";
                }
            }

            #checkout-step-shipping_method {
                padding: 0 20px;

                table {
                    width: 100%;
                    max-width: none;
                }
            }
        }

        .opc-block-summary {
            padding: 10px 20px 20px;
            background: @light-grey;

            .title {
                border: 0;
                cursor: auto;
                font-weight: 600;
                font-size: 1.6rem;
                padding: 0 0 10px 0;
                background: @light-grey;
                text-transform: uppercase;

                &:after {
                    display: none;
                }
            }

            .items-in-cart {

                .title {
                    padding: 10px 0;
                    text-align: right;
                    text-transform: none;
                    border-top: 1px solid @mid-grey;
                    border-bottom: 1px solid @mid-grey;

                    strong {
                        font-size: 1.2rem;
                        font-weight: bold;
                    }
                }

                .content {
                    display: block !important;

                    ol.minicart-items {
                        li.product-item {

                            .product-item-details {
                                padding-left: 88px !important;

                                .product-item-name-block {
                                    padding-right: 16px;

                                    .product-item-name {
                                        margin-bottom: 0;
                                    }

                                    .details-qty {
                                        margin-top: 4px;
                                        font-size: 1rem;
                                        font-weight: bold;

                                    }
                                }

                                .cart-price {
                                    color: @dark-green;
                                    
                                    > span {
                                        font-weight: bold;
                                    }
                                }
                            }

                            .product-image-container {
                                margin: 0;
                                width: 100%;
                                max-width: 77px;

                                img {
                                    width: auto;
                                    height: auto;
                                }
                            }
                        }
                    }
                }
            }
        }

        .opc-block-shipping-information {
            margin: 2rem -12px 0;
            background: @light-grey;
            
            > div {
                padding: 10px 20px 20px;
            }

            .ship-via {
                padding-top: 2rem;
                border-top: 1px solid #C1C1C1;
            }

            .shipping-information-title,
            .shipping-information-title {
                border: 0;
                cursor: auto;
                font-weight: 600;
                font-size: 1.6rem;
                padding: 0 0 10px 0;
                text-transform: uppercase;
            }
        }

        .actions-toolbar {
            margin: 1rem 0 0 0;
            
            button.continue {
                border: 0;
                color: white;
                min-width: 160px;
                font-weight: bold;
                border-radius: 8px;
                text-transform: none;
                padding: 0.8rem 4rem;
                background: @dark-green;
                text-transform: uppercase;
    
                &:hover {
                    background-color: @light-green;
                }
            }
        }

        #checkout-payment-method-load {
            button.checkout {
                border: 0;
                color: white;
                min-width: 160px;
                font-weight: bold;
                border-radius: 8px;
                text-transform: none;
                padding: 0.8rem 4rem;
                background: @dark-green;
                text-transform: uppercase;
    
                &:hover {
                    background-color: @light-green;
                }
            }
        }
    }

    // #tooltip-label {
    //     display: none;
    // }

    // header {

    //     .header {
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         flex-direction: column !important;
    //     }
    // }

    // .my-flex {
    //     display: flex;
    //     justify-content: center;

    //     .logo img {
    //         width: 100%;
    //         height: auto;
    //     }
    // }

    // .am-checkout {
    //     margin: 0;
    //     padding: 0;
    // }

    // .checkout-container {
    //     position: relative;
    //     background: transparent !important;

    //     .authentication-wrapper {
    //         margin: 0;
    //         top: 9rem;
    //         left: 1rem;
    //         padding: 0;
    //         max-width: none;
    //         position: absolute;
            
    //         @media (min-width: 485px) {
    //             top: 7rem;
    //             width: 100% !important;
    //             text-align: left !important;
    //         }

    //         @media (min-width: @md-breakpoint) {
    //             left: auto;
    //             top: 2.3rem;
    //             right: 2rem;
    //             bottom: auto;
    //             width: auto !important;
    //         }


    //         button.action-auth-toggle {
    //             border: 0;
    //             color: @white;
    //             font-weight: bold;
    //             background: @black;
    //             border-radius: 8px;
    //             text-transform: none;
    //             padding: 0.8rem 4rem;
    //             text-transform: uppercase;

    //             -webkit-animation: wiggle 5s infinite; /* Safari 4+ */
    //             -moz-animation:    wiggle 5s infinite; /* Fx 5+ */
    //             -o-animation:      wiggle 5s infinite; /* Opera 12+ */
    //             animation:         wiggle 5s infinite; /* IE 10+, Fx 29+ */

    //             &:hover {
    //                 background-color: @dark-grey;
    //             }

    //             span {

    //             }
    //         }
    //     }

    //     .product-item-inner {
    //         display: flex;
    //         flex-direction: column;
    //     }

    //     .opc-wrapper {
    //         .checkout-column {
    //             &:first-child {
    //                 margin-left: -10px;
    //             }
    //             &:last-child {
    //                 margin-right: -10px;
    //             }
    //         }
    //     }

    //     .checkout-header {
    //         width: 100%;
    //         padding: 1rem;
    //         position: relative;
    //         margin-bottom: 1rem;
    //         padding-bottom: 6rem;
    //         background-color: @light-green;

    //         @media (min-width: @md-breakpoint) {
    //             padding-bottom: 1rem;
    //         }

    //         .title {
    //             color: @white;
    //             font-size: 2rem;
    //             margin-bottom: 0;
    //         }

    //         .description {
    //             color: @white;
    //             font-size: 16px;

    //             @media (min-width: @md-breakpoint) {
    //                 max-width: 60%;
    //             }

    //             &:after {
    //                 content: ".";
    //             }
    //         }
    //     }

    //     .checkout-block {
    //         border-top: 4px solid @dark-green;
    //         border-bottom: 4px solid @dark-green;
    //     }

    //     .checkout-block,
    //     .amcheckout-title {
    //         border: 0;
    //         box-shadow: none;
    //         padding-left: 2rem;
    //         padding-right: 2rem;
    //         background: @light-grey;

    //         .amcheckout-title,
    //         .step-title {
    //             padding: 0;
    //             color: @black;
    //             font-size: 2rem;
    //             font-weight: 600;
    //             font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;;
    //         }
    //     }

    //     @field-border-radius: 10px;
    //     input, select {
    //         border: 0px;
    //         height: 38px;
    //         color: @black;
    //         background: @white;
    //         padding-left: 20px;
    //         padding-right: 20px;
    //         border-radius: @field-border-radius;
    //         border: 1px solid darken(@white, 10%);

    //         &:focus,
    //         &:active {
    //             border-radius: 5px;
    //             outline-width: 1px !important;
    //             outline-offset: 3px !important;
    //             outline-style: dashed !important;
    //             outline-color: lighten(black, 50%) !important;
    //         }
    //     }

    //     input[type="radio"] {
    //         height: 20px;
    //     }

    //     .payment-option-content {
    //         padding: 0 !important;

    //         #discount-code {
    //             margin-top: 1rem;
    //         }
    //     }

    //     .checkout-payment-method {
            
    //         br, .legend {
    //             display: none;
    //         }

    //         .stripe-payments {
                
    //             label {
    //                 span.stripe-icon-container {
    //                     float: none;
    //                     margin-bottom: 5px;
    //                     display: inline-block;
    //                 }

    //                 > span:not(.stripe-icon-container) {
    //                     display: block;
    //                     margin-left: 21px;
    //                 }
    //             }
    //         }
    //     }

    //     .opc-block-summary {
    //         background: @light-grey;
            
    //         .step-title {
    //             background: @light-grey;
    //         }

    //         .product-image-container {
    //             width: 110px !important;
    //             height: 110px !important;
    //             margin-left: 0 !important;

    //             img {
    //                 width: 110px !important;
    //                 height: 110px !important;
    //             }
    //         }

    //         .product-item-details {
    //             padding-left: 130px;
    //         }

    //         .totals.sub {
    //             background-color: darken(@light-grey, 5%);
    //         }
    //         .totals.grand {
    //             background-color: lighten(@light-green, 40%);

    //             .mark, .amount {
    //                 padding-top: 6px;
    //             }
    //         }
    //         .totals.shipping {
    //             .value {
    //                 font-size: .9rem;
    //             }
    //         }
    //     }

    //     .totals .mark {
    //         padding-left: 1rem !important;
    //     }
    //     .totals .amount {
    //         padding-right: 1rem !important;
    //     }

    //     .additional-options {
    //         margin: 1rem 0 0 0;
    //     }

    //     .checkout-block {

    //         .payment-option-title {
    //             padding: 0;

    //             .action {
    //                 color: @black;
    //             }
    //         }
    //     }

    //     button.checkout {
    //         padding: 1rem 4rem;
    //         background: @dark-green;
    //         border-color: @dark-green;
    //     }
    // }

    // .billing-address-same-as-shipping-block {
    //     display: flex;
    //     align-items: center;
    // }

    #stripe-payments-card-number {
        width: 240px;
    }

    // .pci-dss-info-block {
    //     width: 100%;
    // }

    // // .payment-method-title .label {
    // //     // display: none;
    // // }

    // .estimated-label {
    //     margin-bottom: 0;
    // }
    // .estimated-price {
    //     color: @dark-green;
    //     font-size: 2.5rem;
    // }
}

// @-webkit-keyframes wiggle {
//     0% { transform: rotate(0); }
//     93% { transform: rotate(0); }
//     95% { transform: rotate(6deg) scale(1.2); }
//     98% { transform: rotate(-6deg) scale(1); }
//     100% { transform: rotate(0); }
// }
// @-moz-keyframes wiggle {
//     0% { transform: rotate(0); }
//     93% { transform: rotate(0); }
//     95% { transform: rotate(6deg) scale(1.2); }
//     98% { transform: rotate(-6deg) scale(1); }
//     100% { transform: rotate(0); }
// }
// @-o-keyframes wiggle {
//     0% { transform: rotate(0); }
//     93% { transform: rotate(0); }
//     95% { transform: rotate(6deg) scale(1.2); }
//     98% { transform: rotate(-6deg) scale(1); }
//     100% { transform: rotate(0); }
// }
// @keyframes wiggle {
//     0% { transform: rotate(0); }
//     93% { transform: rotate(0); }
//     95% { transform: rotate(6deg) scale(1.2); }
//     98% { transform: rotate(-6deg) scale(1); }
//     100% { transform: rotate(0); }
// }