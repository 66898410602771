
body.catalog-product-view {

    .columns {
        margin-top: 2rem;
    }

    .page-title-wrapper {
        .page-title {
            color: @dark-green;
            text-transform: none;
        
        }
    }

    .product-reviews-summary {
        display: flex;
        margin: 0 auto;
        align-items: center;
        // flex-direction: column;
        justify-content: flex-start;

        @media (min-width: 700px) {
            flex-direction: row;
        }

        .rating-summary {

            .rating-result {

                > span {
                &:before {
                        color: #FFCC00;
                    }
                }
            }
        }

        .reviews-actions {
            margin: 0;
            padding: 0;
            display: inline !important;

            a.view {
                color: @dark-grey;
                font-weight: bold;
            }

            a.add {
                display: none;
                color: @dark-green;

                @media (min-width: @md-breakpoint) {
                    display: inline;
                }
            }
        }
    }

    .product-info-main {
        .overview {
            margin-top: 0 !important;
            border: none !important;

            .read-more {
                font-size: 14px;
                color: @dark-green;

                &:after {
                    content: "...";
                }
            }
        }
    }

    .product-reviews-summary {
        margin-top: 1rem;
    }

    .product-info-price {
        display: none;

        .product-info-stock-sku {
            .stock {
                margin-top: 0;
                margin-bottom: 0;

                &.available {
                    background: @dark-green;
                }
                &.available {
                    background: @dark-green;
                }
            }
        }
    }

    .minimal-price {
        display: none !important;
    }
    
    .box-tocart {
        margin-top: 0;

        > div {
            margin: 0;
            padding: 0;
            width: 100%;

            > div {
                margin: 0;
                width: 100%;
                padding: 0 !important;
                display: block !important;

                button {
                    font-weight: bold;
                    border-radius: 10px !important;
                    width: 100% !important;
                }
            }
        }
    }

    // .price-final_price {
    //     display: none;
    // }

    .underprice-wrapper {
        display: flex;
        margin: 2rem 0 4rem 0;

        @media (max-width: @md-breakpoint) {
            flex-direction: column;
        }
        
        .underprice.item {
            width: 50%;
            display: flex;
            align-items: center;
            max-width: 49.999994%;
            justify-content: center;

            @media (max-width: @md-breakpoint) {
                width: 100%;
                max-width: 100%;
                justify-content: center;
            }
            
            .underprice_icon-wrapper {
                width: 84px;
                display: flex;
                justify-content: center;
            }

            .underprice_content-wrapper {
                width: 200px;

                @media (min-width: @md-breakpoint) {
                    width: auto;
                }
            }
            
            > div {
                display: flex;
                padding: 1rem;
            }
        }
    }


    .block.related {
        position: relative;
        background: @light-grey;

        .page-title-main {
            z-index: 1;
            margin-bottom: 0;
            position: relative;
            margin-bottom: 3rem;
            text-transform: none;

            &:before {
                left: 0;
                top: 50%;
                right: 0;
                height: 1px;
                content: "";
                position: absolute;
                background-color: @mid-green;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
            }

            strong {
                margin: 0;
                border: none;
                color: #2CAA66;
                font-size: inherit;
                position: relative;
                padding: 15px 40px;
                text-transform: none;
                display: inline-block;
                background: @light-grey;
                font-size: 2rem !important;
            }
        }

        &:before,
        &:after {
            top: 0;
            bottom: 0;
            content: "";
            width: 100%;
            position: absolute;
            background: @light-grey;
        }

        &:before {
            left: -100%;
            background: @light-grey;
        }

        &:after {
            right: -100%;
            background: @light-grey;
        }

        .products-related {
            // Image
            .product-img-main {

                .product-item-inner {
                    display: none;
                }
            }

            .product-item-details {

                .product-item-name a {
                    font-weight: bold;
                    color: @dark-green;
                }

                .product-item-name {
                    height: 48px;
                    display: flex;
                    overflow: hidden;
                    justify-content: center;
                }

                .product-reviews-summary {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .reviews-actions {
                        margin: 0;
                        line-height: 1;
                        display: block !important;
                    }

                    .view {
                        color: @dark-grey;
                        font-weight: bold;
                    }

                    .add {
                        display: none;
                    }
                }
                
                .product-star-rating {
                    height: 26px;
                    margin-top: 1rem;
                    overflow: hidden;

                    .rating-summary .rating-result > span:before {
                        color: #FFCC00;

                        span {
                            display: inline;
                            color: @dark-grey;
                        }
                    }
                }

                .price-box {
                    height: 50px;
                    display: flex;
                    overflow: hidden;
                    margin: 10px 0 0 0;
                    align-items: center;
                    justify-content: center;

                    .price-label {
                        display: none;
                    }

                    .price {
                        font-size: 1.8rem;
                        color: @dark-green;   
                        
                        &:before {
                            color: @black;
                            display: block;
                            font-size: 1rem;
                            font-weight: normal;
                            content: "Prices from:";
                        }
                    }
                }

                .product-item-inner {
                    .product-item-actions {
                        padding: 0;
                        margin-top: 20px;

                        a {
                            border: 0;
                            color: @white;
                            font-weight: 800;
                            border-radius: 8px;
                            text-transform: none;
                            padding: 0.8rem 2rem;
                            background-color: @light-green;

                            &:hover {
                                background-color: @dark-green;
                            }
                        }
                    }
                }
            }
        }

    }

    .product.info.detailed {
        padding: 3rem 0;

        .rating-label {
            width: 80px;
            display: inline-block;
        }

        .rating-result {
            > span {
                &:before {
                    color: #FFCC00;
                }
            }
        }

        .review-control-vote label:before {
            color: #FFCC00;
        }

        .fieldset .review-legend.legend strong {
            color: @dark-green;
        }
    }

}

.table-wrapper.grouped {
    table {

        tr {
            @media (max-width: @md-breakpoint) {
                padding: 1rem;
                margin-bottom: 1rem;
                display: flex !important;
                border: 1px solid @mid-grey;
                justify-content: space-between;
            }

            &.product-oos {
                .amxnotif-block,
                .link-stock-alert {
                    display: none;
                }

                @media (max-width: @md-breakpoint) {

                    &.ready {
                        padding: 1rem;
                        margin-bottom: 0;
                        border-bottom: none;
                        display: flex !important;
                        justify-content: space-between;
                    }
                }
            }

            &.oos-row {

                @media (max-width: @md-breakpoint) {
                    padding: 0 .5rem;
                    border-top: none;
                    border-left: 1px solid @mid-grey;
                    border-right: 1px solid @mid-grey;
                    border-bottom: 1px solid @mid-grey;

                    td {
                        width: 100%;
                        padding-top: 0 !important;
                        padding-bottom: 0 !important;
                    }
                }

                td {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                }

                .amxnotif-block {
                    margin-bottom: 0;

                    label {
                        cursor: pointer;
                        font-size: 1rem;
                        color: @dark-green;
                        font-weight: normal;
                        margin-bottom: 0.7rem;

                        &:after {
                            content: "?";
                            margin-left: -3px;
                        }
                    }

                    .notification-container {
                        padding: 10px 10px;
                        border: 1px solid @light-grey;

                        @media (max-width: @md-breakpoint) {
                            border: none;
                            padding: 0 0 10px 0;
                        }

                        .input-fields {
                            width: 69%;
                            
                            @media (max-width: @md-breakpoint) {
                                width: 65%;
                            }
                            @media (max-width: 460px) {
                                width: 100%;
                            }
                        }
                        input {
                            width: 100%;
                            height: 39px;
                            border-radius: 5px;
                        }

                        .amxnotif_guest_action {
                            width: 30%; 

                            @media (max-width: 460px) {
                                width: 100%;
                                margin: 5px 0 0 0;
                            }

                            .primary {
                                width: 100%;
                            }

                            button {
                                width: 100%;
                                border-color: @dark-green;
                            }
                        }
                    }
                }

                .link-stock-alert {
                    margin: 0 0 7px 0;

                    a {
                        cursor: pointer;
                        font-size: 1rem;
                        color: @dark-green;
                        font-weight: normal;
                        margin-bottom: 0.7rem;
                    }
                }
            }
        }

        th.price,
        td.price {
            width: 75px;
            max-width: 75px;
            // font-size: 18px;
            font-weight: bold;
            color: @dark-green;

            @media (max-width: @md-breakpoint) {
                display: none !important;
            }

            .link-stock-alert {
                display: none;
            }

            .price-box {
                display: inline-block;
            }

            .special-price {
                margin: 0;
                padding: 0;
            }

            .product-salable {
                display: none;
            }

            .old-price {
                display: block;
                margin-top: -10px;

                .price {
                    font-size: 14px;
                    color: @light-red;
                    position: relative;

                    &:before {
                        top: 50%;
                        content: "";
                        height: 2px;
                        width: 100%;
                        display: block;
                        position: absolute;
                        background: @light-red;
                    }
                }
            }
        }

        td.item {
            display: flex;
            flex-direction: column;
            
            @media (max-width: @md-breakpoint) {
                margin: 0;
                padding: 0 !important;
                max-width: 60%;
            }

            strong {
                display: block;

                @media (max-width: @md-breakpoint) {
                    font-size: 18px;
                }
            }

            .product-item-name {
                
            }

            .priceper {
                display: block;
                font-size: 12px;
            }
        }

        td.qty {
            width: 120px;
            max-width: 110px;
            text-align: center;

            @media (max-width: @md-breakpoint) {
                max-width: none;
                display: flex !important;
                flex-direction: column;
                padding: 1rem !important;
                justify-content: flex-end;
            }

            .mobileprice {
                display: none;

                @media (max-width: @md-breakpoint) {
                    display: flex;
                    margin-bottom: 1rem;
                    justify-content: space-between;
                }

                .price-box {
                    display: inline-block;
                }

                .price {
                    color: @dark-green;
                    font-weight: bold;
                }

                .special-price {
                    margin: 0;
                    padding: 0;
                }

                .old-price {
                    display: block;
                    margin-top: -10px;

                    .price {
                        font-size: 14px;
                        color: @light-red;
                        position: relative;

                        &:before {
                            top: 50%;
                            content: "";
                            height: 2px;
                            width: 100%;
                            display: block;
                            position: absolute;
                            background: @light-red;
                        }
                    }
                }

                .product-salable {

                    .fa-check {
                        color: @light-green;
                    }
        
                    .fa-times {
                        color: @light-red;
                    }
                }
            }

        }

        th.salable,
        td.salable {
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: @md-breakpoint) {
                display: none !important;
            }

            .product-salable {
                display: flex;
                align-items: center;
                justify-content: center;
    
                .fa-check {
                    color: @light-green;
                }
    
                .fa-times {
                    color: @light-red;
                }
            }
        }



        thead {
            th {
                font-weight: bold;
                color: @dark-green;
                border-bottom: 2px solid @dark-green;

                &.qty {
                    text-align: center;
                }
            }

            @media (max-width: @md-breakpoint) {
                display: none;
            }
        }

        tbody {

            .item {
                display: flex;

                .product-item-name {
                    font-weight: bold;
                }
            }

            td {
                &:before {
                    display: none !important;
                }
            }
        }
    }
}

#maincontent {
    .product-add-form {
        .box-tocart {
            thead {
                th {
                    font-weight: bold;
                    color: @dark-green;
                    border-bottom: 2px solid @dark-green;

                    &:nth-child(n+2) {
                        text-align: center;
                    }
                }
            }
            tbody {
                tr {
                    td {
                        &:first-child {

                        }

                        &:nth-child(2) {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .price-box {
                            color: @dark-green;
                            display: inline-block;
                        }

                        .special-price {
                            margin: 0;
                            padding: 0;
                        }

                        .product-salable {
                            display: none;
                        }

                        .old-price {
                            margin-top: -10px;
                            display: inline-block;

                            .price {
                                font-size: 14px;
                                color: @light-red;
                                position: relative;

                                &:before {
                                    top: 50%;
                                    content: "";
                                    height: 2px;
                                    width: 100%;
                                    display: block;
                                    position: absolute;
                                    background: @light-red;
                                }
                            }
                        }

                        .product-salable {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                
                            .fa-check {
                                color: @light-green;
                            }
                
                            .fa-times {
                                color: @light-red;
                            }
                        }
                    }
                }
            }
        }
    }
}

.product-feeder-icons {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    
    // @media (min-width: @md-breakpoint) {
    //     justify-content: flex-start;
    // }

    > img {
        max-width: 50px;
        padding: 8px;

        @media (min-width: @md-breakpoint) {
            max-width: none;
        }
    }
}