html body {

    &.customer-account-create {

        .header.cta-bar {
            display: none;
        }

        .page-wrapper {
            min-height: 70vh;
            background-size: cover;
            background: rgb(35,120,187);
            background-position: top left;
            background-repeat: no-repeat;
            background: linear-gradient(180deg, rgba(35,120,187,1) 0%, rgba(125,214,234,1) 100%);
        }

        main {
            padding: 3rem 1.5rem 2rem 1.5rem;

            .page-title-wrapper {
                padding: 1rem 1rem 0 1rem;

                .page-title {
                    width: 100%;
                    color: @white;
                    font-size: 2.6rem;
                    margin-bottom: 0;

                    span {
                        text-transform: none; 
                    }
                }
            }

            .column {
                color: @white;
                display: flex;
                flex-wrap: wrap;

                > div {
                    padding: 1rem;
                    
                    @media (min-width: @md-breakpoint) {
                        max-width: 48%;
                    }

                    h2 {
                        margin-top: 0;
                        font-size: 2rem;
                    }

                    i {
                        color: @yellow;
                        font-size: 2.5rem;
                        margin-right: 1rem;
                    }

                    .signup-points {
                        margin-top: 3rem;

                        div {
                            display: flex;
                            align-items: center;
                            margin-bottom: 0.5rem;
                        }
                    }
                    
                }
                div.registration-footnote-wrapper {
                    width: 100%;
                    color: @black;
                    max-width: none;
                    margin-top: 4rem;
                    font-size: 0.9rem;
                }

            }

            .form-create-account {
                // display: flex;
                flex-wrap: wrap;
                width: 100% !important;

                fieldset {
                    width: 100%;
                    padding: 1rem;
                    margin-bottom: 1rem;
                    padding: 0 !important;

                    &:nth-child(1) {
                        background-color: red;
                    }

                    > .field {
                        width: 50%;
                        float: left;
                        margin: auto !important;
                        margin-bottom: 0.5rem !important;
                        
                        > div {
                            padding: 0 0.5rem;
                        }

                        label {
                            display: block;
                            margin-bottom: 10px;

                            &:after {
                                content: '*';
                                font-size: 1.2rem;
                                margin: 0 0 0 4.5px;
                                color: @black !important;
                            }
                        }

                        input {
                            height: auto;
                            padding: 0.5rem;
                            font-size: 16px;
                            border-radius: 8px;
                            padding: 0.8rem 1.2rem;
                            border: 2px solid transparent;

                            &:focus,
                            &:active {
                                outline-offset: 3px;
                                outline-width: 1px;
                                outline-color: black;
                                outline-style: dashed;
                            }

                            &.mage-error {
                                border: 2px solid red;
                            }
                        }

                        &.field-name-firstname,
                        &.field-name-lastname {
                            label {
                                padding: 0 0.5rem;
                            }
                        }

                        &.newsletter {
                            display: none;
                            padding-left: 0.6rem;

                            label {
                                &:after {
                                    content: '';
                                    display: none !important;
                                }
                            }
                        }

                    }
                    
                    &.create.account {
                        .field.required:not(.password):not(.confirmation) {
                            width: 100% !important;
                        }
                        // .password, .confirmation {
                        //     width: 40%;
                        // }
                    }

                    br {
                        display: none;
                    }

                    legend {
                        display: none;
                    }

                    .field {
                        margin: 0;
                    }

                    #password-strength-meter-container .password-strength-meter {
                        color: black;
                        font-size: 1rem;
                        margin-top: 0.5rem;
                        border-radius: 8px;
                        text-align: center;
                        border-radius: 0.8rem;
                    }
                }

                .actions-toolbar {
                    margin-top: 2rem;
                    
                    div {
                        display: flex;
                        flex-direction: column-reverse;
                        justify-content: space-between;

                        @media (min-width: @md-breakpoint) {
                            flex-direction: row;
                        }
                    }

                    button {
                        border-radius: 0;
                        padding: 2rem 1rem;
                        margin-bottom: .5rem !important;
                        border: 4px solid black;

                        &:first-child {
                            background: transparent;
                            border-color: @light-green;

                            &:hover {
                                background: @black;
                                border-color: @black;
                            }
                        }
                        
                        &:last-child {
                            color: @black;
                            background: @light-green;
                            border-color: @light-green;

                            &:hover {
                                color: @white;
                                background: @black;
                                border-color: @black;
                            }
                        }

                        span {
                            display: block;

                            &:first-child {
                                font-size: 1.6rem;
                                margin-top: 0.5rem;
                            }
                            
                            &:last-child {
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}