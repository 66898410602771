.boxes {
    margin: 0 0 6rem 0;

    .title {
        font-size: 2rem;
        color: @mid-green;
        position: relative;
        text-align: center;
        margin-bottom: 2rem;

        &:before {
            left: 0;
            top: 50%;
            right: 0;
            height: 1px;
            content: "";
            position: absolute;
            background-color: @mid-green;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
        }

        strong {
            margin: 0;
            font-size: inherit;
            position: relative;
            padding: 15px 40px;
            display: inline-block;
            background-color: white;
        }
    }

    .items {
        margin-left: -1rem;
        margin-right: -1rem;

        clear: both;

        &:before,
        &:after {
            content: '';
            display: table;
        }

        &:after {
            clear: both;
        }

        * {
            box-sizing: border-box;
        }

        .info-block-widget {
            float: left;
            padding: 1rem;
            
            > div {
                padding: 2rem;
                color: @white;
                overflow: hidden;
                min-height: 245px;
                position: relative;
                padding-bottom: 5.5rem;
                
                @media (min-width: 440px) {
                    padding-top: 8rem;
                }

                @media (min-width: 550px) {
                    padding-top: 2rem;
                }

                .ibw-title,
                .ibw-subtitle,
                .ibw-infoline,
                .ibw-subtext {
                    display: block;
                    // max-width: 100%;
                    margin-bottom: 0.4rem;

                    @media (min-width: 550px) {
                        max-width: 60%;
                    }
                }

                .ibw-title {
                    font-weight: bold;
                    font-size: 2.3rem;
                    margin-bottom: 1rem;
                    
                    @media (min-width: 440px) {
                        max-width: 62%;
                    }   
                }
        
                .ibw-subtitle {
                    font-weight: bold;
                    font-size: 1.4rem;
                    
                    @media (min-width: 440px) {
                        max-width: 70%;
                    } 
                }
        
                .ibw-infoline {
                }
        
                .ibw-subtext {
        
                }

                a:not(.button) {
                    color: white;
                    font-size: 1rem;
                    max-width: 100px;
                }
        
                a.button {
                    left: 2rem;
                    bottom: 2rem;
                    color: @white;
                    margin-top: 1rem;
                    font-weight: bold;
                    font-size: 1.2rem;
                    border-radius: 8px;
                    background: @black;
                    position: absolute;
                    padding: 0.8rem 2rem;
                    display: inline-block;

                    &:hover {
                        background-color: lighten(@black, 20%);
                    }
                }
        
                img {
                    top: .5rem;
                    right: .5rem;
                    display: none;
                    max-width: 150px;
                    position: absolute;
                    transform: translateY(0%);
                    
                    @media (min-width: 440px) {
                        top: 1.5rem;
                        right: 1.5rem;
                        display: block;
                    }

                    @media (min-width: 550px) {
                        top: 50%;
                        bottom: 2rem;
                        transform: translateY(-50%);
                    }
                }
            }

            &.btn-red {
                a.button {
                    background-color: @red;
                    
                    &:hover {
                        background-color: darken(@red, 10%);
                    }
                }
            }
    
            &.background-image {
    
                > div {
                    background-size: cover;
                    background-position: center center;
                }
    
                img {
    
                }
            }
        }

    }

    &.homepage-one {

        .info-block-widget {
            width: 100%;
            
            @media (min-width: @md-breakpoint) {
                width: 33.333336%;
            }

            &:nth-child(4) {
                width: 100%;

                > div {
                    padding: 0;
                    display: flex;
                    align-items: flex-end;
                    flex-direction: column;
                    justify-content: center;

                    @media (min-width: @md-breakpoint) { 
                            padding: 2rem;
                    }
                }

                .content {
                    width: 100%;
                    display: flex;
                    min-height: 260px;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    background-color: rgba(0,0,0,0.7);
                    
                    @media (min-width: @md-breakpoint) {
                        width: 50%;
                        min-height: 340px;
                        background-color: transparent;
                    }
                    
                    .ibw-title,
                    .ibw-subtitle,
                    .ibw-infoline,
                    .ibw-subtext {
                        width: 100%;
                        max-width: 80%;
                        text-align: center;
                    } 
                    
                    .ibw-title {
                        font-size: 2rem;
                        
                        @media (min-width: @md-breakpoint) { 
                            font-size: 3.5rem;
                        }
                    }

                    .ibw-subtitle,
                    .ibw-infoline {
                        font-size: 1.5rem;

                        @media (min-width: @md-breakpoint) { 
                            font-size: 2rem;
                        }
                    }

                    a.button {
                        left: auto;
                        right: auto;
                        bottom: auto;
                        position: relative;
                    }
                }

                img {
                    display: none;
                }
            }
        }
    }

    &.homepage-two {

        .info-block-widget {
            width: 100%;
            
            @media (min-width: @md-breakpoint) {
                width: 50%;
            }

            .ibw-subtitle {
                font-size: 1rem;
            }

            img {
                top: 50%;
                max-width: 150px;
                max-height: 150px;
                position: absolute;
                transform: translateY(-50%);
            }
        }
    }
}