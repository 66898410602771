.testimonials-slider {
    margin: 6rem 0;

    .main {

        .title {
            font-size: 2rem;
            position: relative;
            text-align: center;
            color: @mid-green;

            &:before {
                left: 0;
                top: 50%;
                right: 0;
                height: 1px;
                content: "";
                position: absolute;
                background-color: @mid-green;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
            }
    
            strong {
                margin: 0;
                font-weight: 600;
                font-size: inherit;
                position: relative;
                padding: 15px 40px;
                display: inline-block;
                background-color: white;
            }
        }

        .icon {
            text-align: center;
            margin-bottom: 2rem;
            
            img {
                display: block;
                margin: 0 auto;
                max-width: 150px;
                max-height: 150px;
            }
        }

        .owl-carousel {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        .slide {
            padding: 2rem;

            .slide-content {
                margin: 0 auto;
                max-width: 720px;
                position: relative;
                text-align: center;

                p {
                    line-height: 2;

                    &:before {
                        content: "\"";
                        line-height: 0;
                        font-size: 2rem;
                        margin-top: -6px;
                        font-style: italic;
                        margin-right: 5px;
                        font-family: 'Open Sans';
                    }
                    &:after {
                        line-height: 0;
                        content: "\"";
                        font-size: 2rem;
                        margin-top: -6px;
                        font-style: italic;
                        font-family: 'Open Sans';
                    }
                }

            }
        }
    }

}