

// Homepage
.home-slider-wrapper {
    margin-top: 1rem;

    .owl-prev, 
    .owl-next {
        opacity: 1;
        height: 32px;
        display: none;
        align-items: center;
        margin: 0 !important;
        justify-content: center;
        transform: translateY(-50%);
        background: @white !important;

        .left-arrow, 
        .right-arrow {
            color: @black;
        }
    }
    
    &:hover {
        .owl-prev,
        .owl-next {
            display: flex;
        }
    }
}