.attribute_details_buttons a {
    width: 150px;
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;
    color: @dark-grey;
    padding: 10px 30px;
    text-transform: uppercase;
    text-transform: lowercase;
    border: 1px solid @mid-grey;

}
.attribute_details_buttons a:hover {
    color: @light-green;
}
