.block-search {
    max-width: 350px;
}
.block-search > .block-title { display: none; }
.block-search > .block-content form input {
    width: 100%;
    z-index: 10;
    height: 38px;
    font-size: 12px;
    border-radius: 0;
    color: #9d9fa6;
    background: transparent;
    outline: none !important;
    padding: 8px 70px 8px 18px;
}
.block-search > .block-content form input:focus {
    box-shadow: none;
}
.block-search > .block-content div.actions button {
    width: 52px;
    z-index: 20;
    height: 38px;
    border-radius: 0;
    background: @dark-green;
}
.block-search > .block-content div.actions button:hover {
    background: @light-green;
}
.block-search > .block-content div.actions button:disabled {
    background: @dark-green;
}
.block-search > .block-content div.actions button:disabled:hover {
    background: @light-green;
}
.block-search > .block-content div.actions button:before {
    content: 'Go';
    color: @white;
    font-size: 12px;
    font-weight: bold;
    font-family: inherit;
}
.block-search > .block-content div.actions button:hover:before {
    color: @white;
}
