
.account {

    main {
        margin-top: 2rem;

        a,
        .block-title a {
            color: @dark-green;

            &:hover {
                color: @black;
            }
        }
    
        .page-title-wrapper {
            padding: 1rem;
            margin-bottom: 1rem;
            background-color: @light-green;
    
            .page-title {
                color: @white;
                font-size: 2rem;
                margin-bottom: 0;
            }
        }

        .account-nav-title {
            display: none;
        }

        .account-nav-content {
            background: @light-grey;

            ul {
                li {
                    a {
                        color: @black;

                        &:hover {
                            color: @dark-green;
                        }
                    }

                    span.delimiter {
                        margin-top: 0.5rem;
                        margin-bottom: 0.5rem;
                        border-top: 1px solid @mid-grey;
                    }

                    &.current {
                        background: darken(@light-grey, 5%);

                        strong {
                            color: @dark-green;
                            border-color: @dark-green;
                        }
                    }
                }
            }
        }

        .sidebar.sidebar-additional {
            margin-top: 0;
            margin-bottom: 4rem;

            .block-compare {
                display: none;
            }
        }

        .main {
            margin-top: 1rem;

            .block-title {
                padding: 0 1.5rem;
                color: @dark-green;
                margin-bottom: 5px !important;
            }

            .block-content {
                display: flex;
            }

            .box {
                margin: 5px;
                padding: 1rem;
                background: @light-grey;

                .action {
                    font-size: 0.9rem;
                }
            }
        }

        button.primary {
            border: 0;
            font-size: 1.6rem;
            padding: 0.8rem 1rem;
            // text-transform: none;
            background-color: @light-green;

            &:hover {
                background-color: @dark-green;
            }
        }

        .orders-recent {
            width: 100%;
            padding-left: 1rem;
            padding-right: 1rem;

            a {
                &:hover {
                    color: @black;
                }
            }
        }

        .form-newsletter-manage {
            padding: 0 !important;

            .legend {
                padding: 1rem;
                margin-bottom: 2rem;
                background-color: #92bf1f;

                > span {
                    color: white;
                    font-size: 2rem;
                    margin-bottom: 0;
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }

            .choice {
                input {
                    margin-right: 1rem;
                }
            }

            .actions-toolbar {
                .primary {
                    button {
                        width: 100% !important;
                        font-size: 1.2rem !important;
                        border-radius: 8px !important;
                        border-color: @dark-green !important;

                        &:hover {
                            background-color: @black !important;
                        }
                    }
                }
            }
        }
    }
}