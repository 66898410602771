html body {

    &.customer-account-login,
    &.multishipping-checkout-login {

        .page-wrapper {
            min-height: 70vh;
        }

        main {
            padding: 3rem 1.5rem 2rem 1.5rem;

            .page-title-wrapper {
                padding: 1rem;
                margin-bottom: 2rem;
                background-color: @light-green;

                .page-title {
                    width: 100%;
                    color: @white;
                    font-size: 2rem;
                    margin-bottom: 0;
                    text-align: center;

                    span {
                        text-transform: none;
                    }
                }
            }
        }

        .columns {
            .column {
                .login-container {
                    display: flex;
                    flex-direction: column;

                    @media (min-width: @md-breakpoint) {
                        flex-direction: row-reverse;
                    }

                    .block {
                        padding: 2rem;
                        height: 420px;
                        background-color: @light-grey;

                        &.block-customer-login {
                            @media (min-width: @md-breakpoint) {
                                margin-left: 1rem;
                            }
                        }

                        &.block-new-customer {
                            @media (min-width: @md-breakpoint) {
                                margin-right: 1rem;
                            }
                        }

                        .block-title {
                            font-weight: bold;

                            strong {
                                font-weight: inherit;
                            }
                        }

                        .actions-toolbar {

                            .primary a {
                                border: 0;
                                font-size: 1.6rem;
                                padding: 0.8rem 1rem;
                                // text-transform: none;
                                background-color: @light-green;

                                &:hover {
                                    background-color: @dark-green;
                                }
                            }
                        }
                    }
                }

                .block-customer-login {
                    .block-content {

                        .field {
                            label {

                            }

                            .control {
                                width: 100%;
                            } 
                        }

                        .actions-toolbar {

                            button {
                                border: 0;
                                font-size: 1.6rem;
                                padding: 0.8rem 1rem;
                                // text-transform: none;
                                background-color: @light-green;

                                &:hover {
                                    background-color: @dark-green;
                                }
                            }

                            a {
                                color: @dark-green;

                                &:hover {
                                    color: @black;
                                }
                            }
                        }
                    }

                    .fieldset:after {
                        margin-top: 15px;
                        font-size: 0.8rem;
                    }
                }
            }
        }

    }
}