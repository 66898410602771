html body {

    &.catalog-category-view {

        main {

            .category-view {
                margin-bottom: 2rem;
            }

            .category-introduction {
                display: flex;
                // flex-wrap: wrap;
                padding: 0.5rem 1rem;
                margin-bottom: 1rem;
                align-items: flex-start;
                flex-direction: column;
                justify-content: flex-start;
                
                @media (min-width: @sm-breakpoint) {
                    padding: 0.5rem 0;
                }

                .page-title-wrapper {
                    flex-shrink: 0;
                    margin-right: 2rem;
                    margin-bottom: 1rem;

                    @media (min-width: @md-breakpoint) {
                        margin-bottom: 0;
                    }
    
                    .page-title {
                        font-size: 2rem;
                        font-weight: normal;
                        color: @light-green;
                        margin-bottom: 0.5rem;
                        text-transform: capitalize;
                    }
    
                }

                .category-description {
                    font-size: 1rem;
                    color: @dark-grey;
                    margin-bottom: 0;

                    @media (min-width: @md-breakpoint) {
                        font-size: 0.9rem;
                    }

                    p {
                        line-height: 1.2;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                    
                    a {
                        color: inherit !important;
                    }
                }
            }

            .modes {
                display: none;
            }
            
        }
    }
}