
.contact-index-index {

    main {
        margin-top: 2rem;
        margin-bottom: 2rem;

        .page-title-wrapper {
            padding: 1rem;
            margin-bottom: 1rem;
            background-color: @light-green;

            .page-title {
                color: @white;
                font-size: 2rem;
                margin-bottom: 0;
            }
        }

        .columns {
            .column.main {
                display: flex;
                align-items: flex-start;
                flex-direction: column-reverse;
                
                @media (min-width: 1081px) {
                    flex-direction: row-reverse;
                }

                > div {
                    padding: 0 3rem 0 0;
                }
            }
        }

        fieldset {
            .legend {
                margin-bottom: 0;
                font-weight: bold;
                color: @dark-green;
            }
        }

        input,
        textarea {
            height: auto;
            padding: 0.5rem;
            font-size: 16px;
            border-radius: 8px;
            background: #EFEFEF;
            padding: 0.8rem 1.2rem;
            border: 2px solid transparent;

            &:focus,
            &:active {
                outline-offset: 3px;
                outline-width: 1px;
                outline-color: black;
                outline-style: dashed;
            }
        }

        button {
            border: 0;
            border-radius: 0;
            border-radius: 0;
            font-size: 1.4rem;
            font-weight: normal;
            text-transform: none;
            padding: 1rem 6rem !important;
            background: #067C58 !important;
            margin-bottom: .5rem !important;

            &:hover {
                background: @black !important;
            }
            
            span {
                display: block;
            }
        }
    }
}
