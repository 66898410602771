.breadcrumbs {
    display: none;
    font-size: 1rem;
    margin-bottom: 15px;
    padding: 1.1rem 1rem 0 1.2rem;

    @media (min-width: @md-breakpoint) {
        display: block;
    }

    ul.items {

        li.item {

            a {
                color: @mid-grey;
                text-decoration: none;

                &:after {
                    line-height: 22px;
                }

                &:hover {
                    color: @light-green; 
                }
            }

            &.home {

            }

            &.product {

            }

            &:last-child {
                strong {
                    color: @mid-grey;
                }
            }
        }
    }
}