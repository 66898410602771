&.cms-faqs {

    main {
        // margin-top: 2rem;
        margin-bottom: 2rem;

        a {
            color: @dark-green !important;
        }

        h2 {
            color: @white;
            padding: 1rem;
            font-size: 2rem;
            margin-bottom: 2rem;
            background-color: @light-green;
        }

        ul {
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            align-items: center;
            justify-content: flex-start;

            li {
                padding: 8px;
                margin: 0 !important;

                a {
                    font-size: 1.1rem;
                    color: @dark-grey;
                    font-weight: bold;
                    margin-left: -15px;
                    border-radius: 10px;
                    display: inline-block;
                    background: @light-grey;
                    padding: 0.8rem 1.4rem;

                    &:hover {
                        color: @white !important;
                        background: @dark-green;
                    }
                }
            }
        }

        details {
            padding: 0 1rem;

            summary {
                margin-bottom: 1rem;
            } 
        }

        .button {
            font-weight: bold;
            border-radius: 10px;
            display: inline-block;
            padding: 0.8rem 1.4rem;
            background: @light-green;
            text-transform: uppercase;
            color: white !important;
            
            &:hover {
                background: @dark-green;

            }
        }
    }
}