html {
  font-size: 12px;
  overflow-x: hidden;
}
html.ie11 {
  height: auto !important;
}
html.ie11 body {
  height: auto !important;
}
html.ie11 .page-wrapper {
  height: auto !important;
}
html body#body {
  overflow-x: hidden;
}
html body#body.cms-home .page-main .page-main {
  padding-left: 0;
  padding-right: 0;
}
html body#body header.page-header > .panel.wrapper {
  background: transparent;
}
html body#body header {
  background: white;
}
html body#body header .panel.wrapper {
  display: none;
}
html body#body header .panel.header {
  padding: 0;
}
html body#body header .header.content {
  display: flex;
  padding: 0.7rem 1rem;
  align-items: center;
}
@media (min-width: 939px) {
  html body#body header .header.content {
    padding: 2rem 1.5rem 2rem 1.5rem;
  }
}
html body#body header .header.content:before,
html body#body header .header.content:after {
  display: none;
}
@media (min-width: 1020px) {
  html body#body header .header.content {
    flex-direction: row;
  }
}
html body#body header .header.content .logo {
  margin: 0;
}
html body#body header .header.content .logo a.logo {
  margin: 0;
  max-width: none;
}
@media (min-width: 939px) {
  html body#body header .header.content .logo a.logo {
    margin: 0 0 0 1rem;
  }
}
html body#body header .header.content .logo a.logo > img {
  width: auto;
  height: auto;
  max-height: 60px;
  max-width: 230px;
}
@media (min-width: 939px) {
  html body#body header .header.content .logo a.logo > img {
    max-height: 60px;
    max-width: 460px;
  }
}
html body#body header .header.content .form.minisearch {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 767px) {
  html body#body header .header.content .header.controls {
    display: none;
  }
}
html body#body header .header.content .search {
  width: 100%;
}
@media (max-width: 1200px) {
  html body#body header .header.content .search {
    display: block;
  }
}
@media (min-width: 800px) {
  html body#body header .header.content .search {
    max-width: 260px;
  }
}
@media (min-width: 1024px) {
  html body#body header .header.content .search {
    max-width: 340px;
  }
}
@media (min-width: 1060px) {
  html body#body header .header.content .search {
    max-width: none;
  }
}
html body#body header .header.content .search .block-search {
  padding: 0;
  max-width: 100%;
  position: relative;
}
html body#body header .header.content .search .block-search:before {
  top: 50%;
  left: 10px;
  opacity: 0.7;
  z-index: 9000;
  display: block;
  color: #46484a;
  content: '\f002';
  position: absolute;
  font-family: "Fontawesome";
  transform: translateY(-50%);
}
html body#body header .header.content .search .block-search form.form.minisearch input {
  border: 0px;
  color: #000000;
  padding-left: 40px;
  padding-right: 80px;
  background: #EFEFEF;
  border-radius: 10px;
}
html body#body header .header.content .search .block-search form.form.minisearch input:focus,
html body#body header .header.content .search .block-search form.form.minisearch input:active {
  border-radius: 5px;
  outline-width: 1px !important;
  outline-offset: 3px !important;
  outline-style: dashed !important;
  outline-color: #808080 !important;
}
html body#body header .header.content .search .block-search form.form.minisearch div.actions {
  display: none !important;
}
@media (min-width: 939px) {
  html body#body header .header.content .search .block-search form.form.minisearch div.actions {
    display: block !important;
  }
}
html body#body header .header.content .search .block-search form.form.minisearch div.actions button {
  width: 75px  !important;
  background: #92bf1f;
  border-radius: 0 10px 10px 0;
}
html body#body header .header.content .search .block-search form.form.minisearch div.actions button:hover {
  background: #067C58;
}
html body#body header .header.content .search .block-search form.form.minisearch div.actions button:before {
  display: none;
}
html body#body header .header.content .search .block-search form.form.minisearch div.actions button span {
  top: 50%;
  left: 50%;
  margin: 0;
  clip: auto;
  width: auto;
  height: auto;
  color: white;
  font-weight: bold;
  overflow: visible;
  transform: translate(-50%, -50%);
}
html body#body header .header.content .search .block-search form.form.minisearch .search-autocomplete {
  padding: 10px;
  margin-top: 1rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 3px 5px rgba(0, 0, 0, 0.1);
}
html body#body header .header.content .search .block-search form.form.minisearch .search-autocomplete ul li:not(:empty) {
  border: 0;
  line-height: 1;
  padding-top: 8px;
  margin-bottom: 5px;
  border-radius: 10px;
  padding-bottom: 8px;
  background: #f3f3f3;
}
html body#body header .header.content .search .block-search form.form.minisearch .search-autocomplete ul li:not(:empty) span.amount {
  top: 10px;
  font-size: 12px;
}
html body#body header .header.content .search .block-search form.form.minisearch .search-autocomplete ul li:not(:empty):hover {
  background: #e6e6e6;
}
html body#body header .header.content .search .block-search form.form.minisearch .search-autocomplete ul li:not(:empty).selected {
  background: rgba(44, 170, 102, 0.2);
}
html body#body header .header.content .controls {
  float: right;
}
@media (max-width: 1140px) {
  html body#body header .header.content .controls .header.links:before {
    display: none;
  }
}
html body#body header .header.content .controls .header.links:before {
  display: none;
}
html body#body header .header.content .controls .header.links > li {
  display: none;
}
html body#body header .header.content .controls .header.links a {
  white-space: nowrap;
}
@media (min-width: 800px) {
  html body#body header .header.content .controls .header.links:before {
    display: none;
  }
  html body#body header .header.content .controls .header.links > li {
    display: block;
  }
}
@media (min-width: 1125px) {
  html body#body header .header.content .controls .header.links:before {
    display: block;
  }
  html body#body header .header.content .controls .header.links > li {
    display: block;
  }
}
@media (min-width: 939px) {
  html body#body header .header.content .controls {
    display: block;
  }
}
html body#body header .header.content .controls ul.links {
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
  margin: 0;
  justify-content: flex-end;
}
html body#body header .header.content .controls ul.links:before {
  content: "\f007";
  font-family: "Fontawesome";
  width: 42px;
  height: 42px;
  padding: 5px;
  color: white;
  font-size: 24px;
  line-height: 1.8;
  border-radius: 50%;
  text-align: center;
  background: #92bf1f;
}
html body#body header .header.content .controls ul.links li {
  margin: 0;
  position: relative;
}
html body#body header .header.content .controls ul.links li:first-child:after {
  top: 50%;
  right: -3px;
  content: "/";
  color: #000000;
  display: block;
  font-weight: bold;
  position: absolute;
  transform: translateY(-50%);
}
html body#body header .header.content .controls ul.links li a,
html body#body header .header.content .controls ul.links li span {
  margin: 0 8px;
  display: block;
  transition: none;
  font-weight: bold;
  color: #46484a;
  font-size: 1.3rem;
  transition: all 0.3s ease-in-out;
}
html body#body header .header.content .controls ul.links li a:hover,
html body#body header .header.content .controls ul.links li span:hover {
  color: #067C58;
}
@media (max-width: 470px) {
  html body#body header .header.content {
    padding: 0;
    flex-direction: column;
  }
  html body#body header .header.content .ammenu-menu-toggle {
    top: 74px;
    left: 11px;
    position: absolute;
  }
  html body#body header .header.content .ammenu-menu-toggle.-active {
    top: 10px;
    left: 11px;
  }
  html body#body header .header.content .ammenu-menu-toggle .ammenu-icon {
    border-color: white !important;
  }
  html body#body header .header.content .header.logo {
    height: 66px;
    max-width: none;
  }
  html body#body header .header.content .header.logo .my-flex {
    width: 100vw;
    display: flex;
    padding: 10px;
    justify-content: center;
  }
  html body#body header .header.content .header.controls {
    padding-right: 20px;
  }
  html body#body header .header.content .header.controls .action.showcart:before {
    width: 37px;
    height: 37px;
    background: transparent;
  }
  html body#body header .header.content .header.search-controls {
    width: 100%;
    padding-right: 20px;
    background: #067C58;
  }
  html body#body header .header.content .header.search-controls .label:before {
    color: white;
  }
}
html body#body header .header.logo {
  width: auto;
}
html body#body header .search-controls {
  width: auto;
  float: right;
  display: flex;
  margin-left: auto;
  align-items: center;
}
html body#body header .search-controls .header.search {
  margin-right: 1rem;
}
@media (min-width: 939px) {
  html body#body header .search-controls .header.search {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
@media (min-width: 1200px) {
  html body#body header .search-controls .header.search {
    margin-right: 5rem;
  }
}
html body#body header .search-controls .minicart-wrapper {
  margin-right: 0 !important;
}
html body#body .header-actions {
  width: 100%;
  display: none;
}
@media (min-width: 1020px) {
  html body#body .header-actions {
    display: block;
  }
}
html body#body .header-actions .contain {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  max-width: 1280px;
  margin-right: auto;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1020px) {
  html body#body .header-actions .contain {
    flex-wrap: nowrap;
  }
}
html body#body .header-actions .item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media (min-width: 1020px) {
  html body#body .header-actions .item {
    flex: 1;
    width: auto;
  }
}
html body#body .header-actions .item a,
html body#body .header-actions .item div {
  width: 100%;
  padding: 1rem 1.2rem 0 1.2rem;
}
html body#body .header-actions .item a {
  display: block;
}
html body#body .header-actions .item img {
  display: block;
  max-height: 70px;
  margin: 0 auto 0 auto;
}
html body#body .header-actions .item:first-child a img {
  margin: 0 auto 0 0;
}
html body#body .header-actions .item:last-child a img {
  margin: 0 0 0 auto;
}
html body#body .ammenu-menu-toggle {
  padding: 10px;
  z-index: 1000000;
  margin-top: -5px;
  position: relative;
}
html body#body .ammenu-menu-toggle.-active .ammenu-icon {
  border-color: white !important;
}
html body#body .ammenu-menu-toggle.-active .ammenu-icon:nth-of-type(3) {
  transform: translateY(-10px) rotate(-45deg);
}
html body#body .ammenu-menu-toggle .ammenu-icon {
  border-bottom: 3px solid;
}
html body#body .ammenu-nav-sections.nav-sections {
  max-width: none;
}
html body#body .ammenu-nav-sections .ammenu-title {
  color: white;
}
html body#body .ammenu-nav-sections .ammenu-title.active {
  color: #067C58;
}
html body#body .ammenu-nav-sections .ammenu-menu-wrapper.-desktop .ammenu-items {
  justify-content: space-between;
}
html body#body .ammenu-nav-sections .ammenu-menu-wrapper.-desktop .ammenu-items .ammenu-submenu-container {
  margin-top: 11px !important;
}
html body#body .ammenu-nav-sections .ammenu-menu-wrapper.-desktop nav.ammenu-main-container > .ammenu-items > .ammenu-item > a {
  font-size: 18px;
  color: white !important;
}
html body#body .ammenu-nav-sections .ammenu-menu-wrapper.-desktop nav.ammenu-main-container > .ammenu-items > .ammenu-item:hover > a {
  color: #067C58 !important;
}
html body#body .ammenu-nav-sections .ammenu-menu-wrapper.-desktop nav.ammenu-main-container > .ammenu-items > .ammenu-item:last-child {
  background: #EC615E !important;
}
html body#body .ammenu-nav-sections .ammenu-menu-wrapper.-desktop nav.ammenu-main-container > .ammenu-items > .ammenu-item:last-child:hover > a {
  color: white !important;
}
html body#body .ammenu-nav-sections .ammenu-menu-wrapper.-desktop nav.ammenu-main-container > .ammenu-items > .ammenu-item.-main {
  padding: 11px 15px;
}
html body#body .ammenu-nav-sections .ammenu-menu-wrapper.-desktop nav.ammenu-main-container > .ammenu-items .ammenu-link.-level1.current {
  color: #92bf1f !important;
}
html body#body .ammenu-nav-sections .ammenu-menu-wrapper.-mobile nav.ammenu-main-container > ul.ammenu-items > li.ammenu-item > a:before {
  display: none;
}
html body#body .ammenu-nav-sections .ammenu-menu-wrapper.-mobile nav.ammenu-main-container > ul.ammenu-items > li.ammenu-item:last-child > a {
  color: #EC615E !important;
}
html body#body .ammenu-nav-sections .ammenu-menu-wrapper.-mobile nav.ammenu-main-container > ul.ammenu-items > li.ammenu-item:last-child .ammenu-icon:before,
html body#body .ammenu-nav-sections .ammenu-menu-wrapper.-mobile nav.ammenu-main-container > ul.ammenu-items > li.ammenu-item:last-child .ammenu-icon:after {
  background: #EC615E !important;
}
html body#body .ammenu-nav-sections .ammenu-menu-wrapper.-mobile nav.ammenu-main-container > ul.ammenu-items > li.ammenu-item.-drill-opened:last-child .ammenu-icon {
  background: #EC615E !important;
}
html body#body .ammenu-nav-sections.-opened .ammenu-tabs-container .ammenu-title {
  height: 60px;
  z-index: 9000000;
  position: relative;
}
html body#body .ammenu-nav-sections.-opened .ammenu-tabs-container .ammenu-title > a {
  font-weight: bold;
  color: white !important;
}
html body#body .ammenu-nav-sections.-opened .ammenu-tabs-container .ammenu-title.active > a {
  color: #067C58 !important;
}
html body#body .ammenu-nav-sections.-opened .ammenu-tabs-container .ammenu-title:first-child {
  margin-left: 70px;
}
html body#body .ammenu-nav-sections.-opened .ammenu-tabs-container > .ammenu-content {
  margin-top: 60px;
  height: calc(100% - 60px);
}
html body#body .minicart-wrapper {
  display: flex;
  align-items: center;
  margin: 0 0 0 1rem;
  justify-content: flex-end;
}
html body#body .minicart-wrapper > a {
  width: 100%;
  display: flex;
  align-items: center;
}
html body#body .minicart-wrapper > a:before {
  width: 42px;
  height: 42px;
  padding: 5px;
  color: white;
  font-size: 24px;
  content: '\f07a';
  border-radius: 50%;
  background-color: transparent;
}
@media (min-width: 471px) {
  html body#body .minicart-wrapper > a:before {
    background: #92bf1f;
  }
}
html body#body .minicart-wrapper > a:hover .text {
  color: #067C58;
}
html body#body .minicart-wrapper > a .text {
  line-height: 1;
  margin-left: 8px;
  font-weight: bold;
  color: #46484a;
  font-family: inherit;
  text-transform: none;
  display: inline-block;
  letter-spacing: normal;
  transition: all 0.3s ease-in-out;
}
html body#body .minicart-wrapper > a .showcart {
  background: red;
}
html body#body .minicart-wrapper > a .counter {
  width: 60px;
  height: 55px;
  z-index: 50000;
  position: absolute;
}
html body#body .minicart-wrapper > a .counter .counter-number {
  top: 0;
  right: 0;
  width: 15px;
  height: 14px;
  padding: 3px;
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  position: absolute;
  border-radius: 50%;
  color: #067C58;
  background: white;
  border: 1px solid #067C58;
}
html body#body .minicart-wrapper .stripe-payments-express-minicart {
  display: none;
}
html body#body .minicart-wrapper .block-minicart {
  border: 0;
  margin-top: 1rem;
  background: white;
  border-radius: 10px;
  padding: 35px 10px 10px 10px;
  box-shadow: 0px 0px 3px 5px rgba(0, 0, 0, 0.1);
}
html body#body .minicart-wrapper .block-minicart:after {
  border-color: transparent transparent #ffffff transparent;
}
html body#body .minicart-wrapper .block-minicart .subtitle.empty {
  padding: 1rem;
  border-radius: 10px;
  background: #f9d1d0;
}
html body#body .minicart-wrapper .block-minicart .block-content > .action {
  display: none;
}
html body#body .ammenu-content .header.links li {
  text-transform: uppercase;
  border-bottom: 1px solid #c4c4c4 !important;
}
html body#body .ammenu-content .header.links li a {
  font-weight: bold;
}
html body#body .block-search {
  max-width: 350px;
}
html body#body .block-search > .block-title {
  display: none;
}
html body#body .block-search > .block-content form input {
  width: 100%;
  z-index: 10;
  height: 38px;
  font-size: 12px;
  border-radius: 0;
  color: #9d9fa6;
  background: transparent;
  outline: none !important;
  padding: 8px 70px 8px 18px;
}
html body#body .block-search > .block-content form input:focus {
  box-shadow: none;
}
html body#body .block-search > .block-content div.actions button {
  width: 52px;
  z-index: 20;
  height: 38px;
  border-radius: 0;
  background: #067C58;
}
html body#body .block-search > .block-content div.actions button:hover {
  background: #92bf1f;
}
html body#body .block-search > .block-content div.actions button:disabled {
  background: #067C58;
}
html body#body .block-search > .block-content div.actions button:disabled:hover {
  background: #92bf1f;
}
html body#body .block-search > .block-content div.actions button:before {
  content: 'Go';
  color: white;
  font-size: 12px;
  font-weight: bold;
  font-family: inherit;
}
html body#body .block-search > .block-content div.actions button:hover:before {
  color: white;
}
html body#body .nav-sections {
  margin-bottom: 0;
  background-color: #067C58;
}
html body#body .nav-sections nav.navigation {
  background: transparent;
}
html body#body .nav-sections nav.navigation > ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent !important;
}
html body#body .nav-sections nav.navigation ul > li.level0 {
  flex-grow: 1;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
html body#body .nav-sections nav.navigation ul > li.level0 > a {
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
  padding: 8px 20px 8px 20px;
  transition: all 0.3s ease-in-out;
}
html body#body .nav-sections nav.navigation ul > li.level0:last-child > a {
  background-color: #EC615E;
}
html body#body .level0.has-active > a {
  background: #92bf1f;
}
html body#body .nav-sections nav.navigation ul > li.level0:hover,
html body#body .nav-sections nav.navigation ul > li.level0:hover a,
html body#body .nav-sections nav.navigation ul > li.level0.active > .level-top {
  background: #92bf1f !important;
}
html body#body .nav-sections nav.navigation ul > li.level0 > a > span.ui-menu-icon {
  display: none !important;
}
html body#body .testimonials-slider {
  margin: 6rem 0;
}
html body#body .testimonials-slider .main .title {
  font-size: 2rem;
  position: relative;
  text-align: center;
  color: #2CAA66;
}
html body#body .testimonials-slider .main .title:before {
  left: 0;
  top: 50%;
  right: 0;
  height: 1px;
  content: "";
  position: absolute;
  background-color: #2CAA66;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
html body#body .testimonials-slider .main .title strong {
  margin: 0;
  font-weight: 600;
  font-size: inherit;
  position: relative;
  padding: 15px 40px;
  display: inline-block;
  background-color: white;
}
html body#body .testimonials-slider .main .icon {
  text-align: center;
  margin-bottom: 2rem;
}
html body#body .testimonials-slider .main .icon img {
  display: block;
  margin: 0 auto;
  max-width: 150px;
  max-height: 150px;
}
html body#body .testimonials-slider .main .owl-carousel {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body#body .testimonials-slider .main .slide {
  padding: 2rem;
}
html body#body .testimonials-slider .main .slide .slide-content {
  margin: 0 auto;
  max-width: 720px;
  position: relative;
  text-align: center;
}
html body#body .testimonials-slider .main .slide .slide-content p {
  line-height: 2;
}
html body#body .testimonials-slider .main .slide .slide-content p:before {
  content: "\"";
  line-height: 0;
  font-size: 2rem;
  margin-top: -6px;
  font-style: italic;
  margin-right: 5px;
  font-family: 'Open Sans';
}
html body#body .testimonials-slider .main .slide .slide-content p:after {
  line-height: 0;
  content: "\"";
  font-size: 2rem;
  margin-top: -6px;
  font-style: italic;
  font-family: 'Open Sans';
}
html body#body .boxes {
  margin: 0 0 6rem 0;
}
html body#body .boxes .title {
  font-size: 2rem;
  color: #2CAA66;
  position: relative;
  text-align: center;
  margin-bottom: 2rem;
}
html body#body .boxes .title:before {
  left: 0;
  top: 50%;
  right: 0;
  height: 1px;
  content: "";
  position: absolute;
  background-color: #2CAA66;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
html body#body .boxes .title strong {
  margin: 0;
  font-size: inherit;
  position: relative;
  padding: 15px 40px;
  display: inline-block;
  background-color: white;
}
html body#body .boxes .items {
  margin-left: -1rem;
  margin-right: -1rem;
  clear: both;
}
html body#body .boxes .items:before,
html body#body .boxes .items:after {
  content: '';
  display: table;
}
html body#body .boxes .items:after {
  clear: both;
}
html body#body .boxes .items * {
  box-sizing: border-box;
}
html body#body .boxes .items .info-block-widget {
  float: left;
  padding: 1rem;
}
html body#body .boxes .items .info-block-widget > div {
  padding: 2rem;
  color: white;
  overflow: hidden;
  min-height: 245px;
  position: relative;
  padding-bottom: 5.5rem;
}
@media (min-width: 440px) {
  html body#body .boxes .items .info-block-widget > div {
    padding-top: 8rem;
  }
}
@media (min-width: 550px) {
  html body#body .boxes .items .info-block-widget > div {
    padding-top: 2rem;
  }
}
html body#body .boxes .items .info-block-widget > div .ibw-title,
html body#body .boxes .items .info-block-widget > div .ibw-subtitle,
html body#body .boxes .items .info-block-widget > div .ibw-infoline,
html body#body .boxes .items .info-block-widget > div .ibw-subtext {
  display: block;
  margin-bottom: 0.4rem;
}
@media (min-width: 550px) {
  html body#body .boxes .items .info-block-widget > div .ibw-title,
  html body#body .boxes .items .info-block-widget > div .ibw-subtitle,
  html body#body .boxes .items .info-block-widget > div .ibw-infoline,
  html body#body .boxes .items .info-block-widget > div .ibw-subtext {
    max-width: 60%;
  }
}
html body#body .boxes .items .info-block-widget > div .ibw-title {
  font-weight: bold;
  font-size: 2.3rem;
  margin-bottom: 1rem;
}
@media (min-width: 440px) {
  html body#body .boxes .items .info-block-widget > div .ibw-title {
    max-width: 62%;
  }
}
html body#body .boxes .items .info-block-widget > div .ibw-subtitle {
  font-weight: bold;
  font-size: 1.4rem;
}
@media (min-width: 440px) {
  html body#body .boxes .items .info-block-widget > div .ibw-subtitle {
    max-width: 70%;
  }
}
html body#body .boxes .items .info-block-widget > div a:not(.button) {
  color: white;
  font-size: 1rem;
  max-width: 100px;
}
html body#body .boxes .items .info-block-widget > div a.button {
  left: 2rem;
  bottom: 2rem;
  color: white;
  margin-top: 1rem;
  font-weight: bold;
  font-size: 1.2rem;
  border-radius: 8px;
  background: #000000;
  position: absolute;
  padding: 0.8rem 2rem;
  display: inline-block;
}
html body#body .boxes .items .info-block-widget > div a.button:hover {
  background-color: #333333;
}
html body#body .boxes .items .info-block-widget > div img {
  top: 0.5rem;
  right: 0.5rem;
  display: none;
  max-width: 150px;
  position: absolute;
  transform: translateY(0%);
}
@media (min-width: 440px) {
  html body#body .boxes .items .info-block-widget > div img {
    top: 1.5rem;
    right: 1.5rem;
    display: block;
  }
}
@media (min-width: 550px) {
  html body#body .boxes .items .info-block-widget > div img {
    top: 50%;
    bottom: 2rem;
    transform: translateY(-50%);
  }
}
html body#body .boxes .items .info-block-widget.btn-red a.button {
  background-color: #D6171C;
}
html body#body .boxes .items .info-block-widget.btn-red a.button:hover {
  background-color: #a81216;
}
html body#body .boxes .items .info-block-widget.background-image > div {
  background-size: cover;
  background-position: center center;
}
html body#body .boxes.homepage-one .info-block-widget {
  width: 100%;
}
@media (min-width: 939px) {
  html body#body .boxes.homepage-one .info-block-widget {
    width: 33.333336%;
  }
}
html body#body .boxes.homepage-one .info-block-widget:nth-child(4) {
  width: 100%;
}
html body#body .boxes.homepage-one .info-block-widget:nth-child(4) > div {
  padding: 0;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 939px) {
  html body#body .boxes.homepage-one .info-block-widget:nth-child(4) > div {
    padding: 2rem;
  }
}
html body#body .boxes.homepage-one .info-block-widget:nth-child(4) .content {
  width: 100%;
  display: flex;
  min-height: 260px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}
@media (min-width: 939px) {
  html body#body .boxes.homepage-one .info-block-widget:nth-child(4) .content {
    width: 50%;
    min-height: 340px;
    background-color: transparent;
  }
}
html body#body .boxes.homepage-one .info-block-widget:nth-child(4) .content .ibw-title,
html body#body .boxes.homepage-one .info-block-widget:nth-child(4) .content .ibw-subtitle,
html body#body .boxes.homepage-one .info-block-widget:nth-child(4) .content .ibw-infoline,
html body#body .boxes.homepage-one .info-block-widget:nth-child(4) .content .ibw-subtext {
  width: 100%;
  max-width: 80%;
  text-align: center;
}
html body#body .boxes.homepage-one .info-block-widget:nth-child(4) .content .ibw-title {
  font-size: 2rem;
}
@media (min-width: 939px) {
  html body#body .boxes.homepage-one .info-block-widget:nth-child(4) .content .ibw-title {
    font-size: 3.5rem;
  }
}
html body#body .boxes.homepage-one .info-block-widget:nth-child(4) .content .ibw-subtitle,
html body#body .boxes.homepage-one .info-block-widget:nth-child(4) .content .ibw-infoline {
  font-size: 1.5rem;
}
@media (min-width: 939px) {
  html body#body .boxes.homepage-one .info-block-widget:nth-child(4) .content .ibw-subtitle,
  html body#body .boxes.homepage-one .info-block-widget:nth-child(4) .content .ibw-infoline {
    font-size: 2rem;
  }
}
html body#body .boxes.homepage-one .info-block-widget:nth-child(4) .content a.button {
  left: auto;
  right: auto;
  bottom: auto;
  position: relative;
}
html body#body .boxes.homepage-one .info-block-widget:nth-child(4) img {
  display: none;
}
html body#body .boxes.homepage-two .info-block-widget {
  width: 100%;
}
@media (min-width: 939px) {
  html body#body .boxes.homepage-two .info-block-widget {
    width: 50%;
  }
}
html body#body .boxes.homepage-two .info-block-widget .ibw-subtitle {
  font-size: 1rem;
}
html body#body .boxes.homepage-two .info-block-widget img {
  top: 50%;
  max-width: 150px;
  max-height: 150px;
  position: absolute;
  transform: translateY(-50%);
}
html body#body footer {
  clear: both;
}
html body#body footer:before,
html body#body footer:after {
  content: '';
  display: table;
}
html body#body footer:after {
  clear: both;
}
html body#body footer .footer-top {
  background: #EFEFEF;
}
html body#body footer .footer-top .cta.newsletter {
  padding: 3rem 0;
  background: #067C58;
}
html body#body footer .footer-top .cta.newsletter .content {
  width: auto;
  display: flex;
  max-width: 1280px;
  margin-left: auto;
  position: relative;
  margin-right: auto;
  padding-left: 15px;
  align-items: center;
  padding-right: 15px;
  flex-direction: column;
  box-sizing: border-box;
}
html body#body footer .footer-top .cta.newsletter .content:before,
html body#body footer .footer-top .cta.newsletter .content:after {
  content: '';
  display: table;
}
html body#body footer .footer-top .cta.newsletter .content:after {
  clear: both;
}
html body#body footer .footer-top .cta.newsletter .content .icon {
  left: 0;
  display: none;
  bottom: -3.5rem;
  position: absolute;
}
@media (min-width: 939px) {
  html body#body footer .footer-top .cta.newsletter .content .icon {
    display: block;
  }
}
html body#body footer .footer-top .cta.newsletter .content .content-wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0 1rem 1.5rem;
}
html body#body footer .footer-top .cta.newsletter .content .content-wrapper .content-text {
  width: 100%;
}
html body#body footer .footer-top .cta.newsletter .content .content-wrapper .content-text h5,
html body#body footer .footer-top .cta.newsletter .content .content-wrapper .content-text p {
  color: white;
}
html body#body footer .footer-top .cta.newsletter .content .content-wrapper .content-text h5 {
  font-size: 2.5rem;
  text-align: center;
  margin: 0 0 1rem 0;
}
@media (min-width: 767px) {
  html body#body footer .footer-top .cta.newsletter .content .content-wrapper .content-text h5 {
    margin: 0 0 1rem 0;
  }
}
html body#body footer .footer-top .cta.newsletter .content .content-wrapper .content-text p {
  max-width: 460px;
  text-align: center;
  margin: 0 0 1.5rem 0;
  font-size: 1.3rem;
}
@media (min-width: 767px) {
  html body#body footer .footer-top .cta.newsletter .content .content-wrapper .content-text p {
    display: block;
  }
}
html body#body footer .footer-top .cta.newsletter .content .content-wrapper .content-button .button {
  color: white;
  display: block;
  border-radius: 10px;
  padding: 0.8rem 1.4rem;
  background: #92bf1f;
  text-transform: uppercase;
}
html body#body footer .footer-top .cta.newsletter .content .content-wrapper .content-button .button:hover {
  color: #067C58;
  background: white;
}
html body#body footer .footer-top .cta.newsletter:before,
html body#body footer .footer-top .cta.newsletter:after {
  content: '';
  display: table;
}
html body#body footer .footer-top .footer {
  border: 0;
  clear: both;
  display: flex;
  padding: 1rem 0 0 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
@media (min-width: 767px) {
  html body#body footer .footer-top .footer {
    flex-direction: row;
  }
}
html body#body footer .footer-top .footer * {
  box-sizing: border-box;
}
html body#body footer .footer-top .footer .footer-item {
  width: 100%;
  padding: 0 1rem;
}
@media (min-width: 767px) {
  html body#body footer .footer-top .footer .footer-item {
    padding: 1rem;
  }
}
html body#body footer .footer-top .footer .footer-item ul {
  display: none;
  padding: 0 1rem;
}
html body#body footer .footer-top .footer .footer-item h5 {
  margin: 0;
  width: 100%;
  color: white;
  padding: 1rem;
  display: block;
  cursor: pointer;
  font-size: 1.4rem;
  position: relative;
  background: #067C58;
  border-bottom: 2px solid white;
}
html body#body footer .footer-top .footer .footer-item h5:after {
  top: 50%;
  right: 10px;
  content: "\f107";
  position: absolute;
  font-weight: normal;
  font-family: "Fontawesome";
  transform: translateY(-50%);
}
html body#body footer .footer-top .footer .footer-item:nth-child(even) h5 {
  background-color: #92bf1f;
}
html body#body footer .footer-top .footer .footer-item[data-expand="true"] h5 {
  border-bottom: 0;
}
html body#body footer .footer-top .footer .footer-item[data-expand="true"] h5:after {
  top: 50%;
  right: 10px;
  content: "\f106";
  position: absolute;
  font-weight: normal;
  font-family: "Fontawesome";
  transform: translateY(-50%);
}
html body#body footer .footer-top .footer .footer-item[data-expand="true"] ul {
  display: block;
  background: white;
  border-left: 2px solid #C1C1C1;
  border-right: 2px solid #C1C1C1;
  border-bottom: 2px solid #C1C1C1;
}
html body#body footer .footer-top .footer .footer-item[data-expand="true"] ul li {
  padding: 0.5rem 0;
}
@media (min-width: 767px) {
  html body#body footer .footer-top .footer .footer-item[data-expand="true"] ul li {
    padding: 0 !important;
  }
}
html body#body footer .footer-top .footer .footer-item[data-expand="true"] ul li a {
  color: #000000;
}
@media (min-width: 767px) {
  html body#body footer .footer-top .footer .footer-item {
    width: 100%;
    padding: 1.5rem 1rem;
  }
  html body#body footer .footer-top .footer .footer-item h5 {
    padding: 0;
    cursor: default;
    margin: 0 0 1rem;
    font-size: 1.4rem;
    color: #067C58;
    border-bottom: 0;
    background: transparent !important;
  }
  html body#body footer .footer-top .footer .footer-item h5:after {
    content: "";
    display: none;
  }
  html body#body footer .footer-top .footer .footer-item ul {
    border: 0 !important;
    padding: 0 !important;
    display: block !important;
    background: transparent !important;
  }
  html body#body footer .footer-top .footer .footer-item ul li {
    margin-bottom: 5px;
  }
  html body#body footer .footer-top .footer .footer-item ul li p,
  html body#body footer .footer-top .footer .footer-item ul li a {
    font-size: 1.1rem;
    color: #46484a;
  }
  html body#body footer .footer-top .footer .footer-item ul li a {
    cursor: pointer;
  }
  html body#body footer .footer-top .footer .footer-item ul li a:hover {
    color: #067C58;
  }
}
@media (min-width: 767px) and (min-width: 767px) {
  html body#body footer .footer-top .footer .footer-item {
    width: 20%;
  }
}
html body#body footer .footer-bottom {
  padding: 2rem 0;
  background: #EFEFEF;
}
html body#body footer .footer-bottom > .page-main {
  display: flex;
  flex-direction: column;
}
@media (min-width: 767px) {
  html body#body footer .footer-bottom > .page-main {
    flex-direction: row;
  }
}
html body#body footer .footer-bottom > .page-main > div {
  width: 100%;
}
@media (min-width: 767px) {
  html body#body footer .footer-bottom > .page-main > div {
    width: 50%;
  }
  html body#body footer .footer-bottom > .page-main > div:first-child,
  html body#body footer .footer-bottom > .page-main > div:last-child {
    width: 25%;
  }
}
html body#body footer .footer-bottom > .page-main > div .google-review {
  display: none;
  background: white;
  border-radius: 2px;
  border: 2px solid #C1C1C1;
}
@media (min-width: 767px) {
  html body#body footer .footer-bottom > .page-main > div .google-review {
    display: block;
  }
}
html body#body footer .footer-bottom > .page-main > div.footer-copyright-outer {
  padding: 1rem 2rem;
  box-sizing: border-box;
}
html body#body footer .footer-bottom > .page-main > div.footer-copyright-outer .payment-icons ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  justify-content: center;
}
html body#body footer .footer-bottom > .page-main > div.footer-copyright-outer .payment-icons ul li {
  padding: 10px;
  margin-bottom: 0;
}
html body#body footer .footer-bottom > .page-main > div.footer-copyright-outer .payment-icons ul li img {
  max-width: 40px;
}
html body#body footer .footer-bottom > .page-main > div.footer-copyright-outer small.copyright {
  display: block;
  color: #C1C1C1;
  margin-top: 10px;
  text-align: center;
}
html body#body footer .footer-bottom > .page-main > div.footer-copyright-outer small.copyright a {
  color: inherit;
}
html body#body footer .footer-bottom > .page-main > div.footer-copyright-outer small.copyright a:hover {
  color: #067C58;
}
html body#body footer .footer-bottom > .page-main > div.footer-badges-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
}
@media (min-width: 767px) {
  html body#body footer .footer-bottom > .page-main > div.footer-badges-wrapper {
    justify-content: flex-end;
  }
}
html body#body footer .footer-bottom > .page-main > div.footer-badges-wrapper img.fair-to-nature {
  max-width: 90px;
  max-height: 70px;
  margin-left: 1rem;
}
html body#body .home-slider-wrapper {
  margin-top: 1rem;
}
html body#body .home-slider-wrapper .owl-prev,
html body#body .home-slider-wrapper .owl-next {
  opacity: 1;
  height: 32px;
  display: none;
  align-items: center;
  margin: 0 !important;
  justify-content: center;
  transform: translateY(-50%);
  background: white !important;
}
html body#body .home-slider-wrapper .owl-prev .left-arrow,
html body#body .home-slider-wrapper .owl-next .left-arrow,
html body#body .home-slider-wrapper .owl-prev .right-arrow,
html body#body .home-slider-wrapper .owl-next .right-arrow {
  color: #000000;
}
html body#body .home-slider-wrapper:hover .owl-prev,
html body#body .home-slider-wrapper:hover .owl-next {
  display: flex;
}
html body#body .breadcrumbs {
  display: none;
  font-size: 1rem;
  margin-bottom: 15px;
  padding: 1.1rem 1rem 0 1.2rem;
}
@media (min-width: 939px) {
  html body#body .breadcrumbs {
    display: block;
  }
}
html body#body .breadcrumbs ul.items li.item a {
  color: #C1C1C1;
  text-decoration: none;
}
html body#body .breadcrumbs ul.items li.item a:after {
  line-height: 22px;
}
html body#body .breadcrumbs ul.items li.item a:hover {
  color: #92bf1f;
}
html body#body .breadcrumbs ul.items li.item:last-child strong {
  color: #C1C1C1;
}
html body#body .sidebar .block {
  padding: 0;
  background: #EFEFEF;
}
html body#body .sidebar .block .block-title {
  line-height: 1;
  padding: 1rem 1.5rem;
  background: #92bf1f;
}
html body#body .sidebar .block .block-title strong {
  color: white;
  font-size: 1.6rem;
}
html body#body .sidebar .block .block-title .counter {
  color: white;
}
html body#body .sidebar .block .block-content,
html body#body .sidebar .block > .empty {
  padding: 0 1.5rem 1.5rem;
}
html body#body .sidebar .block .block-content .block-subtitle,
html body#body .sidebar .block > .empty .block-subtitle {
  display: none;
}
html body#body .sidebar .block .block-content .filter-options .filter-options-title,
html body#body .sidebar .block > .empty .filter-options .filter-options-title {
  padding: 0.6rem 0;
  color: #067C58;
  text-transform: none;
  border-top: 1px solid #C1C1C1;
  border-bottom: 1px solid #C1C1C1;
}
html body#body .sidebar .block .block-content .filter-options li.item,
html body#body .sidebar .block > .empty .filter-options li.item {
  margin-bottom: 8px;
}
html body#body .sidebar .block .block-content .filter-options li.item:hover a,
html body#body .sidebar .block > .empty .filter-options li.item:hover a {
  margin-left: 8px;
}
html body#body .sidebar .block .block-content .filter-options li.item a,
html body#body .sidebar .block > .empty .filter-options li.item a {
  color: #000000;
  position: relative;
  padding: 0 0 0 14px;
  transition: all 0.25s ease-in-out;
}
html body#body .sidebar .block .block-content .filter-options li.item a:hover,
html body#body .sidebar .block > .empty .filter-options li.item a:hover {
  color: #92bf1f;
}
html body#body .sidebar .block .block-content .filter-options li.item a:before,
html body#body .sidebar .block > .empty .filter-options li.item a:before {
  top: 50%;
  left: 0px;
  width: 0px;
  height: 0px;
  content: '';
  display: block;
  position: absolute;
  border-right: none;
  transform: translateY(-50%);
  border-left: 4px solid #C1C1C1;
  border-top: 4px solid rgba(0, 0, 0, 0);
  border-bottom: 4px solid rgba(0, 0, 0, 0);
}
html body#body .sidebar .block .block-content .filter-options li.item a .count,
html body#body .sidebar .block > .empty .filter-options li.item a .count {
  display: none;
}
html body#body .attribute_details_buttons a {
  width: 150px;
  font-size: 12px;
  font-weight: 600;
  margin-top: 10px;
  color: #46484a;
  padding: 10px 30px;
  text-transform: uppercase;
  text-transform: lowercase;
  border: 1px solid #C1C1C1;
}
html body#body .attribute_details_buttons a:hover {
  color: #92bf1f;
}
html body#body .product.cta-bar,
html body#body .category.cta-bar {
  margin-bottom: 1.5rem;
}
html body#body .product.cta-bar .product-actions,
html body#body .category.cta-bar .product-actions {
  display: flex;
  color: white;
  padding: 1.4rem;
  align-items: center;
  justify-content: center;
}
@media (min-width: 939px) {
  html body#body .product.cta-bar .product-actions,
  html body#body .category.cta-bar .product-actions {
    padding: 0.8rem;
  }
}
html body#body .product.cta-bar .product-actions .item a,
html body#body .category.cta-bar .product-actions .item a,
html body#body .product.cta-bar .product-actions .item div,
html body#body .category.cta-bar .product-actions .item div {
  color: inherit;
}
@media (min-width: 939px) {
  html body#body .product.cta-bar .product-actions .item a,
  html body#body .category.cta-bar .product-actions .item a,
  html body#body .product.cta-bar .product-actions .item div,
  html body#body .category.cta-bar .product-actions .item div {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}
html body#body .product.cta-bar .product-actions .item a div.content-main,
html body#body .category.cta-bar .product-actions .item a div.content-main,
html body#body .product.cta-bar .product-actions .item div div.content-main,
html body#body .category.cta-bar .product-actions .item div div.content-main {
  display: flex;
  align-items: center;
  justify-content: center;
}
html body#body .product.cta-bar .product-actions .item a img,
html body#body .category.cta-bar .product-actions .item a img,
html body#body .product.cta-bar .product-actions .item div img,
html body#body .category.cta-bar .product-actions .item div img {
  display: none;
  max-width: 42px;
  max-height: 42px;
  margin-right: 1rem;
}
@media (min-width: 939px) {
  html body#body .product.cta-bar .product-actions .item a img,
  html body#body .category.cta-bar .product-actions .item a img,
  html body#body .product.cta-bar .product-actions .item div img,
  html body#body .category.cta-bar .product-actions .item div img {
    display: block;
  }
}
html body#body .product.cta-bar .product-actions .item a span.title,
html body#body .category.cta-bar .product-actions .item a span.title,
html body#body .product.cta-bar .product-actions .item div span.title,
html body#body .category.cta-bar .product-actions .item div span.title {
  margin: 0;
  line-height: 1;
  font-size: 2rem;
  text-align: left;
}
html body#body .product.cta-bar .product-actions .item a span,
html body#body .category.cta-bar .product-actions .item a span,
html body#body .product.cta-bar .product-actions .item div span,
html body#body .category.cta-bar .product-actions .item div span {
  display: inherit;
  font-size: 0.8rem;
  text-align: right;
}
@media (min-width: 939px) {
  html body#body .product.cta-bar .product-actions .item a span,
  html body#body .category.cta-bar .product-actions .item a span,
  html body#body .product.cta-bar .product-actions .item div span,
  html body#body .category.cta-bar .product-actions .item div span {
    text-align: left;
    margin-left: 1rem;
    margin-bottom: 5px;
  }
}
html body#body .product.cta-bar .product-actions .item a span a,
html body#body .category.cta-bar .product-actions .item a span a,
html body#body .product.cta-bar .product-actions .item div span a,
html body#body .category.cta-bar .product-actions .item div span a {
  color: inherit;
  margin: 0 3px;
  text-decoration-style: double;
  text-decoration-line: underline;
}
html body#body .product.cta-bar .product-actions .item a span a:hover,
html body#body .category.cta-bar .product-actions .item a span a:hover,
html body#body .product.cta-bar .product-actions .item div span a:hover,
html body#body .category.cta-bar .product-actions .item div span a:hover {
  color: #EC615E;
}
html body#body .product.detailed .data.item.title {
  border: 0;
  color: #000000;
  margin-right: 1rem;
}
html body#body .product.detailed .data.item.content {
  margin-top: 0;
  padding: 3rem 2rem;
  border: 2px solid #eaeaea;
}
@media (min-width: 768px) {
  html body#body .product.detailed .data.item.content {
    margin-top: 44px;
  }
}
html body#body .product.detailed .data.item.content .description p > strong {
  margin-top: 1rem;
  display: inline-block;
}
html body#body .product.detailed .data.item.content .description strong,
html body#body .product.detailed .data.item.content .description strong span {
  color: #92bf1f !important;
}
html body#body .product.detailed .data.item.content .description a {
  color: #067C58 !important;
  text-decoration: underline !important;
}
html body#body .product.detailed .data.item.content .description a:hover {
  color: #000000 !important;
}
html body#body .product.detailed .data.item.content .description span[style="color: #29b471;"] {
  font-weight: bold;
  color: #067C58 !important;
}
html body#body .product.detailed .data.item.active .switch {
  color: white;
  background: #92bf1f;
}
html body#body .product.detailed .data.item.active .switch:hover {
  color: white;
  background: #8bb61e;
}
html body#body .product.detailed .data.item > .switch {
  border: 0;
  padding: 5px 15px 5px 15px;
  border-radius: 5px 5px 0 0;
  background: #eaeaea;
}
html body#body .product.detailed .data.item > .switch:hover {
  color: #000000;
  background: #d6d6d6;
}
html body#body .product.detailed .data.item > .switch .counter {
  display: none;
}
html body#body .block.review-list .block-title {
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 2px solid #EFEFEF;
}
html body#body .block.review-list .block-title strong {
  font-size: 2rem;
  font-weight: bold;
  color: #067C58;
}
html body#body .block.review-list .review-item .review-title {
  font-size: 1.6rem;
  font-weight: bold;
  color: #067C58;
  margin-bottom: 1rem;
}
html body#body .box-tocart {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
html body#body .box-tocart #product-addtocart-button {
  border: 0;
  border-radius: 0;
  font-size: 1.7rem;
  padding: 1rem 6rem;
  font-weight: normal;
  text-transform: none;
  background: #067C58;
}
html body#body .box-tocart #product-addtocart-button:hover {
  background: #92bf1f;
}
html body#body .control.qty {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 939px) {
  html body#body .control.qty {
    justify-content: flex-start;
  }
}
html body#body .control.qty .control.btn {
  cursor: pointer;
  padding: 5px 15px;
  background: white;
  border: 1px solid #C1C1C1;
}
html body#body .control.qty .control.btn span {
  text-align: center;
}
html body#body .control.qty .control.btn:hover {
  background: #d6d6d6;
}
html body#body .control.qty input[type="number"] {
  width: 40px;
  height: 34px;
  padding: 5px 10px;
  border: 0 !important;
}
html body#body.catalog-product-view .box-tocart .qty td:nth-child(3) {
  width: 140px;
  text-align: center;
}
html body#body.catalog-product-view .box-tocart #qty {
  width: 40px;
  height: 34px;
  padding: 5px 10px;
  border: 0 !important;
}
html body#body.catalog-product-view .box-tocart .qty-btn {
  cursor: pointer;
  padding: 5px 15px;
  background: white;
  text-align: center;
  border: 1px solid #C1C1C1;
}
html body#body.catalog-product-view .box-tocart .qty-btn:hover {
  background: #d6d6d6;
}
html body#body.catalog-product-view .box-tocart .qty-btn.qty-dec {
  float: left;
}
html body#body.catalog-product-view .box-tocart .qty-btn.qty-inc {
  float: right;
}
html body#body .product-image-photo {
  display: block;
  max-width: 225px;
  max-height: 225px;
  position: relative;
  margin: 0 auto 10px auto;
}
@media (max-width: 970px) {
  html body#body .product-image-photo {
    max-width: 215px;
    max-height: 215px;
  }
}
@media (max-width: 500px) {
  html body#body .product-image-photo {
    max-width: 205px;
    max-height: 205px;
  }
}
html body#body #shopping-cart-table .product-image-photo {
  max-width: 100%;
  max-height: 100%;
}
@media (min-width: 767px) {
  html body#body #shopping-cart-table .product-image-photo {
    max-width: 100px;
    max-height: 100px;
  }
}
html body#body .rewardssocial-buttons {
  display: none;
}
html body#body .widget .page-title-main {
  z-index: 1;
  font-size: 2rem;
  margin-bottom: 0;
  color: #2CAA66;
  position: relative;
  margin-bottom: 2rem;
  text-transform: none;
}
html body#body .widget .page-title-main strong {
  border: 0;
  z-index: 2;
  margin: 0 auto;
  color: inherit;
  font-size: inherit;
  position: relative;
  padding: 15px 40px;
  display: inline-block;
  text-transform: inherit;
  background-color: white;
}
html body#body .widget .page-title-main:before {
  left: 0;
  top: 50%;
  right: 0;
  height: 1px;
  content: "";
  position: absolute;
  transform: translateY(-50%);
  background-color: #2CAA66;
}
html body#body .widget.block-new-products .product-img-main .product-item-inner {
  display: none;
}
html body#body .widget.block-new-products .product-item-details .product-item-name a {
  font-weight: bold;
  color: #067C58;
}
html body#body .widget.block-new-products .product-item-details .product-item-name {
  height: 48px;
  display: flex;
  overflow: hidden;
  justify-content: center;
}
html body#body .widget.block-new-products .product-item-details .product-reviews-summary {
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 939px) {
  html body#body .widget.block-new-products .product-item-details .product-reviews-summary {
    flex-direction: row;
  }
}
html body#body .widget.block-new-products .product-item-details .product-reviews-summary .reviews-actions {
  margin: 0;
  line-height: 1;
  display: block !important;
}
html body#body .widget.block-new-products .product-item-details .product-reviews-summary .view {
  color: #000000;
  font-weight: bold;
}
html body#body .widget.block-new-products .product-item-details .product-reviews-summary .add {
  display: none;
}
html body#body .widget.block-new-products .product-item-details .product-star-rating {
  overflow: hidden;
}
html body#body .widget.block-new-products .product-item-details .product-star-rating .rating-summary .rating-result > span:before {
  color: #FFCC00;
}
html body#body .widget.block-new-products .product-item-details .product-star-rating .rating-summary .rating-result > span:before span {
  display: inline;
}
html body#body .widget.block-new-products .product-item-details .price-box {
  height: 50px;
  display: flex;
  overflow: hidden;
  margin: 10px 0 0 0;
  align-items: center;
  justify-content: center;
}
html body#body .widget.block-new-products .product-item-details .price-box .price-label {
  display: none;
}
html body#body .widget.block-new-products .product-item-details .price-box .price {
  font-size: 1.8rem;
  color: #067C58;
}
html body#body .widget.block-new-products .product-item-details .price-box .price:before {
  color: #000000;
  display: block;
  font-size: 1rem;
  font-weight: normal;
  content: "Prices from:";
}
html body#body .widget.block-new-products .product-item-details .product-item-inner .product-item-actions {
  padding: 0;
  margin-top: 10px;
}
html body#body .widget.block-new-products .product-item-details .product-item-inner .product-item-actions button {
  border: 0;
  border-radius: 8px;
  text-transform: none;
  padding: 0.8rem 2rem;
  background-color: #92bf1f;
}
html body#body .widget.block-new-products .product-item-details .product-item-inner .product-item-actions button:hover {
  background-color: #067C58;
}
html body#body .widget.block-new-products .product-star-rating {
  color: red;
  text-align: center;
}
html body#body .widget .testmonial-back .page-title-main strong {
  background-color: #f5f5f5;
}
html body#body .sidebar.sidebar-additional .block-compare {
  display: none;
}
html body#body .sidebar.sidebar-additional .product-item {
  display: flex;
}
html body#body .sidebar.sidebar-additional .product-items {
  margin-bottom: 2rem;
}
html body#body .sidebar.sidebar-additional .actions-toolbar {
  display: flex;
  max-width: none;
  margin: 1rem 0 0 0;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}
html body#body .sidebar.sidebar-additional .actions-toolbar > div button {
  margin: 0 0 0 2rem;
}
html body#body .sidebar.sidebar-additional .actions-toolbar > div a {
  margin: 0;
}
html body#body .sidebar.sidebar-additional button.action.primary {
  border: 0;
  border-radius: 8px;
  text-transform: none;
  padding: 0.8rem 2rem;
  background-color: #067C58;
}
html body#body .sidebar.sidebar-additional button.action.primary:hover {
  background-color: #92bf1f;
}
html body#body .sidebar.sidebar-additional .reorder a {
  color: #000000;
}
html body#body .sidebar.sidebar-additional .reorder .actions-toolbar button {
  border: 0;
  font-size: 1.2rem;
  border-radius: 8px;
  text-transform: none;
  padding: 0.8rem 1rem;
  background-color: #067C58;
}
html body#body .sidebar.sidebar-additional .reorder .actions-toolbar button:hover {
  background-color: #92bf1f;
}
html body#body .sidebar.sidebar-additional .reorder .action.view:hover {
  color: #067C58;
}
html body#body .suggested {
  margin-bottom: 2rem;
}
html body#body .mst-search__highlight {
  background: #e2f3b7;
}
@media (max-width: 767px) {
  html body#body .search .block-search {
    margin-top: 0;
    position: initial !important;
  }
  html body#body .search .block-search .label {
    margin-top: 0;
  }
  html body#body .search .block-search:before {
    display: none !important;
  }
  html body#body .search .block-search .block-title {
    display: none;
  }
  html body#body .search .block-search .block-content form .search .control {
    left: 0;
    right: 0;
    top: 75px;
    z-index: 3000;
    padding: 0 4rem;
    position: absolute;
    background: #067C58;
  }
  html body#body .search .block-search .block-content form .search .control #search {
    background-color: white;
  }
  html body#body .search .block-search .block-content form .search .control #search:focus,
  html body#body .search .block-search .block-content form .search .control #search:active {
    outline-offset: 3px !important;
    outline-width: 1px !important;
    outline-color: white !important;
    outline-style: dashed !important;
  }
  html body#body .search .block-search .block-content form .search .actions {
    display: none;
  }
}
@media (max-width: 767px) and (max-width: 470px) {
  html body#body .search .block-search .block-content form .search .control {
    top: 120px;
  }
}
html body#body .search.results > .block:before,
html body#body .search.results > .block:after {
  content: "";
  display: table;
}
html body#body .search.results > .block:after {
  clear: both;
}
html body#body .search.results > .block dd {
  float: left;
  margin-right: 0.5rem;
}
html body#body .search.results > .block dd:after {
  content: ",";
  margin-left: -4px;
}
html body#body .search.results > .block dd:last-child:after {
  display: none;
}
html body#body .search .products {
  clear: both;
  margin-top: 4rem;
}
html body#body .mst-searchautocomplete__autocomplete {
  margin-top: 2rem;
  overflow: hidden;
  border-radius: 0.4rem;
}
html body#body .mst-searchautocomplete__autocomplete .mst-searchautocomplete__highlight {
  color: #92bf1f !important;
}
html body#body .mst-searchautocomplete__autocomplete .mst-searchautocomplete__wrapper {
  overflow: hidden;
  border-radius: 0.4rem;
  border: 1px solid #EFEFEF;
}
html body#body .mst-searchautocomplete__autocomplete .mst-searchautocomplete__index-title {
  color: white;
  background-color: #067C58;
}
html body#body .mst-searchautocomplete__autocomplete .mst-searchautocomplete__spinner {
  margin-top: 4px;
}
html body#body .mst-searchautocomplete__autocomplete .mst-searchautocomplete__close {
  color: white;
  padding: 0.7rem 1rem;
}
html body#body .mst-searchautocomplete__autocomplete .mst-searchautocomplete__index {
  border: 0;
}
html body#body .mst-searchautocomplete__autocomplete .product-reviews-summary .rating-summary {
  left: 0;
}
html body#body .mst-searchautocomplete__autocomplete .product-reviews-summary .rating-summary .rating-result > span:before {
  color: #FFCC00;
}
html body#body .page.messages a {
  color: #000000;
}
html body#body .pages ul li a {
  background: #067C58;
  border-color: #067C58;
}
html body#body .pages ul li a:hover {
  background: #000000;
  border-color: #000000;
}
html body#body .pages ul li.current strong {
  background: #92bf1f;
  border-color: #92bf1f;
}
html body#body .stock.available {
  background: #067C58;
  border-color: #067C58;
}
html body#body .product-reviews-summary .reviews-actions a.add {
  color: #067C58;
}
html body#body .header.minicart a {
  color: #000000;
}
html body#body .header.minicart .subtotal {
  display: flex;
  justify-content: flex-end;
}
html body#body .header.minicart .subtotal .label {
  margin-right: 6px;
}
html body#body .header.minicart .subtotal .label:after {
  margin-right: 6px;
}
html body#body .header.minicart #top-cart-btn-checkout {
  background: #067C58;
  border-color: #067C58;
  color: white;
}
html body#body .header.minicart #top-cart-btn-checkout span {
  display: block;
  margin: 0 auto;
}
html body#body .header.minicart #top-cart-btn-checkout:hover {
  background: #000000;
  border-color: #000000;
}
html body#body .header.minicart .minicart-items-wrapper {
  margin: 0 0 0 0;
  border-bottom: 0;
  padding: 15px 12px 0;
}
html body#body .header.minicart .minicart-items-wrapper.active {
  margin: 0 -10px 0 1rem;
}
html body#body .header.minicart .minicart-items-wrapper .minicart-items li:after {
  display: none;
}
html body#body .header.minicart .minicart-items-wrapper .minicart-items li:last-child {
  padding-bottom: 0;
}
html body#body .header.minicart .minicart-items-wrapper .minicart-items li .product {
  display: flex;
}
html body#body .header.minicart .minicart-items-wrapper .minicart-items li .product a {
  line-height: 1;
  margin: 0 0 8px 0;
  width: auto !important;
}
html body#body .header.minicart .minicart-items-wrapper .minicart-items li .product a .product-image-container {
  margin: 0;
  width: 100px;
  height: auto;
}
html body#body .header.minicart .minicart-items-wrapper .minicart-items li .product a .product-image-container .product-image-wrapper {
  margin: 0;
}
html body#body .header.minicart .minicart-items-wrapper .minicart-items li .product a .product-image-container .product-image-wrapper img {
  margin-bottom: 0;
  width: auto !important;
  height: auto !important;
}
html body#body .header.minicart .minicart-items-wrapper .minicart-items li .product div.product-item-details {
  width: 100%;
  padding: 0 0 0 10px;
}
html body#body .header.minicart .minicart-items-wrapper .minicart-items li .product div.product-item-details .product-item-name {
  margin: 0;
  line-height: 1;
}
html body#body .header.minicart .minicart-items-wrapper .minicart-items li .product div.product-item-details .product-item-name a:hover {
  color: #067C58;
}
html body#body .header.minicart .minicart-items-wrapper .minicart-items li .product div.product-item-details .product-item-pricing .label {
  width: 2.5rem;
}
html body#body .header.minicart .minicart-items-wrapper .minicart-items li .product div.product-item-details .product-item-pricing .price-container {
  margin: 0;
}
html body#body .header.minicart .minicart-items-wrapper .minicart-items li .product div.product-item-details .product-item-pricing .price-container .price-wrapper {
  margin: 0;
}
html body#body .header.minicart .minicart-items-wrapper .minicart-items li .product div.product-item-details .product-item-pricing .price-container .price-wrapper .price-excluding-tax {
  margin: 0;
}
html body#body .header.minicart .minicart-items-wrapper .minicart-items li .product div.product-item-details .product-item-pricing .price-container .price-wrapper .price-excluding-tax .minicart-price {
  margin: 0;
}
html body#body .header.minicart .minicart-items-wrapper .minicart-items li .product div.product-item-details .product-item-pricing .price-container .price-wrapper .price-excluding-tax .minicart-price .price {
  margin: 0;
}
html body#body .header.minicart .minicart-items-wrapper .minicart-items li .product div.product-item-details .product-item-pricing .details-qty {
  margin-top: 4px;
}
html body#body .header.minicart .minicart-items-wrapper .minicart-items li .product div.product-item-details .product-item-pricing .details-qty input {
  border-radius: 5px;
}
html body#body .header.minicart .minicart-items-wrapper .minicart-items li .product div.product-item-details .product-item-pricing .details-qty button.update-cart-item {
  padding: 6px;
  color: white !important;
  background: #92bf1f;
  border-color: #92bf1f;
}
html body#body .header.minicart .minicart-items-wrapper .minicart-items li .product div.product-item-details .product-item-pricing .details-qty button.update-cart-item:hover {
  background: #067C58;
  border-color: #067C58;
}
html body#body .header.minicart .minicart-items-wrapper .minicart-items li .product div.product-item-details .product-item-pricing .details-qty button.update-cart-item span {
  color: white !important;
}
html body#body .header.minicart .block-content > .actions:last-child {
  display: none;
}
html body#body .header.minicart .actions .secondary a {
  width: 100%;
  display: block;
  font-weight: bold;
  text-align: center;
  color: #92bf1f;
  margin-bottom: 10px;
  text-transform: uppercase;
}
html body#body .header.minicart .actions .secondary a:hover {
  color: #067C58;
}
html body#body .block-search .control {
  border: none !important;
}
@media (max-width: 980px) {
  html body#body .minicart-wrapper {
    margin: 0 8px 0 0;
  }
  html body#body .minicart-wrapper .block-minicart {
    max-width: 360px;
    z-index: 30000;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100vw - 17px);
  }
  html body#body .minicart-wrapper .action.showcart .text {
    display: none;
  }
  html body#body .minicart-wrapper .counter-number {
    right: 10px !important;
  }
}
@media (max-width: 450px) {
  html body#body .minicart-wrapper .items-total,
  html body#body .minicart-wrapper .subtotal {
    margin: 0;
    width: 100%;
    line-height: 1;
    text-align: center;
  }
  html body#body .minicart-wrapper .subtotal {
    justify-content: center !important;
  }
}
.message.notice a,
a {
  color: #067C58;
}
.message.notice a:hover,
a:hover {
  color: #000000;
}
html body.checkout-cart-index .page-wrapper {
  min-height: 70vh;
}
html body.checkout-cart-index main {
  padding: 2rem 0 2rem 0;
}
html body.checkout-cart-index main a {
  color: #067C58;
}
html body.checkout-cart-index main a:hover {
  color: #000000;
}
html body.checkout-cart-index main .page-title-wrapper {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #92bf1f;
}
html body.checkout-cart-index main .page-title-wrapper .page-title {
  width: 100%;
  color: white;
  font-size: 2rem;
  margin-bottom: 0;
  text-align: center;
}
html body.checkout-cart-index main .messages:before,
html body.checkout-cart-index main .messages:after {
  content: '';
  display: table;
}
html body.checkout-cart-index main .messages:after {
  clear: both;
}
html body.checkout-cart-index main .columns .main {
  padding: 2rem;
  min-height: auto;
  background-color: #EFEFEF;
}
html body.checkout-cart-index main .columns .main .rewards-notification-block {
  margin: 0;
}
html body.checkout-cart-index main .columns .main .cart-empty {
  padding: 1rem;
  font-size: 1.4rem;
  text-align: center;
}
html body.checkout-cart-index main .columns .main .cart-empty p:last-child {
  margin-bottom: 0;
}
html body.checkout-cart-index main .action.action-delete {
  padding: 0 !important;
  border: none !important;
  color: #EC615E !important;
  font-weight: normal !important;
}
html body.checkout-cart-index .cart-summary {
  padding: 0;
  background: #EFEFEF;
}
html body.checkout-cart-index #block-shipping .title {
  padding-top: 0.8rem;
}
html body.checkout-cart-index #block-shipping .title > strong {
  font-size: 1.5rem;
  font-weight: bold;
  color: #067C58;
}
html body.checkout-cart-index #block-shipping .title:after {
  top: 0px;
}
html body.checkout-cart-index #block-discount {
  margin-top: 1rem;
}
html body.checkout-cart-index #block-discount .title {
  padding-top: 1.5rem;
}
html body.checkout-cart-index #block-discount .title > strong {
  font-size: 1.5rem;
  font-weight: bold;
  color: #067C58;
}
html body.checkout-cart-index #block-discount .title:after {
  display: none;
}
html body.checkout-cart-index #block-discount .content {
  display: block !important;
}
html body.checkout-cart-index #block-discount .content input {
  border-radius: 5px;
}
html body.checkout-cart-index #block-rewards-form {
  margin-top: 1rem;
}
html body.checkout-cart-index #block-rewards-form .discount-form {
  padding: 5px;
}
html body.checkout-cart-index #block-rewards-form .title {
  padding-top: 0.7rem;
}
html body.checkout-cart-index #block-rewards-form .title > strong {
  font-size: 1.5rem;
  font-weight: bold;
  color: #067C58;
}
html body.checkout-cart-index #block-rewards-form .title:after {
  top: -2px;
}
html body.checkout-cart-index #block-rewards-form .points_amount {
  margin-bottom: 15px;
}
html body.checkout-cart-index #block-rewards-form .content input {
  border-radius: 5px;
}
html body.checkout-cart-index #block-rewards-form .buttons-container button {
  padding: 0.8rem;
  background: #067C58;
}
html body.checkout-cart-index #block-rewards-form .buttons-container button > span {
  color: white;
  font-size: 1.2rem;
}
html body.checkout-cart-index .checkout button {
  background: #067C58;
  border-color: #067C58;
}
html body.checkout-cart-index .checkout button:hover {
  background: #000000;
  border-color: #000000;
}
html body.checkout-cart-index #block-shipping {
  display: none;
}
html body.customer-account-login .page-wrapper,
html body.multishipping-checkout-login .page-wrapper {
  min-height: 70vh;
}
html body.customer-account-login main,
html body.multishipping-checkout-login main {
  padding: 3rem 1.5rem 2rem 1.5rem;
}
html body.customer-account-login main .page-title-wrapper,
html body.multishipping-checkout-login main .page-title-wrapper {
  padding: 1rem;
  margin-bottom: 2rem;
  background-color: #92bf1f;
}
html body.customer-account-login main .page-title-wrapper .page-title,
html body.multishipping-checkout-login main .page-title-wrapper .page-title {
  width: 100%;
  color: white;
  font-size: 2rem;
  margin-bottom: 0;
  text-align: center;
}
html body.customer-account-login main .page-title-wrapper .page-title span,
html body.multishipping-checkout-login main .page-title-wrapper .page-title span {
  text-transform: none;
}
html body.customer-account-login .columns .column .login-container,
html body.multishipping-checkout-login .columns .column .login-container {
  display: flex;
  flex-direction: column;
}
@media (min-width: 939px) {
  html body.customer-account-login .columns .column .login-container,
  html body.multishipping-checkout-login .columns .column .login-container {
    flex-direction: row-reverse;
  }
}
html body.customer-account-login .columns .column .login-container .block,
html body.multishipping-checkout-login .columns .column .login-container .block {
  padding: 2rem;
  height: 420px;
  background-color: #EFEFEF;
}
@media (min-width: 939px) {
  html body.customer-account-login .columns .column .login-container .block.block-customer-login,
  html body.multishipping-checkout-login .columns .column .login-container .block.block-customer-login {
    margin-left: 1rem;
  }
}
@media (min-width: 939px) {
  html body.customer-account-login .columns .column .login-container .block.block-new-customer,
  html body.multishipping-checkout-login .columns .column .login-container .block.block-new-customer {
    margin-right: 1rem;
  }
}
html body.customer-account-login .columns .column .login-container .block .block-title,
html body.multishipping-checkout-login .columns .column .login-container .block .block-title {
  font-weight: bold;
}
html body.customer-account-login .columns .column .login-container .block .block-title strong,
html body.multishipping-checkout-login .columns .column .login-container .block .block-title strong {
  font-weight: inherit;
}
html body.customer-account-login .columns .column .login-container .block .actions-toolbar .primary a,
html body.multishipping-checkout-login .columns .column .login-container .block .actions-toolbar .primary a {
  border: 0;
  font-size: 1.6rem;
  padding: 0.8rem 1rem;
  background-color: #92bf1f;
}
html body.customer-account-login .columns .column .login-container .block .actions-toolbar .primary a:hover,
html body.multishipping-checkout-login .columns .column .login-container .block .actions-toolbar .primary a:hover {
  background-color: #067C58;
}
html body.customer-account-login .columns .column .block-customer-login .block-content .field .control,
html body.multishipping-checkout-login .columns .column .block-customer-login .block-content .field .control {
  width: 100%;
}
html body.customer-account-login .columns .column .block-customer-login .block-content .actions-toolbar button,
html body.multishipping-checkout-login .columns .column .block-customer-login .block-content .actions-toolbar button {
  border: 0;
  font-size: 1.6rem;
  padding: 0.8rem 1rem;
  background-color: #92bf1f;
}
html body.customer-account-login .columns .column .block-customer-login .block-content .actions-toolbar button:hover,
html body.multishipping-checkout-login .columns .column .block-customer-login .block-content .actions-toolbar button:hover {
  background-color: #067C58;
}
html body.customer-account-login .columns .column .block-customer-login .block-content .actions-toolbar a,
html body.multishipping-checkout-login .columns .column .block-customer-login .block-content .actions-toolbar a {
  color: #067C58;
}
html body.customer-account-login .columns .column .block-customer-login .block-content .actions-toolbar a:hover,
html body.multishipping-checkout-login .columns .column .block-customer-login .block-content .actions-toolbar a:hover {
  color: #000000;
}
html body.customer-account-login .columns .column .block-customer-login .fieldset:after,
html body.multishipping-checkout-login .columns .column .block-customer-login .fieldset:after {
  margin-top: 15px;
  font-size: 0.8rem;
}
html body.catalog-category-view main .category-view {
  margin-bottom: 2rem;
}
html body.catalog-category-view main .category-introduction {
  display: flex;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
@media (min-width: 767px) {
  html body.catalog-category-view main .category-introduction {
    padding: 0.5rem 0;
  }
}
html body.catalog-category-view main .category-introduction .page-title-wrapper {
  flex-shrink: 0;
  margin-right: 2rem;
  margin-bottom: 1rem;
}
@media (min-width: 939px) {
  html body.catalog-category-view main .category-introduction .page-title-wrapper {
    margin-bottom: 0;
  }
}
html body.catalog-category-view main .category-introduction .page-title-wrapper .page-title {
  font-size: 2rem;
  font-weight: normal;
  color: #92bf1f;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}
html body.catalog-category-view main .category-introduction .category-description {
  font-size: 1rem;
  color: #46484a;
  margin-bottom: 0;
}
@media (min-width: 939px) {
  html body.catalog-category-view main .category-introduction .category-description {
    font-size: 0.9rem;
  }
}
html body.catalog-category-view main .category-introduction .category-description p {
  line-height: 1.2;
}
html body.catalog-category-view main .category-introduction .category-description p:last-child {
  margin-bottom: 0;
}
html body.catalog-category-view main .category-introduction .category-description a {
  color: inherit !important;
}
html body.catalog-category-view main .modes {
  display: none;
}
html body.cms-no-route .page-wrapper {
  min-height: 70vh;
}
html body.cms-no-route main {
  padding: 5rem 1.5rem 2rem 1.5rem;
}
html body.cms-no-route main .columns:before,
html body.cms-no-route main .columns:after {
  content: '';
  display: table;
}
html body.cms-no-route main .columns:after {
  clear: both;
}
html body.cms-no-route main .columns .column {
  display: flex;
  align-items: center;
  justify-content: center;
}
html body.cms-no-route main .columns .column h1 {
  margin-bottom: 2rem;
}
html body.cms-no-route main .columns .column a {
  color: #92bf1f;
}
html body.cms-no-route main .columns .column a:hover {
  color: #067C58;
}
html body.cms-no-route main .columns .column .col {
  float: left;
}
@media (min-width: 939px) {
  html body.cms-no-route main .columns .column .md-col-4 {
    padding-right: 3rem;
  }
}
html body.cms-no-route main .columns .column .btn {
  margin-top: 2rem;
  float: left;
}
html body.cms-no-route main .columns .column .btn a {
  color: white;
  padding: 6px 15px;
  margin-right: 15px;
  border-radius: 10px;
  text-transform: capitalize;
  background-color: #92bf1f;
}
html body.cms-no-route main .columns .column .btn a:hover {
  background-color: #067C58;
}
html body.customer-account-create .header.cta-bar {
  display: none;
}
html body.customer-account-create .page-wrapper {
  min-height: 70vh;
  background-size: cover;
  background: #2378bb;
  background-position: top left;
  background-repeat: no-repeat;
  background: linear-gradient(180deg, #2378bb 0%, #7dd6ea 100%);
}
html body.customer-account-create main {
  padding: 3rem 1.5rem 2rem 1.5rem;
}
html body.customer-account-create main .page-title-wrapper {
  padding: 1rem 1rem 0 1rem;
}
html body.customer-account-create main .page-title-wrapper .page-title {
  width: 100%;
  color: white;
  font-size: 2.6rem;
  margin-bottom: 0;
}
html body.customer-account-create main .page-title-wrapper .page-title span {
  text-transform: none;
}
html body.customer-account-create main .column {
  color: white;
  display: flex;
  flex-wrap: wrap;
}
html body.customer-account-create main .column > div {
  padding: 1rem;
}
@media (min-width: 939px) {
  html body.customer-account-create main .column > div {
    max-width: 48%;
  }
}
html body.customer-account-create main .column > div h2 {
  margin-top: 0;
  font-size: 2rem;
}
html body.customer-account-create main .column > div i {
  color: yellow;
  font-size: 2.5rem;
  margin-right: 1rem;
}
html body.customer-account-create main .column > div .signup-points {
  margin-top: 3rem;
}
html body.customer-account-create main .column > div .signup-points div {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
html body.customer-account-create main .column div.registration-footnote-wrapper {
  width: 100%;
  color: #000000;
  max-width: none;
  margin-top: 4rem;
  font-size: 0.9rem;
}
html body.customer-account-create main .form-create-account {
  flex-wrap: wrap;
  width: 100% !important;
}
html body.customer-account-create main .form-create-account fieldset {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  padding: 0 !important;
}
html body.customer-account-create main .form-create-account fieldset:nth-child(1) {
  background-color: red;
}
html body.customer-account-create main .form-create-account fieldset > .field {
  width: 50%;
  float: left;
  margin: auto !important;
  margin-bottom: 0.5rem !important;
}
html body.customer-account-create main .form-create-account fieldset > .field > div {
  padding: 0 0.5rem;
}
html body.customer-account-create main .form-create-account fieldset > .field label {
  display: block;
  margin-bottom: 10px;
}
html body.customer-account-create main .form-create-account fieldset > .field label:after {
  content: '*';
  font-size: 1.2rem;
  margin: 0 0 0 4.5px;
  color: #000000 !important;
}
html body.customer-account-create main .form-create-account fieldset > .field input {
  height: auto;
  padding: 0.5rem;
  font-size: 16px;
  border-radius: 8px;
  padding: 0.8rem 1.2rem;
  border: 2px solid transparent;
}
html body.customer-account-create main .form-create-account fieldset > .field input:focus,
html body.customer-account-create main .form-create-account fieldset > .field input:active {
  outline-offset: 3px;
  outline-width: 1px;
  outline-color: black;
  outline-style: dashed;
}
html body.customer-account-create main .form-create-account fieldset > .field input.mage-error {
  border: 2px solid red;
}
html body.customer-account-create main .form-create-account fieldset > .field.field-name-firstname label,
html body.customer-account-create main .form-create-account fieldset > .field.field-name-lastname label {
  padding: 0 0.5rem;
}
html body.customer-account-create main .form-create-account fieldset > .field.newsletter {
  display: none;
  padding-left: 0.6rem;
}
html body.customer-account-create main .form-create-account fieldset > .field.newsletter label:after {
  content: '';
  display: none !important;
}
html body.customer-account-create main .form-create-account fieldset.create.account .field.required:not(.password):not(.confirmation) {
  width: 100% !important;
}
html body.customer-account-create main .form-create-account fieldset br {
  display: none;
}
html body.customer-account-create main .form-create-account fieldset legend {
  display: none;
}
html body.customer-account-create main .form-create-account fieldset .field {
  margin: 0;
}
html body.customer-account-create main .form-create-account fieldset #password-strength-meter-container .password-strength-meter {
  color: black;
  font-size: 1rem;
  margin-top: 0.5rem;
  border-radius: 8px;
  text-align: center;
  border-radius: 0.8rem;
}
html body.customer-account-create main .form-create-account .actions-toolbar {
  margin-top: 2rem;
}
html body.customer-account-create main .form-create-account .actions-toolbar div {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}
@media (min-width: 939px) {
  html body.customer-account-create main .form-create-account .actions-toolbar div {
    flex-direction: row;
  }
}
html body.customer-account-create main .form-create-account .actions-toolbar button {
  border-radius: 0;
  padding: 2rem 1rem;
  margin-bottom: 0.5rem !important;
  border: 4px solid black;
}
html body.customer-account-create main .form-create-account .actions-toolbar button:first-child {
  background: transparent;
  border-color: #92bf1f;
}
html body.customer-account-create main .form-create-account .actions-toolbar button:first-child:hover {
  background: #000000;
  border-color: #000000;
}
html body.customer-account-create main .form-create-account .actions-toolbar button:last-child {
  color: #000000;
  background: #92bf1f;
  border-color: #92bf1f;
}
html body.customer-account-create main .form-create-account .actions-toolbar button:last-child:hover {
  color: white;
  background: #000000;
  border-color: #000000;
}
html body.customer-account-create main .form-create-account .actions-toolbar button span {
  display: block;
}
html body.customer-account-create main .form-create-account .actions-toolbar button span:first-child {
  font-size: 1.6rem;
  margin-top: 0.5rem;
}
html body.customer-account-create main .form-create-account .actions-toolbar button span:last-child {
  font-size: 1rem;
}
.account main {
  margin-top: 2rem;
}
.account main a,
.account main .block-title a {
  color: #067C58;
}
.account main a:hover,
.account main .block-title a:hover {
  color: #000000;
}
.account main .page-title-wrapper {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #92bf1f;
}
.account main .page-title-wrapper .page-title {
  color: white;
  font-size: 2rem;
  margin-bottom: 0;
}
.account main .account-nav-title {
  display: none;
}
.account main .account-nav-content {
  background: #EFEFEF;
}
.account main .account-nav-content ul li a {
  color: #000000;
}
.account main .account-nav-content ul li a:hover {
  color: #067C58;
}
.account main .account-nav-content ul li span.delimiter {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-top: 1px solid #C1C1C1;
}
.account main .account-nav-content ul li.current {
  background: #e2e2e2;
}
.account main .account-nav-content ul li.current strong {
  color: #067C58;
  border-color: #067C58;
}
.account main .sidebar.sidebar-additional {
  margin-top: 0;
  margin-bottom: 4rem;
}
.account main .sidebar.sidebar-additional .block-compare {
  display: none;
}
.account main .main {
  margin-top: 1rem;
}
.account main .main .block-title {
  padding: 0 1.5rem;
  color: #067C58;
  margin-bottom: 5px !important;
}
.account main .main .block-content {
  display: flex;
}
.account main .main .box {
  margin: 5px;
  padding: 1rem;
  background: #EFEFEF;
}
.account main .main .box .action {
  font-size: 0.9rem;
}
.account main button.primary {
  border: 0;
  font-size: 1.6rem;
  padding: 0.8rem 1rem;
  background-color: #92bf1f;
}
.account main button.primary:hover {
  background-color: #067C58;
}
.account main .orders-recent {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}
.account main .orders-recent a:hover {
  color: #000000;
}
.account main .form-newsletter-manage {
  padding: 0 !important;
}
.account main .form-newsletter-manage .legend {
  padding: 1rem;
  margin-bottom: 2rem;
  background-color: #92bf1f;
}
.account main .form-newsletter-manage .legend > span {
  color: white;
  font-size: 2rem;
  margin-bottom: 0;
  font-weight: 600;
  text-transform: uppercase;
}
.account main .form-newsletter-manage .choice input {
  margin-right: 1rem;
}
.account main .form-newsletter-manage .actions-toolbar .primary button {
  width: 100% !important;
  font-size: 1.2rem !important;
  border-radius: 8px !important;
  border-color: #067C58 !important;
}
.account main .form-newsletter-manage .actions-toolbar .primary button:hover {
  background-color: #000000 !important;
}
.checkout-index-index * {
  box-sizing: border-box;
}
.checkout-index-index a {
  color: #067C58;
}
.checkout-index-index form .field.required > label,
.checkout-index-index form .field._required > label {
  position: relative;
  padding-right: 10px;
}
.checkout-index-index form .field.required > label:after,
.checkout-index-index form .field._required > label:after {
  top: 0;
  right: 0;
}
.checkout-index-index .checkout-container {
  margin: 3rem 0;
}
.checkout-index-index .checkout-container .authentication-wrapper {
  display: none;
}
.checkout-index-index .checkout-container .opc-progress-bar .opc-progress-bar-item > span {
  font-size: 1.4rem;
  font-weight: bold;
}
.checkout-index-index .checkout-container .opc-progress-bar .opc-progress-bar-item > span:after {
  line-height: 1.5;
  font-size: 1.4rem;
}
.checkout-index-index .checkout-container .opc-progress-bar .opc-progress-bar-item._active:before,
.checkout-index-index .checkout-container .opc-progress-bar .opc-progress-bar-item._complete:before {
  background: #067C58;
}
.checkout-index-index .checkout-container .opc-progress-bar .opc-progress-bar-item._active > span:before,
.checkout-index-index .checkout-container .opc-progress-bar .opc-progress-bar-item._complete > span:before {
  background: #067C58;
}
.checkout-index-index .checkout-container .opc-progress-bar .opc-progress-bar-item._active > span:after,
.checkout-index-index .checkout-container .opc-progress-bar .opc-progress-bar-item._complete > span:after {
  font-size: 2.2rem;
  line-height: 1.05;
  color: #067C58;
}
.checkout-index-index .checkout-container .opc-estimated-wrapper .estimated-price {
  font-size: 2rem;
  color: #067C58;
}
.checkout-index-index .checkout-container #shipping {
  margin-top: 2rem;
}
.checkout-index-index .checkout-container #shipping .step-title {
  font-weight: 600;
  font-size: 1.6rem;
  padding: 10px 20px;
  background: #EFEFEF;
  text-transform: uppercase;
}
.checkout-index-index .checkout-container #shipping .step-title:before {
  content: "1. ";
}
.checkout-index-index .checkout-container #shipping #checkout-step-shipping form {
  padding: 0 20px;
  max-width: none;
}
.checkout-index-index .checkout-container #shipping #checkout-step-shipping form.form-login {
  padding: 20px;
  background: #def7ea;
}
.checkout-index-index .checkout-container #shipping #checkout-step-shipping form.form-login .ml-login-title {
  display: block;
  font-weight: bold;
  font-size: 1.6rem;
  margin: 0 0 16px 0;
  color: #067C58;
}
.checkout-index-index .checkout-container #shipping #checkout-step-shipping form.form-login .ml-login-content {
  margin: 0 0 26px 0;
}
.checkout-index-index .checkout-container #shipping #checkout-step-shipping form.form-login .ml-login-end {
  display: block;
  font-size: 1.4rem;
  color: #067C58;
}
.checkout-index-index .checkout-container #shipping #checkout-step-shipping form.form-login #customer-email-fieldset {
  margin: 0 0 16px;
}
.checkout-index-index .checkout-container #shipping #checkout-step-shipping form.form-login input {
  width: 100%;
}
.checkout-index-index .checkout-container #shipping #checkout-step-shipping form.form-login .validator-email {
  padding-right: 4rem;
}
.checkout-index-index .checkout-container #shipping #checkout-step-shipping form.form-login .note {
  display: none;
}
.checkout-index-index .checkout-container #shipping #checkout-step-shipping form.form-login .field-tooltip > span > span {
  display: none;
}
.checkout-index-index .checkout-container #shipping #checkout-step-shipping form.form-login .actions-toolbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.checkout-index-index .checkout-container #shipping #checkout-step-shipping form.form-login .actions-toolbar button.primary {
  border: 0;
  color: white;
  min-width: 160px;
  font-weight: bold;
  border-radius: 8px;
  text-transform: none;
  padding: 0.8rem 4rem;
  background: #067C58;
  text-transform: uppercase;
}
.checkout-index-index .checkout-container #shipping #checkout-step-shipping form.form-login .actions-toolbar button.primary:hover {
  background-color: #92bf1f;
}
.checkout-index-index .checkout-container #shipping #checkout-step-shipping form.form-login .actions-toolbar .secondary {
  width: auto;
  margin-left: 2rem;
}
.checkout-index-index .checkout-container #shipping #checkout-step-shipping form.form-login .actions-toolbar .secondary a {
  margin: 0;
}
.checkout-index-index .checkout-container #shipping #checkout-step-shipping form.form-login .actions-toolbar .secondary a:hover {
  color: #000000;
}
.checkout-index-index .checkout-container .checkout-billing-address .billing-address-form #tooltip-label span {
  display: none;
}
.checkout-index-index .checkout-container .checkout-billing-address .billing-address-form form > .fieldset > .field {
  width: 100%;
}
@media (min-width: 939px) {
  .checkout-index-index .checkout-container .checkout-billing-address .billing-address-form form > .fieldset > .field:nth-child(1) {
    width: 50%;
  }
  .checkout-index-index .checkout-container .checkout-billing-address .billing-address-form form > .fieldset > .field:nth-child(2) {
    width: 50%;
  }
  .checkout-index-index .checkout-container .checkout-billing-address .billing-address-form form > .fieldset > .field:nth-child(4) {
    width: 100%;
  }
  .checkout-index-index .checkout-container .checkout-billing-address .billing-address-form form > .fieldset > .field:nth-child(5) {
    width: 100%;
  }
  .checkout-index-index .checkout-container .checkout-billing-address .billing-address-form form > .fieldset > .field:nth-child(6) {
    width: 50%;
  }
  .checkout-index-index .checkout-container .checkout-billing-address .billing-address-form form > .fieldset > .field:nth-child(7) {
    width: 50%;
  }
  .checkout-index-index .checkout-container .checkout-billing-address .billing-address-form form > .fieldset > .field:nth-child(8) {
    width: 50%;
  }
  .checkout-index-index .checkout-container .checkout-billing-address .billing-address-form form > .fieldset > .field:nth-child(9) {
    width: 50%;
  }
  .checkout-index-index .checkout-container .checkout-billing-address .billing-address-form form > .fieldset > .field:nth-child(10) {
    width: 50%;
  }
  .checkout-index-index .checkout-container .checkout-billing-address .billing-address-form form > .fieldset > .field:nth-child(11) {
    width: 50%;
  }
}
.checkout-index-index .checkout-container #shipping-new-address-form {
  display: flex;
  flex-wrap: wrap;
}
.checkout-index-index .checkout-container #shipping-new-address-form .field-tooltip > span > span {
  display: none;
}
.checkout-index-index .checkout-container #shipping-new-address-form > .field {
  width: 100%;
}
@media (min-width: 939px) {
  .checkout-index-index .checkout-container #shipping-new-address-form > .field:nth-child(1) {
    width: 50%;
  }
  .checkout-index-index .checkout-container #shipping-new-address-form > .field:nth-child(2) {
    width: 50%;
  }
  .checkout-index-index .checkout-container #shipping-new-address-form > .field:nth-child(4) {
    width: 100%;
  }
  .checkout-index-index .checkout-container #shipping-new-address-form > .field:nth-child(5) {
    width: 100%;
  }
  .checkout-index-index .checkout-container #shipping-new-address-form > .field:nth-child(6) {
    width: 50%;
  }
  .checkout-index-index .checkout-container #shipping-new-address-form > .field:nth-child(7) {
    width: 50%;
  }
  .checkout-index-index .checkout-container #shipping-new-address-form > .field:nth-child(8) {
    width: 50%;
  }
  .checkout-index-index .checkout-container #shipping-new-address-form > .field:nth-child(9) {
    width: 50%;
  }
  .checkout-index-index .checkout-container #shipping-new-address-form > .field:nth-child(10) {
    width: 50%;
  }
  .checkout-index-index .checkout-container #shipping-new-address-form > .field:nth-child(11) {
    width: 50%;
  }
}
.checkout-index-index .checkout-container #opc-shipping_method .step-title {
  font-weight: 600;
  font-size: 1.6rem;
  padding: 10px 20px;
  background: #EFEFEF;
  text-transform: uppercase;
}
.checkout-index-index .checkout-container #opc-shipping_method .step-title:before {
  content: "2. ";
}
.checkout-index-index .checkout-container #opc-shipping_method #checkout-step-shipping_method {
  padding: 0 20px;
}
.checkout-index-index .checkout-container #opc-shipping_method #checkout-step-shipping_method table {
  width: 100%;
  max-width: none;
}
.checkout-index-index .checkout-container .opc-block-summary {
  padding: 10px 20px 20px;
  background: #EFEFEF;
}
.checkout-index-index .checkout-container .opc-block-summary .title {
  border: 0;
  cursor: auto;
  font-weight: 600;
  font-size: 1.6rem;
  padding: 0 0 10px 0;
  background: #EFEFEF;
  text-transform: uppercase;
}
.checkout-index-index .checkout-container .opc-block-summary .title:after {
  display: none;
}
.checkout-index-index .checkout-container .opc-block-summary .items-in-cart .title {
  padding: 10px 0;
  text-align: right;
  text-transform: none;
  border-top: 1px solid #C1C1C1;
  border-bottom: 1px solid #C1C1C1;
}
.checkout-index-index .checkout-container .opc-block-summary .items-in-cart .title strong {
  font-size: 1.2rem;
  font-weight: bold;
}
.checkout-index-index .checkout-container .opc-block-summary .items-in-cart .content {
  display: block !important;
}
.checkout-index-index .checkout-container .opc-block-summary .items-in-cart .content ol.minicart-items li.product-item .product-item-details {
  padding-left: 88px !important;
}
.checkout-index-index .checkout-container .opc-block-summary .items-in-cart .content ol.minicart-items li.product-item .product-item-details .product-item-name-block {
  padding-right: 16px;
}
.checkout-index-index .checkout-container .opc-block-summary .items-in-cart .content ol.minicart-items li.product-item .product-item-details .product-item-name-block .product-item-name {
  margin-bottom: 0;
}
.checkout-index-index .checkout-container .opc-block-summary .items-in-cart .content ol.minicart-items li.product-item .product-item-details .product-item-name-block .details-qty {
  margin-top: 4px;
  font-size: 1rem;
  font-weight: bold;
}
.checkout-index-index .checkout-container .opc-block-summary .items-in-cart .content ol.minicart-items li.product-item .product-item-details .cart-price {
  color: #067C58;
}
.checkout-index-index .checkout-container .opc-block-summary .items-in-cart .content ol.minicart-items li.product-item .product-item-details .cart-price > span {
  font-weight: bold;
}
.checkout-index-index .checkout-container .opc-block-summary .items-in-cart .content ol.minicart-items li.product-item .product-image-container {
  margin: 0;
  width: 100%;
  max-width: 77px;
}
.checkout-index-index .checkout-container .opc-block-summary .items-in-cart .content ol.minicart-items li.product-item .product-image-container img {
  width: auto;
  height: auto;
}
.checkout-index-index .checkout-container .opc-block-shipping-information {
  margin: 2rem -12px 0;
  background: #EFEFEF;
}
.checkout-index-index .checkout-container .opc-block-shipping-information > div {
  padding: 10px 20px 20px;
}
.checkout-index-index .checkout-container .opc-block-shipping-information .ship-via {
  padding-top: 2rem;
  border-top: 1px solid #C1C1C1;
}
.checkout-index-index .checkout-container .opc-block-shipping-information .shipping-information-title,
.checkout-index-index .checkout-container .opc-block-shipping-information .shipping-information-title {
  border: 0;
  cursor: auto;
  font-weight: 600;
  font-size: 1.6rem;
  padding: 0 0 10px 0;
  text-transform: uppercase;
}
.checkout-index-index .checkout-container .actions-toolbar {
  margin: 1rem 0 0 0;
}
.checkout-index-index .checkout-container .actions-toolbar button.continue {
  border: 0;
  color: white;
  min-width: 160px;
  font-weight: bold;
  border-radius: 8px;
  text-transform: none;
  padding: 0.8rem 4rem;
  background: #067C58;
  text-transform: uppercase;
}
.checkout-index-index .checkout-container .actions-toolbar button.continue:hover {
  background-color: #92bf1f;
}
.checkout-index-index .checkout-container #checkout-payment-method-load button.checkout {
  border: 0;
  color: white;
  min-width: 160px;
  font-weight: bold;
  border-radius: 8px;
  text-transform: none;
  padding: 0.8rem 4rem;
  background: #067C58;
  text-transform: uppercase;
}
.checkout-index-index .checkout-container #checkout-payment-method-load button.checkout:hover {
  background-color: #92bf1f;
}
.checkout-index-index #stripe-payments-card-number {
  width: 240px;
}
.catalog-product_compare-index main {
  margin-top: 1rem;
}
.catalog-product_compare-index main .page-title-wrapper {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #92bf1f;
}
.catalog-product_compare-index main .page-title-wrapper .page-title {
  width: 100%;
  color: white;
  font-size: 2rem;
  margin-bottom: 0;
  text-align: center;
}
.catalog-product_compare-index main .print {
  float: right;
  line-height: 1;
  font-size: 0.9rem;
}
.catalog-product_compare-index main .table-comparison {
  margin-top: 2rem;
}
.catalog-product_compare-index main .table-comparison tbody tr {
  border-bottom: 1px solid #EFEFEF;
}
.catalog-product_compare-index main .table-comparison tbody tr td.product .product-item-photo img {
  margin: 0 !important;
}
.catalog-product_compare-index main .table-comparison tbody tr td.product .product-item-name a {
  line-height: 1;
  font-size: 1.5rem;
  color: #067C58;
}
.catalog-product_compare-index main .table-comparison tbody tr td.product .product-item-name a a {
  color: #000000;
}
.catalog-product_compare-index main .table-comparison tbody tr td.product .product-reviews-summary {
  display: flex;
  margin: 0 auto;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
@media (min-width: 700px) {
  .catalog-product_compare-index main .table-comparison tbody tr td.product .product-reviews-summary {
    flex-direction: row;
  }
}
.catalog-product_compare-index main .table-comparison tbody tr td.product .product-reviews-summary .rating-summary .rating-result > span:before {
  color: #FFCC00;
}
.catalog-product_compare-index main .table-comparison tbody tr td.product .product-reviews-summary .reviews-actions {
  margin: 0;
  padding: 0;
  display: inline !important;
}
.catalog-product_compare-index main .table-comparison tbody tr td.product .product-reviews-summary .reviews-actions a {
  color: #000000;
}
.catalog-product_compare-index main .table-comparison tbody tr td.product .price-box .price {
  font-weight: bold;
}
.catalog-product_compare-index main .table-comparison tbody tr td.product .actions-primary form button.primary {
  border: 0;
  width: 100%;
  color: white;
  display: block;
  font-size: 1.2rem;
  margin-right: 1rem;
  padding: 0.8rem 1rem;
  text-transform: none;
  margin-bottom: 0.5rem;
  background-color: #067C58;
}
@media (min-width: 1200px) {
  .catalog-product_compare-index main .table-comparison tbody tr td.product .actions-primary form button.primary {
    width: auto;
    margin-bottom: 0;
    display: inline-block;
  }
}
.catalog-product_compare-index main .table-comparison tbody tr td.product .actions-primary form button.primary:hover {
  background-color: #000000;
}
body.catalog-product-view .columns {
  margin-top: 2rem;
}
body.catalog-product-view .page-title-wrapper .page-title {
  color: #067C58;
  text-transform: none;
}
body.catalog-product-view .product-reviews-summary {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: flex-start;
}
@media (min-width: 700px) {
  body.catalog-product-view .product-reviews-summary {
    flex-direction: row;
  }
}
body.catalog-product-view .product-reviews-summary .rating-summary .rating-result > span:before {
  color: #FFCC00;
}
body.catalog-product-view .product-reviews-summary .reviews-actions {
  margin: 0;
  padding: 0;
  display: inline !important;
}
body.catalog-product-view .product-reviews-summary .reviews-actions a.view {
  color: #46484a;
  font-weight: bold;
}
body.catalog-product-view .product-reviews-summary .reviews-actions a.add {
  display: none;
  color: #067C58;
}
@media (min-width: 939px) {
  body.catalog-product-view .product-reviews-summary .reviews-actions a.add {
    display: inline;
  }
}
body.catalog-product-view .product-info-main .overview {
  margin-top: 0 !important;
  border: none !important;
}
body.catalog-product-view .product-info-main .overview .read-more {
  font-size: 14px;
  color: #067C58;
}
body.catalog-product-view .product-info-main .overview .read-more:after {
  content: "...";
}
body.catalog-product-view .product-reviews-summary {
  margin-top: 1rem;
}
body.catalog-product-view .product-info-price {
  display: none;
}
body.catalog-product-view .product-info-price .product-info-stock-sku .stock {
  margin-top: 0;
  margin-bottom: 0;
}
body.catalog-product-view .product-info-price .product-info-stock-sku .stock.available {
  background: #067C58;
}
body.catalog-product-view .product-info-price .product-info-stock-sku .stock.available {
  background: #067C58;
}
body.catalog-product-view .minimal-price {
  display: none !important;
}
body.catalog-product-view .box-tocart {
  margin-top: 0;
}
body.catalog-product-view .box-tocart > div {
  margin: 0;
  padding: 0;
  width: 100%;
}
body.catalog-product-view .box-tocart > div > div {
  margin: 0;
  width: 100%;
  padding: 0 !important;
  display: block !important;
}
body.catalog-product-view .box-tocart > div > div button {
  font-weight: bold;
  border-radius: 10px !important;
  width: 100% !important;
}
body.catalog-product-view .underprice-wrapper {
  display: flex;
  margin: 2rem 0 4rem 0;
}
@media (max-width: 939px) {
  body.catalog-product-view .underprice-wrapper {
    flex-direction: column;
  }
}
body.catalog-product-view .underprice-wrapper .underprice.item {
  width: 50%;
  display: flex;
  align-items: center;
  max-width: 49.999994%;
  justify-content: center;
}
@media (max-width: 939px) {
  body.catalog-product-view .underprice-wrapper .underprice.item {
    width: 100%;
    max-width: 100%;
    justify-content: center;
  }
}
body.catalog-product-view .underprice-wrapper .underprice.item .underprice_icon-wrapper {
  width: 84px;
  display: flex;
  justify-content: center;
}
body.catalog-product-view .underprice-wrapper .underprice.item .underprice_content-wrapper {
  width: 200px;
}
@media (min-width: 939px) {
  body.catalog-product-view .underprice-wrapper .underprice.item .underprice_content-wrapper {
    width: auto;
  }
}
body.catalog-product-view .underprice-wrapper .underprice.item > div {
  display: flex;
  padding: 1rem;
}
body.catalog-product-view .block.related {
  position: relative;
  background: #EFEFEF;
}
body.catalog-product-view .block.related .page-title-main {
  z-index: 1;
  margin-bottom: 0;
  position: relative;
  margin-bottom: 3rem;
  text-transform: none;
}
body.catalog-product-view .block.related .page-title-main:before {
  left: 0;
  top: 50%;
  right: 0;
  height: 1px;
  content: "";
  position: absolute;
  background-color: #2CAA66;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
body.catalog-product-view .block.related .page-title-main strong {
  margin: 0;
  border: none;
  color: #2CAA66;
  font-size: inherit;
  position: relative;
  padding: 15px 40px;
  text-transform: none;
  display: inline-block;
  background: #EFEFEF;
  font-size: 2rem !important;
}
body.catalog-product-view .block.related:before,
body.catalog-product-view .block.related:after {
  top: 0;
  bottom: 0;
  content: "";
  width: 100%;
  position: absolute;
  background: #EFEFEF;
}
body.catalog-product-view .block.related:before {
  left: -100%;
  background: #EFEFEF;
}
body.catalog-product-view .block.related:after {
  right: -100%;
  background: #EFEFEF;
}
body.catalog-product-view .block.related .products-related .product-img-main .product-item-inner {
  display: none;
}
body.catalog-product-view .block.related .products-related .product-item-details .product-item-name a {
  font-weight: bold;
  color: #067C58;
}
body.catalog-product-view .block.related .products-related .product-item-details .product-item-name {
  height: 48px;
  display: flex;
  overflow: hidden;
  justify-content: center;
}
body.catalog-product-view .block.related .products-related .product-item-details .product-reviews-summary {
  display: flex;
  align-items: center;
  justify-content: center;
}
body.catalog-product-view .block.related .products-related .product-item-details .product-reviews-summary .reviews-actions {
  margin: 0;
  line-height: 1;
  display: block !important;
}
body.catalog-product-view .block.related .products-related .product-item-details .product-reviews-summary .view {
  color: #46484a;
  font-weight: bold;
}
body.catalog-product-view .block.related .products-related .product-item-details .product-reviews-summary .add {
  display: none;
}
body.catalog-product-view .block.related .products-related .product-item-details .product-star-rating {
  height: 26px;
  margin-top: 1rem;
  overflow: hidden;
}
body.catalog-product-view .block.related .products-related .product-item-details .product-star-rating .rating-summary .rating-result > span:before {
  color: #FFCC00;
}
body.catalog-product-view .block.related .products-related .product-item-details .product-star-rating .rating-summary .rating-result > span:before span {
  display: inline;
  color: #46484a;
}
body.catalog-product-view .block.related .products-related .product-item-details .price-box {
  height: 50px;
  display: flex;
  overflow: hidden;
  margin: 10px 0 0 0;
  align-items: center;
  justify-content: center;
}
body.catalog-product-view .block.related .products-related .product-item-details .price-box .price-label {
  display: none;
}
body.catalog-product-view .block.related .products-related .product-item-details .price-box .price {
  font-size: 1.8rem;
  color: #067C58;
}
body.catalog-product-view .block.related .products-related .product-item-details .price-box .price:before {
  color: #000000;
  display: block;
  font-size: 1rem;
  font-weight: normal;
  content: "Prices from:";
}
body.catalog-product-view .block.related .products-related .product-item-details .product-item-inner .product-item-actions {
  padding: 0;
  margin-top: 20px;
}
body.catalog-product-view .block.related .products-related .product-item-details .product-item-inner .product-item-actions a {
  border: 0;
  color: white;
  font-weight: 800;
  border-radius: 8px;
  text-transform: none;
  padding: 0.8rem 2rem;
  background-color: #92bf1f;
}
body.catalog-product-view .block.related .products-related .product-item-details .product-item-inner .product-item-actions a:hover {
  background-color: #067C58;
}
body.catalog-product-view .product.info.detailed {
  padding: 3rem 0;
}
body.catalog-product-view .product.info.detailed .rating-label {
  width: 80px;
  display: inline-block;
}
body.catalog-product-view .product.info.detailed .rating-result > span:before {
  color: #FFCC00;
}
body.catalog-product-view .product.info.detailed .review-control-vote label:before {
  color: #FFCC00;
}
body.catalog-product-view .product.info.detailed .fieldset .review-legend.legend strong {
  color: #067C58;
}
@media (max-width: 939px) {
  .table-wrapper.grouped table tr {
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex !important;
    border: 1px solid #C1C1C1;
    justify-content: space-between;
  }
}
.table-wrapper.grouped table tr.product-oos .amxnotif-block,
.table-wrapper.grouped table tr.product-oos .link-stock-alert {
  display: none;
}
@media (max-width: 939px) {
  .table-wrapper.grouped table tr.product-oos.ready {
    padding: 1rem;
    margin-bottom: 0;
    border-bottom: none;
    display: flex !important;
    justify-content: space-between;
  }
}
@media (max-width: 939px) {
  .table-wrapper.grouped table tr.oos-row {
    padding: 0 0.5rem;
    border-top: none;
    border-left: 1px solid #C1C1C1;
    border-right: 1px solid #C1C1C1;
    border-bottom: 1px solid #C1C1C1;
  }
  .table-wrapper.grouped table tr.oos-row td {
    width: 100%;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
.table-wrapper.grouped table tr.oos-row td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.table-wrapper.grouped table tr.oos-row .amxnotif-block {
  margin-bottom: 0;
}
.table-wrapper.grouped table tr.oos-row .amxnotif-block label {
  cursor: pointer;
  font-size: 1rem;
  color: #067C58;
  font-weight: normal;
  margin-bottom: 0.7rem;
}
.table-wrapper.grouped table tr.oos-row .amxnotif-block label:after {
  content: "?";
  margin-left: -3px;
}
.table-wrapper.grouped table tr.oos-row .amxnotif-block .notification-container {
  padding: 10px 10px;
  border: 1px solid #EFEFEF;
}
@media (max-width: 939px) {
  .table-wrapper.grouped table tr.oos-row .amxnotif-block .notification-container {
    border: none;
    padding: 0 0 10px 0;
  }
}
.table-wrapper.grouped table tr.oos-row .amxnotif-block .notification-container .input-fields {
  width: 69%;
}
@media (max-width: 939px) {
  .table-wrapper.grouped table tr.oos-row .amxnotif-block .notification-container .input-fields {
    width: 65%;
  }
}
@media (max-width: 460px) {
  .table-wrapper.grouped table tr.oos-row .amxnotif-block .notification-container .input-fields {
    width: 100%;
  }
}
.table-wrapper.grouped table tr.oos-row .amxnotif-block .notification-container input {
  width: 100%;
  height: 39px;
  border-radius: 5px;
}
.table-wrapper.grouped table tr.oos-row .amxnotif-block .notification-container .amxnotif_guest_action {
  width: 30%;
}
@media (max-width: 460px) {
  .table-wrapper.grouped table tr.oos-row .amxnotif-block .notification-container .amxnotif_guest_action {
    width: 100%;
    margin: 5px 0 0 0;
  }
}
.table-wrapper.grouped table tr.oos-row .amxnotif-block .notification-container .amxnotif_guest_action .primary {
  width: 100%;
}
.table-wrapper.grouped table tr.oos-row .amxnotif-block .notification-container .amxnotif_guest_action button {
  width: 100%;
  border-color: #067C58;
}
.table-wrapper.grouped table tr.oos-row .link-stock-alert {
  margin: 0 0 7px 0;
}
.table-wrapper.grouped table tr.oos-row .link-stock-alert a {
  cursor: pointer;
  font-size: 1rem;
  color: #067C58;
  font-weight: normal;
  margin-bottom: 0.7rem;
}
.table-wrapper.grouped table th.price,
.table-wrapper.grouped table td.price {
  width: 75px;
  max-width: 75px;
  font-weight: bold;
  color: #067C58;
}
@media (max-width: 939px) {
  .table-wrapper.grouped table th.price,
  .table-wrapper.grouped table td.price {
    display: none !important;
  }
}
.table-wrapper.grouped table th.price .link-stock-alert,
.table-wrapper.grouped table td.price .link-stock-alert {
  display: none;
}
.table-wrapper.grouped table th.price .price-box,
.table-wrapper.grouped table td.price .price-box {
  display: inline-block;
}
.table-wrapper.grouped table th.price .special-price,
.table-wrapper.grouped table td.price .special-price {
  margin: 0;
  padding: 0;
}
.table-wrapper.grouped table th.price .product-salable,
.table-wrapper.grouped table td.price .product-salable {
  display: none;
}
.table-wrapper.grouped table th.price .old-price,
.table-wrapper.grouped table td.price .old-price {
  display: block;
  margin-top: -10px;
}
.table-wrapper.grouped table th.price .old-price .price,
.table-wrapper.grouped table td.price .old-price .price {
  font-size: 14px;
  color: #EC615E;
  position: relative;
}
.table-wrapper.grouped table th.price .old-price .price:before,
.table-wrapper.grouped table td.price .old-price .price:before {
  top: 50%;
  content: "";
  height: 2px;
  width: 100%;
  display: block;
  position: absolute;
  background: #EC615E;
}
.table-wrapper.grouped table td.item {
  display: flex;
  flex-direction: column;
}
@media (max-width: 939px) {
  .table-wrapper.grouped table td.item {
    margin: 0;
    padding: 0 !important;
    max-width: 60%;
  }
}
.table-wrapper.grouped table td.item strong {
  display: block;
}
@media (max-width: 939px) {
  .table-wrapper.grouped table td.item strong {
    font-size: 18px;
  }
}
.table-wrapper.grouped table td.item .priceper {
  display: block;
  font-size: 12px;
}
.table-wrapper.grouped table td.qty {
  width: 120px;
  max-width: 110px;
  text-align: center;
}
@media (max-width: 939px) {
  .table-wrapper.grouped table td.qty {
    max-width: none;
    display: flex !important;
    flex-direction: column;
    padding: 1rem !important;
    justify-content: flex-end;
  }
}
.table-wrapper.grouped table td.qty .mobileprice {
  display: none;
}
@media (max-width: 939px) {
  .table-wrapper.grouped table td.qty .mobileprice {
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;
  }
}
.table-wrapper.grouped table td.qty .mobileprice .price-box {
  display: inline-block;
}
.table-wrapper.grouped table td.qty .mobileprice .price {
  color: #067C58;
  font-weight: bold;
}
.table-wrapper.grouped table td.qty .mobileprice .special-price {
  margin: 0;
  padding: 0;
}
.table-wrapper.grouped table td.qty .mobileprice .old-price {
  display: block;
  margin-top: -10px;
}
.table-wrapper.grouped table td.qty .mobileprice .old-price .price {
  font-size: 14px;
  color: #EC615E;
  position: relative;
}
.table-wrapper.grouped table td.qty .mobileprice .old-price .price:before {
  top: 50%;
  content: "";
  height: 2px;
  width: 100%;
  display: block;
  position: absolute;
  background: #EC615E;
}
.table-wrapper.grouped table td.qty .mobileprice .product-salable .fa-check {
  color: #92bf1f;
}
.table-wrapper.grouped table td.qty .mobileprice .product-salable .fa-times {
  color: #EC615E;
}
.table-wrapper.grouped table th.salable,
.table-wrapper.grouped table td.salable {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 939px) {
  .table-wrapper.grouped table th.salable,
  .table-wrapper.grouped table td.salable {
    display: none !important;
  }
}
.table-wrapper.grouped table th.salable .product-salable,
.table-wrapper.grouped table td.salable .product-salable {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-wrapper.grouped table th.salable .product-salable .fa-check,
.table-wrapper.grouped table td.salable .product-salable .fa-check {
  color: #92bf1f;
}
.table-wrapper.grouped table th.salable .product-salable .fa-times,
.table-wrapper.grouped table td.salable .product-salable .fa-times {
  color: #EC615E;
}
.table-wrapper.grouped table thead th {
  font-weight: bold;
  color: #067C58;
  border-bottom: 2px solid #067C58;
}
.table-wrapper.grouped table thead th.qty {
  text-align: center;
}
@media (max-width: 939px) {
  .table-wrapper.grouped table thead {
    display: none;
  }
}
.table-wrapper.grouped table tbody .item {
  display: flex;
}
.table-wrapper.grouped table tbody .item .product-item-name {
  font-weight: bold;
}
.table-wrapper.grouped table tbody td:before {
  display: none !important;
}
#maincontent .product-add-form .box-tocart thead th {
  font-weight: bold;
  color: #067C58;
  border-bottom: 2px solid #067C58;
}
#maincontent .product-add-form .box-tocart thead th:nth-child(n+2) {
  text-align: center;
}
#maincontent .product-add-form .box-tocart tbody tr td:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
}
#maincontent .product-add-form .box-tocart tbody tr td .price-box {
  color: #067C58;
  display: inline-block;
}
#maincontent .product-add-form .box-tocart tbody tr td .special-price {
  margin: 0;
  padding: 0;
}
#maincontent .product-add-form .box-tocart tbody tr td .product-salable {
  display: none;
}
#maincontent .product-add-form .box-tocart tbody tr td .old-price {
  margin-top: -10px;
  display: inline-block;
}
#maincontent .product-add-form .box-tocart tbody tr td .old-price .price {
  font-size: 14px;
  color: #EC615E;
  position: relative;
}
#maincontent .product-add-form .box-tocart tbody tr td .old-price .price:before {
  top: 50%;
  content: "";
  height: 2px;
  width: 100%;
  display: block;
  position: absolute;
  background: #EC615E;
}
#maincontent .product-add-form .box-tocart tbody tr td .product-salable {
  display: flex;
  align-items: center;
  justify-content: center;
}
#maincontent .product-add-form .box-tocart tbody tr td .product-salable .fa-check {
  color: #92bf1f;
}
#maincontent .product-add-form .box-tocart tbody tr td .product-salable .fa-times {
  color: #EC615E;
}
.product-feeder-icons {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.product-feeder-icons > img {
  max-width: 50px;
  padding: 8px;
}
@media (min-width: 939px) {
  .product-feeder-icons > img {
    max-width: none;
  }
}
.contact-index-index main {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.contact-index-index main .page-title-wrapper {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #92bf1f;
}
.contact-index-index main .page-title-wrapper .page-title {
  color: white;
  font-size: 2rem;
  margin-bottom: 0;
}
.contact-index-index main .columns .column.main {
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
}
@media (min-width: 1081px) {
  .contact-index-index main .columns .column.main {
    flex-direction: row-reverse;
  }
}
.contact-index-index main .columns .column.main > div {
  padding: 0 3rem 0 0;
}
.contact-index-index main fieldset .legend {
  margin-bottom: 0;
  font-weight: bold;
  color: #067C58;
}
.contact-index-index main input,
.contact-index-index main textarea {
  height: auto;
  padding: 0.5rem;
  font-size: 16px;
  border-radius: 8px;
  background: #EFEFEF;
  padding: 0.8rem 1.2rem;
  border: 2px solid transparent;
}
.contact-index-index main input:focus,
.contact-index-index main textarea:focus,
.contact-index-index main input:active,
.contact-index-index main textarea:active {
  outline-offset: 3px;
  outline-width: 1px;
  outline-color: black;
  outline-style: dashed;
}
.contact-index-index main button {
  border: 0;
  border-radius: 0;
  font-size: 1.4rem;
  font-weight: normal;
  text-transform: none;
  padding: 1rem 6rem !important;
  background: #067C58 !important;
  margin-bottom: 0.5rem !important;
}
.contact-index-index main button:hover {
  background: #000000 !important;
}
.contact-index-index main button span {
  display: block;
}
.cms-faqs main {
  margin-bottom: 2rem;
}
.cms-faqs main a {
  color: #067C58 !important;
}
.cms-faqs main h2 {
  color: white;
  padding: 1rem;
  font-size: 2rem;
  margin-bottom: 2rem;
  background-color: #92bf1f;
}
.cms-faqs main ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  justify-content: flex-start;
}
.cms-faqs main ul li {
  padding: 8px;
  margin: 0 !important;
}
.cms-faqs main ul li a {
  font-size: 1.1rem;
  color: #46484a;
  font-weight: bold;
  margin-left: -15px;
  border-radius: 10px;
  display: inline-block;
  background: #EFEFEF;
  padding: 0.8rem 1.4rem;
}
.cms-faqs main ul li a:hover {
  color: white !important;
  background: #067C58;
}
.cms-faqs main details {
  padding: 0 1rem;
}
.cms-faqs main details summary {
  margin-bottom: 1rem;
}
.cms-faqs main .button {
  font-weight: bold;
  border-radius: 10px;
  display: inline-block;
  padding: 0.8rem 1.4rem;
  background: #92bf1f;
  text-transform: uppercase;
  color: white !important;
}
.cms-faqs main .button:hover {
  background: #067C58;
}
.page-products .toolbar {
  padding: 8px;
  display: flex;
  margin-bottom: 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #EFEFEF;
}
@media (min-width: 450px) {
  .page-products .toolbar {
    display: block;
    padding: 0 1.5rem;
    background: transparent;
  }
}
.page-products .toolbar .toolbar-amount {
  padding: 0;
  float: left;
}
@media (min-width: 450px) {
  .page-products .toolbar .toolbar-amount {
    margin-right: 2rem;
  }
}
.page-products .toolbar .toolbar-sorter {
  padding: 0;
  float: right;
  margin-left: 2rem;
}
.page-products .products-grid .product-item {
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 1rem;
  margin-bottom: 3rem;
  width: 100% !important;
  margin-left: auto !important;
  transition: all 0.5s ease-in-out linear;
}
@media (min-width: 460px) {
  .page-products .products-grid .product-item {
    width: 50% !important;
  }
}
@media (min-width: 939px) {
  .page-products .products-grid .product-item {
    width: 33.3335% !important;
  }
  .page-products .products-grid .product-item:hover {
    background: #f7f7f7;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
  }
}
.page-products .products-grid .product-item .product-item-info {
  width: auto;
  margin-left: auto;
  margin-right: auto;
}
.page-products .products-grid .product-item .product-item-info .product-img-main {
  height: 235px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-products .products-grid .product-item .product-item-info .product-img-main .product-item-actions {
  display: none;
}
.page-products .products-grid .product-item .product-item-info .product-item-details {
  padding: 0 1rem;
}
.page-products .products-grid .product-item .product-item-info .product-item-details .product-reviews-summary {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
@media (min-width: 700px) {
  .page-products .products-grid .product-item .product-item-info .product-item-details .product-reviews-summary {
    flex-direction: row;
  }
}
.page-products .products-grid .product-item .product-item-info .product-item-details .product-reviews-summary .rating-summary .rating-result > span:before {
  color: #FFCC00;
}
.page-products .products-grid .product-item .product-item-info .product-item-details .product-reviews-summary .reviews-actions {
  margin: 0;
  padding: 0;
  line-height: 1;
  display: inline !important;
}
.page-products .products-grid .product-item .product-item-info .product-item-details .product-reviews-summary .reviews-actions a {
  color: #000000;
}
.page-products .products-grid .product-item .product-item-info .product-item-details .product-item-inner {
  margin-top: 1rem;
}
.page-products .products-grid .product-item .product-item-info .product-item-details .product-item-inner .product-item-actions {
  display: block;
}
.page-products .products-grid .product-item .product-item-info .product-item-name {
  height: 54px;
  overflow: hidden;
  font-size: 1.6rem;
  font-weight: bold;
  color: #067C58;
  text-overflow: ellipsis;
}
.page-products .products-grid .product-item form:not([data-role="tocart-form"]) {
  overflow: hidden;
  max-height: 218px;
}
.page-products .products-grid .product-item form:not([data-role="tocart-form"]) > div {
  height: 38px;
  margin-bottom: 0.5rem;
}
.page-products .products-grid .product-item form:not([data-role="tocart-form"]) .row-1,
.page-products .products-grid .product-item form:not([data-role="tocart-form"]) .row-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page-products .products-grid .product-item form:not([data-role="tocart-form"]) .row-1 input[type="radio"],
.page-products .products-grid .product-item form:not([data-role="tocart-form"]) .row-2 input[type="radio"] {
  cursor: pointer;
}
.page-products .products-grid .product-item form:not([data-role="tocart-form"]) .row-1 > div:first-child {
  text-align: left;
}
.page-products .products-grid .product-item form:not([data-role="tocart-form"]) .row-1 > div:last-child {
  text-align: right;
}
.page-products .products-grid .product-item form:not([data-role="tocart-form"]) .row-1 .product-size {
  font-weight: bold;
}
.page-products .products-grid .product-item form:not([data-role="tocart-form"]) .row-1 .product-price {
  font-weight: bold;
  color: #067C58;
}
.page-products .products-grid .product-item form:not([data-role="tocart-form"]) .row-1 .fa-check {
  color: #92bf1f;
}
.page-products .products-grid .product-item form:not([data-role="tocart-form"]) .row-1 .fa-times {
  color: #EC615E;
}
.page-products .products-grid .product-item form:not([data-role="tocart-form"]) .row-2 {
  font-size: 0.9rem;
}
.page-products .products-grid .product-item form:not([data-role="tocart-form"]) .row-2 p {
  margin: 0;
  font-weight: bold;
  padding-left: 22px;
  color: #666666;
}
.page-products .products-grid .product-item form:not([data-role="tocart-form"]) .row-2 .product-old-price {
  color: #EC615E;
  font-weight: bold;
  padding-right: 22px;
  text-decoration: line-through;
}
.page-products .products-grid .product-item form:not([data-role="tocart-form"]).single-variation {
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-products .products-grid .product-item form:not([data-role="tocart-form"]).single-variation .product-prices {
  margin-bottom: 0;
}
.page-products .products-grid .product-item form:not([data-role="tocart-form"]).single-variation .row-1 > div:first-child {
  display: none;
}
.page-products .products-grid .product-item form:not([data-role="tocart-form"]).single-variation .row-1 > div:last-child {
  width: 100%;
}
.page-products .products-grid .product-item form:not([data-role="tocart-form"]).single-variation .row-1 > div:last-child > .product-price {
  font-size: 2rem;
}
.page-products .products-grid .product-item button.action.primary {
  border: 0;
  width: 100%;
  display: block;
  font-size: 1.2rem;
  margin-right: 1rem;
  padding: 0.8rem 1rem;
  text-transform: none;
  margin-bottom: 0.5rem;
  background-color: #067C58;
}
@media (min-width: 1200px) {
  .page-products .products-grid .product-item button.action.primary {
    width: auto;
    margin-bottom: 0;
    display: inline-block;
  }
}
.page-products .products-grid .product-item button.action.primary:hover {
  background-color: #000000;
}
.page-products .products-grid .product-item a.action.viewproduct {
  border: 0;
  color: white;
  font-weight: 700;
  font-size: 1.2rem;
  border-radius: 5px;
  padding: 0.8rem 1rem;
  text-transform: none;
  display: block;
  background-color: #92bf1f;
}
@media (min-width: 1200px) {
  .page-products .products-grid .product-item a.action.viewproduct {
    display: inline-block;
  }
}
.page-products .products-grid .product-item a.action.viewproduct:hover {
  background-color: #000000;
}
.wishlist-index-index .main .product-item {
  text-align: center;
  width: 50% !important;
  margin-left: auto !important;
  padding-top: 1rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  margin-bottom: 3rem !important;
  padding-bottom: 1rem !important;
  transition: all 0.5s ease-in-out linear;
}
@media (min-width: 939px) {
  .wishlist-index-index .main .product-item {
    width: 33.3335% !important;
  }
  .wishlist-index-index .main .product-item:hover {
    background: #f7f7f7;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
  }
}
.wishlist-index-index .main .product-item .product-item-info {
  width: auto;
  margin-left: auto;
  margin-right: auto;
}
.wishlist-index-index .main .product-item .product-item-info .product-reviews-summary {
  display: flex;
  margin: 0 auto;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
@media (min-width: 700px) {
  .wishlist-index-index .main .product-item .product-item-info .product-reviews-summary {
    flex-direction: row;
  }
}
.wishlist-index-index .main .product-item .product-item-info .product-reviews-summary .rating-summary .rating-result > span:before {
  color: #FFCC00;
}
.wishlist-index-index .main .product-item .product-item-info .product-reviews-summary .reviews-actions {
  margin: 0;
  padding: 0;
  display: inline !important;
}
.wishlist-index-index .main .product-item .product-item-info .product-reviews-summary .reviews-actions a {
  color: #000000;
}
.wishlist-index-index .main .product-item .product-item-info .product-item-details {
  padding: 0 1rem;
}
.wishlist-index-index .main .product-item .product-item-info .product-item-details .product-item-inner {
  margin-top: 1rem;
}
.wishlist-index-index .main .product-item .product-item-info .product-item-details .product-item-inner .product-item-actions {
  display: block;
}
.wishlist-index-index .main .product-item .product-item-info .product-item-name {
  height: 54px;
  overflow: hidden;
  font-size: 1.6rem;
  font-weight: bold;
  color: #067C58;
  text-overflow: ellipsis;
}
.wishlist-index-index .main .product-item form:not([data-role="tocart-form"]) {
  overflow: hidden;
}
.wishlist-index-index .main .product-item form:not([data-role="tocart-form"]) > div {
  margin-bottom: 0.5rem;
}
.wishlist-index-index .main .product-item form:not([data-role="tocart-form"]) .row-1,
.wishlist-index-index .main .product-item form:not([data-role="tocart-form"]) .row-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wishlist-index-index .main .product-item form:not([data-role="tocart-form"]) .row-1 input[type="radio"],
.wishlist-index-index .main .product-item form:not([data-role="tocart-form"]) .row-2 input[type="radio"] {
  cursor: pointer;
}
.wishlist-index-index .main .product-item form:not([data-role="tocart-form"]) .row-1 .product-size {
  font-weight: bold;
}
.wishlist-index-index .main .product-item form:not([data-role="tocart-form"]) .row-1 .product-price {
  font-weight: bold;
  color: #067C58;
}
.wishlist-index-index .main .product-item form:not([data-role="tocart-form"]) .row-1 .fa-check {
  color: #92bf1f;
}
.wishlist-index-index .main .product-item form:not([data-role="tocart-form"]) .row-1 .fa-times {
  color: #EC615E;
}
.wishlist-index-index .main .product-item form:not([data-role="tocart-form"]) .row-2 {
  font-size: 0.9rem;
}
.wishlist-index-index .main .product-item form:not([data-role="tocart-form"]) .row-2 p {
  margin: 0;
  font-weight: bold;
  padding-left: 22px;
  color: #666666;
}
.wishlist-index-index .main .product-item form:not([data-role="tocart-form"]) .row-2 .product-old-price {
  color: #EC615E;
  font-weight: bold;
  padding-right: 22px;
  text-decoration: line-through;
}
.wishlist-index-index .main .product-item .box-tocart {
  justify-content: center !important;
}
.wishlist-index-index .main .product-item button.action.primary {
  border: 0;
  width: 100%;
  display: block;
  font-size: 1.2rem;
  padding: 0.8rem 1rem;
  text-transform: none;
  margin-bottom: 0.5rem;
  background-color: #067C58;
}
@media (min-width: 1200px) {
  .wishlist-index-index .main .product-item button.action.primary {
    width: auto;
    margin-bottom: 0;
    display: inline-block;
  }
}
.wishlist-index-index .main .product-item button.action.primary:hover {
  background-color: #000000;
}
.wishlist-index-index .main .product-item .product-item-actions a {
  font-weight: bold;
}
.wishlist-index-index .main .product-item .product-item-actions a.edit {
  display: none;
}
.wishlist-index-index .main .product-item .product-item-actions a.btn-remove {
  color: #EC615E;
}
.wishlist-index-index .main .product-item .product-item-actions a.btn-remove:hover {
  opacity: 0.7;
}
.wishlist-index-index .main .actions-toolbar button {
  border: 0;
  width: 100%;
  color: white;
  display: block;
  font-size: 1.2rem;
  margin-right: 1rem;
  padding: 0.8rem 1rem;
  text-transform: none;
  margin-bottom: 0.5rem;
  background-color: #067C58;
}
@media (min-width: 1200px) {
  .wishlist-index-index .main .actions-toolbar button {
    width: auto;
    margin-bottom: 0;
    display: inline-block;
  }
}
.wishlist-index-index .main .actions-toolbar button:hover {
  background-color: #000000;
}
.wishlist-index-index #confirmBox .amcart-message-box .amcart-product-notice {
  color: white;
  padding: 0.5rem 2rem;
  background: #92bf1f;
}
.wishlist-index-index #confirmBox .amcart-message-box .product-item-info {
  margin-top: 2rem;
}
.wishlist-index-index #confirmBox .amcart-message-box .am-photo-container {
  width: 40%;
}
.wishlist-index-index #confirmBox .amcart-message-box .am-photo-container a img {
  margin: 0;
}
.wishlist-index-index #confirmBox .amcart-message-box .ampage-top-info,
.wishlist-index-index #confirmBox .amcart-message-box .ampage-bottom-info {
  width: 60%;
}
.wishlist-index-index #confirmBox .amcart-message-box .ampage-top-info .am-title,
.wishlist-index-index #confirmBox .amcart-message-box .ampage-bottom-info .am-title {
  color: #067C58;
}
.wishlist-index-index #confirmBox .amcart-message-box .ampage-top-info .product-reviews-summary,
.wishlist-index-index #confirmBox .amcart-message-box .ampage-bottom-info .product-reviews-summary {
  display: flex;
  margin: 0 auto;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
@media (min-width: 700px) {
  .wishlist-index-index #confirmBox .amcart-message-box .ampage-top-info .product-reviews-summary,
  .wishlist-index-index #confirmBox .amcart-message-box .ampage-bottom-info .product-reviews-summary {
    flex-direction: row;
  }
}
.wishlist-index-index #confirmBox .amcart-message-box .ampage-top-info .product-reviews-summary .rating-summary .rating-result > span:before,
.wishlist-index-index #confirmBox .amcart-message-box .ampage-bottom-info .product-reviews-summary .rating-summary .rating-result > span:before {
  color: #FFCC00;
}
.wishlist-index-index #confirmBox .amcart-message-box .ampage-top-info .product-reviews-summary .reviews-actions,
.wishlist-index-index #confirmBox .amcart-message-box .ampage-bottom-info .product-reviews-summary .reviews-actions {
  margin: 0;
  padding: 0;
  display: inline !important;
}
.wishlist-index-index #confirmBox .amcart-message-box .ampage-top-info .product-reviews-summary .reviews-actions a,
.wishlist-index-index #confirmBox .amcart-message-box .ampage-bottom-info .product-reviews-summary .reviews-actions a {
  color: #000000;
}
.owl-buttons .owl-prev,
.owl-buttons .owl-next {
  background: #067C58 !important;
}
.owl-buttons .owl-prev:hover,
.owl-buttons .owl-next:hover {
  background: black !important;
}
.fotorama__stage__frame,
.fotorama__arr--next,
.fotorama__arr--prev,
.fotorama__stage {
  max-height: 500px;
}
.fotorama--fullscreen .fotorama__stage {
  max-width: none !important;
  max-height: none !important;
}
.fotorama--fullscreen {
  z-index: 9999000 !important;
}
.fotorama__nav--thumbs {
  display: flex;
  justify-content: center;
}
.actions-toolbar > .primary .action:last-child {
  padding: 0.8rem;
  background: #067C58;
}
.actions-toolbar > .primary .action:last-child > span {
  color: white;
  font-size: 1.2rem;
}
.actions-toolbar > .secondary,
.actions-toolbar > .secondary .action:last-child {
  width: 100%;
  display: block;
  text-align: center;
}
.actions-toolbar > .secondary > span,
.actions-toolbar > .secondary .action:last-child > span {
  font-size: 1rem;
  text-align: center;
}
aside .block-customer-login #block-customer-login-heading {
  font-weight: bold;
}
aside .block-customer-login .block-content .g-recaptcha {
  display: flex;
  align-items: center;
  justify-content: center;
}
aside .block-customer-login .block-content .g-recaptcha .grecaptcha-badge {
  margin-top: 2rem;
}
.validator-email #customer-email-error {
  margin-top: 29px;
}
.validator-phone {
  width: 100% !important;
}
.feedback-phone {
  z-index: 50;
  right: -1px;
}
.link.rewards {
  display: none !important;
}
@media (min-width: 768px) {
  .modal-custom.authentication-dropdown.custom-slide._show {
    right: 0 !important;
    top: 56px !important;
    width: 600px !important;
  }
}
@media (max-width: 450px) {
  .pages-items .item:nth-child(4),
  .pages-items .item:nth-child(5) {
    display: none;
  }
}
@media (max-width: 350px) {
  .pages-items .item:nth-child(3) {
    display: none;
  }
}
.checkout-index-index .payment-option.rewards-block {
  display: none;
}
#block-rewards-form .title:after {
  display: none;
}
#block-rewards-form #block-rewards-points-form {
  display: block !important;
}
#block-rewards-form #block-rewards-points-form .discount-form p {
  margin-bottom: 0px;
}
#block-rewards-form #block-rewards-points-form .discount-form .input-box .input-text {
  margin-top: 0;
}
#block-rewards-form #block-rewards-points-form .discount-form .input-box label {
  padding: 0 8px;
}
#block-rewards-form #block-rewards-points-form .discount-form .input-box #points_amount:valid + label,
#block-rewards-form #block-rewards-points-form .discount-form .input-box #points_amount:focus + label {
  display: none;
}
body.checkout-onepage-success .page-wrapper {
  margin-bottom: 4rem;
}
.block-customer-login div.email,
.block-customer-login div.password {
  margin-bottom: 10px;
}
.block-customer-login div.note {
  margin-bottom: 16px;
}
body.checkout-index-index .message.notice {
  margin: 0 0 9px 130px !important;
}
/*
NOTE: Basket Icon
    content: "\f291";
    color: #5BD77B;
    font-size: 28px;
    font-family: "Fontawesome";
*/
